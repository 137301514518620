<template>
    <v-container>

        <v-row>
            <v-col v-if="pageHeading">
                <span class="text-subtitle-1">{{pageHeading}}</span>
            </v-col>

            <v-col class="text-end">
                <select-entity-dialog
                @on-search="objectSearch"
                @on-clear="clear"
                path="/id-search/"
                :type-list="typeList"
                :button-text="buttonText">
                </select-entity-dialog>
            </v-col>
        </v-row>

        <search-by-id
        :form-data="searchFormData"
        :do-request="doRequest"
        @loading-complete="onLoadData"
        @refresh-data="doRequest=true"
        />

    </v-container>
</template>

<script>
import selectEntityDialog from '@/components/select-entity-dialog.vue'
import searchById from '@/components/search-by-id.vue'


export default {
    name: 'id-search',
    components: { 
      selectEntityDialog,
      searchById

    },
    data () {
      return {
        doRequest: false,
        searchResults: {},
        searchFormData: {},
        buttonText: 'Выбрать объект',
        currentType: '',
        currentId: '',

        typeList: [
            {value:'object', text:'Помещение'},
            {value:'building', text:'Здание'},
            {value:'request', text:'Заявку'},
            //{value:'fb', text:'ФЗ'},
        ],

      }
    },
    computed: {
        pageHeading: function() {

            const typeStrings = {
                object: 'Помещение',
                building: 'Здание',
                request: 'Заявка',
                fb: 'ФЗ',
            }

            let result = (this.currentId && this.currentType && typeStrings[this.currentType]);

            if (result) {
                result += (' № ' + this.currentId);
            }

            return result;
        }

    },
    methods: {
        clear: function () {
            this.searchResults = {};
            this.buttonText = 'Выбрать объект';
            this.currentType = '';
            this.currentId = undefined;
        },
        onLoadData: function () {
            this.doRequest = false;
            this.buttonText = 'Выбрать другой объект';
        },
        objectSearch: function (data) {
            this.searchFormData = data;
            this.currentId = data.id;
            this.currentType = data.type;
            this.doRequest = true;
        },


    },


}
</script>