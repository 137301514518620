<template>

    <tr>
        <td>
            <v-chip
            :color="action.color"
            small
            :href="'/offer/' + offer.ID"
            target="_blank"
            >
            {{action.title}}
            </v-chip>
        </td>
        <td class="text-caption">
            <staff-name :id='offer.User_Ins'/>
        </td>
        <td>
            <date-sql-date-formatter :date="offer.DateIns" shortdate/>
        </td>
        <td>
            <a :href="'/request/' + offer.Request_ID" target="_blank" :title="'Заявка ' + offer.Request_ID">
                {{destination}} {{reqSquare}}
            </a>
        </td>

        <td>
            {{offer.Address}}
        </td>
        <td>
            <a :href="'/object/' + offer.Square_ID" target="_blank" :title="'Помещение ' + offer.Square_ID">
            {{offer.Square}} м²
            </a>
        </td>
        <td class="text-caption">
            <staff-name :id='offer.manager'/>
        </td>



    </tr>

</template>





<script>
import staffName from '@/components/staff-name.vue'
import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue'

export default {
    name: 'offer-search-item',
    components: {
        staffName,
        dateSqlDateFormatter,
    },
    props: {
        offer: {
            type: Object
        },
    },
    computed: {
        reqSquare: function(){
            let arr = [];
            if(this.offer.MinSquare){
                arr.push(this.offer.MinSquare);
            }
            if(this.offer.MaxSquare){
                arr.push(this.offer.MaxSquare);
            }
            let result = arr.join('-');
            if (result) {
                result += ' м²';
            }
            return result;
        },
        action: function(){
            if(this.offer.slOfferState == 104) {
                return {
                    title: 'предложение',
                    color: 'light-blue accent-1'
                }
            } else if(this.offer.slOfferState == 105) {
                return {
                    title: 'показ',
                    color: 'teal accent-3'
                }
            } else if(this.offer.slOfferState == 106) {
                return {
                    title: 'переговоры',
                    color: 'lime accent-4'
                }
            } else if(this.offer.slOfferState == 107) {
                return {
                    title: 'сделка',
                    color: 'yellow accent-2'
                }
            } else {
                return {
                    title: 'unknown',
                    color: 'default'
                }
            }
        },
        destination: function() {

            let destinations = {
                1: 'офис',
                2: 'продукты',
                4: 'склад',
                8: 'производство',
                16: 'общепит',
                32: 'банк',
                64: 'магазин',
                128: 'салон',
                256: 'медцентр',
                512: 'гостиница'
            }

            if (this.offer.slDestination && destinations[this.offer.slDestination]) {
                return destinations[this.offer.slDestination]
            } else {
                return 'помещение'
            }

        }
    },
    /*

        o.[ID]
        ,o.[Square_ID]
        ,b.Address
        ,s.[Square]
        ,s.[RubPrice]
        ,s.[User_ID] AS manager
        ,o.[slOfferState]
        ,o.[isRefused]
        ,o.[Request_ID]
        ,o.[About]
        ,o.[User_Ins]
        ,o.[DateIns]
        ,o.[User_Upd]
        ,o.[DateUpd]
        ,r.[MinSquare]
        ,r.[MaxSquare]
        ,r.[MeterPrice]
        ,r.[MonthPrice]
        ,r.[slDestination]
        ,r.[slNeedContractType]

    */
}
</script>