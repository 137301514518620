<template>
    <tr :class="{ 'grey--text': link.del, 'grey lighten-4': link.del }">
        <td class="text-right grey--text">
            {{link.building_id}}
        </td>
        <td>
            {{link.address}}
        </td>
        <td class="text-right">
            {{floor}}
        </td>
        <td class="text-right">
            <a
            target="_blank" 
            :href="href"
            class="mr-2"
            :title="link.square_id || link.building_id">
                {{square}}
            </a>
            <a
            target="_blank" 
            :href="megapolis_href">
                <v-icon small>mdi-application-import</v-icon>
            </a>
        </td>
        <td>
            <a
            target="_blank"
            :href="link.link"
            class="mr-2"
            >
            <v-icon>mdi-youtube</v-icon>
            </a>
            <v-tooltip
            v-if="link.del"
            class="grey--text"
            top
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-chip
                    small
                    v-bind="attrs"
                    v-on="on"
                    >
                    удалено
                    </v-chip>
                </template>
                {{link.date_del}}
                <v-chip
                small
                class="ml-2"
                >
                    <staff-name :id='link.user_del'></staff-name>
                </v-chip>
            </v-tooltip>
            <v-chip
            v-if="!link.del && link.public"
            small
            color="green lighten-4"
            >
                public
            </v-chip>
        </td>

        <td class="text-right grey--text">
            {{link.date}}
        </td>
        <td>
            <v-chip
            small
            >
                <staff-name :id='link.user_ins'></staff-name>
            </v-chip>
        </td>
    </tr>
</template>


<script>
import staffName from '@/components/staff-name.vue'

export default {
    name: 'youtubeLinksTimelineItem',
    components: { 
      staffName,
    },
    props: {
        link: {
            type: Object
        },
    },
    data () {
      return {}
    },
    computed: {
        floor: function () {
            if (!this.link.square_id) {
                return '---';
            } else if (this.link.floor == -2) {
                return 'подвал';
            } else if (this.link.floor == -1) {
                return 'цоколь';
            } if (this.link.floor > 0) {
                return this.link.floor + '-й этаж';
            } else {
                return '';
            }
        },
        square: function () {
            if (this.link.square_id) {
                return this.link.square + ' м²';
            } else {
                return 'Здание';
            }
        },
        href: function () {
            if (this.link.square_id) {
                return '/youtube-links/object?square_id=' + this.link.square_id;
            } else {
                return '/youtube-links/object?building_id=' + this.link.building_id;
            }
        },
        megapolis_href: function () {
            if (this.link.square_id) {
                return 'http://megapolis.mega-realty.ru/proxy.htm?type=square&id=' + this.link.square_id;
            } else {
                return 'http://megapolis.mega-realty.ru/proxy.htm?type=building&id=' + this.link.building_id;
            }
        },
    }
}
</script>