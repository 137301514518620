<template>
    <div class="ymap">
        <yandex-map 
            :coords="[ 55.741189, 37.624035 ]"
            :zoom="16"
            :use-object-manager="true"
            @map-was-initialized="mapInitHandler"
        >
        </yandex-map>
    </div>
</template>

<script>
import { yandexMap } from 'vue-yandex-maps'
    
export default {
    name: 'building-foto-map',
    components: { 
      yandexMap,
    },
    data () {
      return {
        objectManager: {},
      }
    },
    methods: {
        mapInitHandler(map) {
            /* global ymaps */
            const objectManager = new ymaps.RemoteObjectManager(
                '/private-cloud/building-photos-map/?b=%b&z=%z',
                {
                    geoObjectOpenBalloonOnClick: true
                }
            )
            map.geoObjects.add(objectManager)
        },

    },
}
</script>

<style scoped>
    .ymap, .ymap-container {
        width: 100%;
        height: 100%;
    }
</style>