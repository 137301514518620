<template>
    <span>

        <v-btn
        icon
        color="green"
        @click="show=true"
        >
            <v-icon>mdi-calendar-clock</v-icon>
        </v-btn>

        <data-editor
        max-width="900"
        url="/api/request/next-call"
        :show="show"
        @close-dialog="show = false"
        @request-complete="requestComplete"
        :save-condition="!!formData.id && !!formDate"
        :del-condition="!!formData.id"
        :form-data="formData"
        title="Следующий звонок"
        >
            <v-row>
                <v-col
                cols="12"
                sm="7"
                md="4"
                >
                    <v-date-picker
                    v-model="formDate"
                    :min="currentDate"
                    locale="ru-RU"
                    ></v-date-picker>

                </v-col>
                <v-col
                cols="12"
                sm="5"
                md="8"
                >
                    <v-row>
                        <v-col>
                            <v-select
                            v-model="formHour"
                            :items="hours"
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-select
                            v-model="formMinutes"
                            :items="minutes"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-textarea
                        outlined
                        label="Комментарий"
                        v-model="formComment"
                    ></v-textarea>
                </v-col>
            </v-row>
        </data-editor>

    </span>
</template>


<script>
import dataEditor from '@/components/data-editor.vue'


export default {
    name: 'next-call-editor',
    components: { 
      dataEditor
    },
    props: {
        nextCall: {
            type: Object
        },
    },
    data () {
        return {
            show: false,
            hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
            minutes: ['00', '15', '30', '45'],
            formDate: '',
            formHour: '09',
            formMinutes: '00',
            formComment: '',
        }
    },
    computed: {
        currentDate: function () {
            return this.dateDate(new Date(Date.now()));
        },
        formData: function () {
            let result = {};
            result.id = this.nextCall.Request_ID
            if ( this.formDate ) {
                result.call_date = new Date(this.formDate + ' ' + this.formHour + ':' + this.formMinutes).toISOString();
            }
            result.comment = this.formComment;
            return result;
        },


    },

    methods: {
        requestComplete: function (response) {
            if(response.success){
                this.$emit('next-call-changed', response.nextCall);
            }
        },
        dateDate: function (d) {
            let result = '';
            if ( d ) {
                result += d.getFullYear() + '-' + [
                    '0' + (d.getMonth() + 1),
                    '0' + d.getDate()
                ].map(s => s.slice(-2)).join('-');
            }
            return result;
        },
        dateHours: function (d) {
            let result = '';
            if ( d ) {
                let hour = d.getHours();
                result += hour < 10 ? '0' + hour : hour;
            }
            return result;
        },
        dateMinutes: function (d) {
            let result = '';
            if ( d ) {
                let minutes  = d.getMinutes();
                result += minutes  < 10 ? '0' + minutes  : minutes;
            }
            return result;
        }
    },
    created() {

        this.formComment = this.nextCall.NextCallComment;

        if ( this.nextCall.NextCallDate ) {
            let date = new Date(this.nextCall.NextCallDate);
            this.formDate = this.dateDate(date);
            this.formHour = this.dateHours(date);
            this.formMinutes = this.dateMinutes(date);
        } else {
            this.formDate = this.currentDate;
        }

    },

}
</script>