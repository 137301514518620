<template>
    <v-container>
        <data-editor
        max-width="50%"
        url="/api/old-address/street-editor"
        :show="editor"
        :new-item="addNewItem"
        @close-dialog="editor = false"
        @request-complete="doRequest = true"
        :del-condition="!!currentItem.id && !currentItem.adr_count"
        :save-condition="!!currentItem.name && !!currentItem.city_id"
        :form-data="currentItem"
        title="Редактор названий улиц"
        >
            <v-row>
                <v-col
                cols="12"
                sm="6"
                >
                    <v-text-field
                        v-model="currentItem.name"
                        label="Название"
                        outlined
                        clearable
                        hide-details="true"
                    ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                    <v-select
                    :items="cityList"
                    label="Населённый пункт"
                    v-model="currentItem.city_id"
                    outlined
                    hide-details="true"
                    :disabled = !cityListReady
                    ></v-select>
                </v-col>
            </v-row>
        </data-editor>

        
        <v-row>
            <v-col>
                <span class="text-h5">Названия улиц</span>
                <v-btn
                small
                outlined
                rounded
                class="ml-8"
                @click="newStreet">
                    <v-icon>mdi-plus</v-icon> добавить улицу
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col
            cols="12"
            sm="4"
            >
                <v-text-field
                    v-model="query"
                    label="Часть названия"
                    outlined
                    clearable
                    @keyup.native.enter="doRequest = true"
                ></v-text-field>
            </v-col>

            <v-col
            cols="12"
            sm="4"
            >
                <v-select
                :items="cityListForSearch"
                label="Населённый пункт"
                v-model="cityId"
                outlined
                :disabled = !cityListReady
                ></v-select>
            </v-col>

            <v-col
            cols="12"
            sm="3"
            md="4"
            lg="2"
            >
                <v-btn
                color="primary"
                elevation="2"
                x-large
                @click="clickFind"
                >
                <v-icon left>
                    mdi-magnify
                </v-icon>
                Искать
                </v-btn>
            </v-col>
        </v-row>

        <data-loader
        url="/api/old-address/street-list"
        :form-data="searchFormData"
        :do-request="doRequest"
        :check-response="checkResponse"
        @loading-complete="onLoadData">
            <template v-slot:default>
                <v-simple-table
                dense
                class="row-pointer">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Улица</th>
                                <th class="text-left">Город</th>
                                <th class="text-left">Адреса</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <street-names-item
                            v-for="item in searchResults"
                            :key="item.id"
                            :item="item"
                            @item-selected="onStreetSelected"></street-names-item>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </data-loader>



    </v-container>
</template>

<script>
    import streetNamesItem from './components/street-names-item.vue'
    import dataLoader from '@/components/data-loader.vue'
    import dataEditor from '@/components/data-editor.vue'
    export default {
        name: 'street-names',
        components: {
            streetNamesItem,
            dataLoader,
            dataEditor
        },
        data () {
            return {
                error: false,
                cityId: undefined,
                query: undefined,
                cityList: [],
                cityListReady: false,
                doRequest: false,
                searchResults: [],
                checkResponse: function (response) {
                    return (response.streets && response.streets.length > 0);
                },
                editor: false,
                addNewItem: false,
                currentItem: {},
            }
        },
        computed: {
            searchFormData: function () {
                return {
                    city_id: this.cityId,
                    query: this.query
                }
            },
            cityListForSearch: function () {
                let result = [...this.cityList];
                result.unshift({text:'Любой', value: 0});
                return result;
            }
            
        },
        methods: {
            clickFind: function () {
                this.doRequest = true;
                this.changeRouteParams();
            },
            changeRouteParams: function () {
                if (this.cityId != this.$route.params.city ) {
                    this.$router.push({ path: '/street-names/' + this.cityId });
                }
                return;
            },
            onLoadData: function (data) {
                this.searchResults = [];
                this.doRequest = false;
                if ( data && this.checkResponse(data) ) {
                    this.searchResults = data.streets;
                }
                
            },
            onStreetSelected: function (street) {
                this.addNewItem = false;
                this.currentItem = {
                    id: street.ID,
                    city_id: street.City_ID,
                    name: street.Name,
                    adr_count: street.Address_Count
                };
                this.editor = true;
            },
            newStreet: function () {
                this.addNewItem = true;
                this.currentItem = {};
                this.editor = true;
            },
            loadCities: function () {
                this.cityListReady = false;
                this.cityList = [];
                this.$http({url: '/api/old-address/select-city', method: 'POST'})
                .then(
                    response => {
                        if ( response.cities ) {
                            this.cityList = response.cities;
                            this.cityListReady = true;
                        }
                    },
                    () => {
                        this.$eventBus.$emit('on-get-error', 'Не удалось загрузить список населённых пунктов');
                    })
            },
        },
        created() {
            this.loadCities();
            if (+this.$route.params.city) {
                this.cityId = +this.$route.params.city;
                this.doRequest = true;
            }
        }
    }
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>