<template>
     <v-overlay
        v-if="this.$store.getters.criticalError"
        class="text-center">
        <v-btn
            color="error"
            @click="resetError"
        >
            Ошибка!
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <p class="mt-5">
            Внимание! Эта информация важна для устранения ошибки:
        </p>

        <v-container>
            <v-row justify="center">
                <v-card
                    class="scroll"
                        height="300" width="300"
                >
                    <v-card-text>
                        ERROR MESSAGE:
                        <p>
                            {{ this.$store.state.criticalError.message }}
                        </p>
                        ERROR STACK:
                        <p>
                            {{ this.$store.state.criticalError.stack }}
                        </p>
                    </v-card-text>
                </v-card>
            </v-row>
        </v-container>
    </v-overlay>
</template>

<script>
export default {
    name: 'critical-error',
    data () {
      return {}
    },

    methods: {
      resetError: function () {
        this.$store.dispatch("reset_critical_error");
      }
    },

}
</script>

<style scoped>
    .scroll {
        overflow-y:scroll;

    }
</style>