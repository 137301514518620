import cianNewOffers from './cian-new-offers'
import cianAdFeed from './cian-ad-feed'
import cianAdArchive from './cian-ad-archive'
import cianAdCheckerLog from './cian-ad-checker-log'

export default [
    {
        path: '/cian-new-offers',
        name: 'cian-new-offers',
        component: cianNewOffers
    },
    {
        path: '/cian-ad-feed',
        name: 'cian-ad-feed',
        component: cianAdFeed
    },
    {
        path: '/cian-ad-archive',
        name: 'cian-ad-archive',
        component: cianAdArchive
    },
    {
        path: '/cian-ad-checker-log',
        name: 'cian-ad-checker-log',
        component: cianAdCheckerLog
    },

];