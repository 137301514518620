<template>
    <v-container>
        
        <v-row
        v-if="fb.ID">

            <v-col
            cols="12"
            sm="9">
                <fb-main-menu
                :fb="fb"/>

                <staff-name-chip
                :id="fb.User_ID"
                class="mr-2"/>

                <v-icon
                class="mr-2">
                    mdi-phone-in-talk
                </v-icon>
                <date-sql-date-formatter
                time
                :date="fb.CallDate"
                class="mr-2"/>

                <span
                v-if="fb.CallFrequency"
                class="text-caption grey--text">
                Частота прозвона {{fb.CallFrequency}} {{declineNouns(fb.CallFrequency, ' неделя', ' недели', ' недель')}}
                </span>

            </v-col>

            <v-col
            cols="12"
            lg="3">

                <firm-contacts-list
                :firmId="fb.Firm_ID"
                :fbId="fb.ID"
                :contactsCount="fb.ContactsCount"/>

            </v-col>

        </v-row>

        <v-row class="mt-0">
            <v-col>
                <div>
                    <v-chip
                    v-if="fb.isTfSeven"
                    small
                    class="mr-2 mb-2"
                    color="default">
                        24/7
                    </v-chip>

                    <v-chip
                    v-if="fb.isRemoteShow"
                    small
                    class="mr-2 mb-2"
                    color="default">
                        Удалённый показ
                    </v-chip>

                    <v-chip
                    v-if="fb.isFirmOwner"
                    small
                    class="mr-2 mb-2"
                    color="default">
                        Переговорная
                    </v-chip>

                    <v-chip
                    v-if="fb.isCallSelf"
                    small
                    class="mr-2 mb-2"
                    color="yellow lighten-3">
                        Звонит менеджер
                    </v-chip>

                    <v-chip
                    v-if="fb.isNoCian"
                    small
                    class="mr-2 mb-2"
                    color="red">
                        Не рекламировать
                    </v-chip>
                </div>

                <div
                v-if="fb.ComissionValue"
                class="word-break-normal">
                    Комиссия {{fb.ComissionValue}}
                    <span
                    v-if="fb.slComissionType == 160">
                    руб.
                    </span>
                    <span
                    v-else>
                    %
                    </span>
                </div>


                <div
                v-if="fb.About"
                class="text--secondary word-break-normal">
                    {{fb.About}}
                </div>


            </v-col>

        </v-row>

        <v-row class="mt-0">
            <v-col>
                <date-ins-change-del-tooltip time :item="fb"/>
            </v-col>
        </v-row>

    </v-container>

</template>


<script>
import fbMainMenu from '@/components/entities/fb/fb-main-menu.vue'
import staffNameChip from '@/components/staff-name-chip.vue'
import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'
import firmContactsList from '@/components/entities/firm/firm-contacts-list.vue'
import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue'
import declineNoun from '@/mixins/decline-nouns-after-num.js'

export default {
    name: 'fb-card',
    components: {
        fbMainMenu,
        staffNameChip,
        dateInsChangeDelTooltip,
        firmContactsList,
        dateSqlDateFormatter
    },
    mixins: [
        declineNoun
    ],
    props: {
        fb: {
            type: Object,
        },
    },
}
</script>
