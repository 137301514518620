<template>
    <v-menu
    v-if="fb.ID"
    open-on-hover
    bottom
    offset-y
    >
        <template v-slot:activator="slotData">
            <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotData" />
            <v-chip
            v-else
            v-on="slotData.on"
            color="amber lighten-4"
            class="mr-2"> 
                <v-icon
                class="mr-2">
                    mdi-key-outline
                </v-icon> {{fb.ID}}
            </v-chip>
        </template>
        
        <v-list>

            <v-list-item
            target="_blank"
            :href="'/fb/' + fb.ID">
                <v-list-item-title>
                    <v-icon left>
                        mdi-link-variant
                    </v-icon>
                    ФЗ № {{fb.ID}}
                </v-list-item-title>
            </v-list-item>

        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: 'fb-main-menu',
        props: {
            fb: {
                type: Object,
            }
        },

    }
</script>