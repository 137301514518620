<template>
    <span>
        <v-chip
        :key="item.val"
        v-for="item in selected"
        class="ma-2"
        close
        @click:close="removeFromNumbersList(item.val)"
        >
        {{item.name}}
        </v-chip>
    </span>
</template>


<script>

export default {
    name: 'search-chips-numbers-list',
    props: {
        name: {
            type: String
        },
        value: {
            type: Array
        },
        options: {
            type: Array
        },
    },

    computed: {
        selected: function () {
            let result = [];
            if(this.value && this.value.length > 0 && this.options && this.options.length > 0){
                for (let item of this.options) {
                    if(this.value.includes(item.id)){
                        result.push({ 'val': item.id, 'name': item.name});
                    }
                }
            }
            return result;
        },
    },

    methods: {
        removeFromNumbersList: function (n) {
            let obj = {};
            obj[this.name] = this.value.filter(item => item !== n);
            this.$emit( 'setFields', obj )
        }
    }
}
</script>