export default {
    props: {
        params: {
            type: Object
        },
    },
    data () {
        return {
            dialog: false,
            formData: {...this.params},
            rules_int_or_empty: value => (value == '' || +value == 0 || !!+value || 'Введите число'),
        }
    },
    methods: {
        formSearchAction: function () {
            this.dialog = false;
            this.$emit('do-search', this.formData);
            return;
        },
        setFormFields: function (data) {
            if (data){
                for (var key in data) {
                    this.formData[key] = data[key]
                }
            }
            return;
        },
    },
}