<template>
    <tr
    @click.stop="selectThisItem">
        <td>
            {{item.Name}}
        </td>
        <td>
            {{item.City_Name}}
        </td>
        <td>
            {{item.Address_Count}}
        </td>
        <td>
            <date-ins-change-del-tooltip :item="item"/>
        </td>
    </tr>
</template>

<script>
    import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'

    export default {
        name: 'street-names-item',
        components: {
            dateInsChangeDelTooltip,
        },
        props: {
            item: {
                type: Object
            },
        },
        data () {
            return {}
        },
        //computed: {},
        methods: {
            selectThisItem: function () {
                this.$emit('item-selected', this.item);
            }
        },
    }
</script>

<style>
</style>