<template>
    <v-container>
        <v-row
        v-if="firm.ID">
            <v-col
            cols="12"
            sm="9">
                <firm-main-menu
                :firmId="firm.ID"/>

                <span
                v-if="!firm.Name">
                    Фирма {{firm.ID}}
                </span>

                {{firm.Name}}
            </v-col>

            <v-col
            cols="12"
            sm="3"
            class="order-lg-last">
                <firm-contacts-list
                v-if="showContacts"
                :firmId="firm.ID"/>
            </v-col>

        </v-row>
        <v-row
        v-if="firm.About"
        class="mt-0">
            <v-col
            cols="12"
            sm="9">
                <div
                class="text--secondary word-break-normal">
                    {{firm.About}}
                </div>
            </v-col>

            <v-col
            cols="12"
            sm="3"
            class="order-lg-last">
                <div
                class="text--secondary word-break-normal"
                v-if="firm.INN">
                   ИНН: {{firm.INN}}
                </div>
            </v-col>

        </v-row>
        
        <v-row class="mt-0">
            <v-col>
                <date-ins-change-del-tooltip time :item="firm"/>
            </v-col>
        </v-row>
    </v-container>

</template>


<!--

Добавить всплывающее окно с реквизитами

		,[Name]
		,[ShortName]
		,[LegalAddress]
		,[Address]
		,[INN]
		,[KPP]
		,[RS]
		,[Bank]
		,[KS]
		,[BIK]
		,[OKPO]
		,[OKVED]

-->



<script>
import firmMainMenu from '@/components/entities/firm/firm-main-menu.vue'
import firmContactsList from '@/components/entities/firm/firm-contacts-list.vue'
import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'
    export default {
        name: 'firm-card',
        components: {
            firmMainMenu,
            firmContactsList,
            dateInsChangeDelTooltip
        },
        props: {
            firm: {
                type: Object,
            },
            showContacts: {
                type: Boolean,
                default: true
            },
        },
    }
</script>
