<template>
    <v-container>
        <div
        v-if="!error">
            <v-row>
                <v-col>
                    <span class="text-h5">Новые видео на Youtube</span>
                </v-col>
            </v-row>


            <v-row v-if="youtubeLinksList && youtubeLinksList.length > 0">
                <v-col
                cols="12">

                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th width="5em" class="text-right"> id</th>
                                    <th class="text-left">Здание</th>
                                    <th class="text-right">Этаж</th>
                                    <th class="text-right">Площадь</th>
                                    <th></th>
                                    <th class="text-right">Добавлено</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <youtube-links-timeline-item
                                v-for="item in youtubeLinksList"
                                :key="item.id"
                                :link="item"></youtube-links-timeline-item>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                cols="12">
                    <v-pagination
                        class="mt-4"
                        v-if="pageCount > 1"
                        v-model="page"
                        :length="pageCount"
                        :total-visible="7"
                        @input="loadData"
                    ></v-pagination>
                </v-col>
            </v-row>

        </div>

    </v-container>
</template>

<script>
import youtubeLinksTimelineItem from './components/youtube-links-timeline-item.vue'

export default {
    name: 'youtubeLinksTimeline',

    components: { 
      youtubeLinksTimelineItem,
    },

    data () {
        return {
            error: false,
            youtubeLinksList: [],
            linksPerPage: 50,
            page: 1,
            pageCount: 1
        }
    },
    methods: {
        loadData: function () {
            this.$eventBus.$emit('on-start-loading-data');
            this.error = false;
            this.youtubeLinksList = [];
            this.$http({url: '/api/youtube-links/timeline/' + this.page, method: 'GET'})
            .then(
                response => {
                    if (+response.count) {
                        this.pageCount = Math.ceil(response.count/this.linksPerPage);
                    } else {
                        this.pageCount = 1;
                    }
                    if (response.list) {
                        this.youtubeLinksList = response.list;
                    }
                    this.$eventBus.$emit('on-stop-loading-data');
                },
                () => {
                    this.$eventBus.$emit('on-stop-loading-data');
                    this.error = true;
                    this.$eventBus.$emit('on-get-error', 'Ошибка загрузки данных');
                });
        },
    },
    created() {
        this.loadData()
    },

}
</script>