<template>
    <v-container>

        <square-heading
        :square="square"
        @refresh-data="refreshData">
        </square-heading>

        <v-row class="mt-0">
            <v-col
            cols="12"
            sm="9"
            >

                <div
                v-if="squareObject.RateManager"
                class="text-caption text--secondary">
                    <v-icon
                    color="yellow darken-3"
                    small>
                        mdi-star
                    </v-icon>
                    <staff-name :id='squareObject.RateManager' fullname/>
                </div>


                <div>
                    {{squareObject.planningString}}
                </div>

                <div>
                    {{squareObject.destinationString}}
                </div>

                <div>
                    {{squareObject.optionsString}}
                </div>

                <div
                v-if="square.About"
                class="text--secondary">
                    {{square.About}}
                </div>

                <v-alert
                class="mt-4"
                v-if="square.supplementary"
                border="top"
                colored-border
                type="info"
                icon="mdi-text-long"
                elevation="2"
                >
                    {{square.supplementary}}
                </v-alert>

            </v-col>

            <v-col
            cols="12"
            sm="3"
            >

                <div
                v-if="priceIncluded"
                class="text-caption text--secondary">
                цена включает {{priceIncluded}}
                </div>

                <div
                v-if="square.PriceInclusion & 4"
                class="text-caption text--secondary">
                УСН
                </div>

                <div
                class="text-caption text--secondary">
                {{squareObject.contractNameString}}
                </div>

                <div>
                    <v-chip
                    v-if="square.isFree"
                    color="green lighten-4"
                    small> 
                        без комиссии
                    </v-chip>
                    <v-chip
                    v-else
                    color="deep-orange lighten-4"
                    small> 
                        комиссия
                    </v-chip>
                </div>


            </v-col>

        </v-row>

        <v-row class="mt-0">
            <v-col>
                <date-ins-change-del-tooltip time :item="square"/>
            </v-col>
        </v-row>

    </v-container>
</template>


<script>
import Square from '@/components/js/square.js'
import squareHeading from '@/components/entities/square/square-heading.vue'
import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'
import staffName from '@/components/staff-name.vue'

export default {
    name: 'square-card',
    components: {
        squareHeading,
        dateInsChangeDelTooltip,
        staffName,
    },
    props: {
        square: {
            type: Object,
        },
    },
    data () {
        return {
            squareObject: new Square(this.square),
        }
    },
    computed: {
        priceIncluded: function () {
            let result = '';
            if(this.square.PriceInclusion & 1){
                result += 'НДС';
            }
            if((this.square.PriceInclusion & 3) == 3){
                result += ' и ';
            }
            if(this.square.PriceInclusion & 2){
                result += 'коммуналку';
            }
            return result;
        }
    },
    methods: {
        refreshData: function () {
            this.$emit('refresh-data');
        },
    },


}
</script>

