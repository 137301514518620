import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import { EventBus } from './utils/event-bus.js';

Vue.use(Vuex)
Vue.use(VueCookies)


const localStorageCache = (context, axiosParams, fieldName, mutationName) => {
  return new Promise((resolve, reject) => {

    const load_from_api = () => {
      axios(axiosParams)
      .then(
        response => {
          context.commit(mutationName, response)
          localStorage.setItem(fieldName, JSON.stringify(response))
          resolve()
        },
        error => {
          reject(error)
        })
    }
    
    if(localStorage.getItem(fieldName)) {
      try {
        let j = JSON.parse(localStorage.getItem(fieldName))
        context.commit(mutationName, j)
        resolve()
      } catch(e) {
        localStorage.removeItem(fieldName)
        load_from_api()
      }
    } else {
      load_from_api()
    }

  })
}




Vue.$cookies.config('6h')

export default new Vuex.Store({
  state: {
    logonPending: false,
    apiStatus: 'unauthorized',
    appInitComplete: false,
    authorizationToken: '',
    criticalError: {
      message: '',
      stack: '',
    },
    authMessage: '',
    user: {},
    staffFullNameList: {},
    staffManagerList: {},
    appDictionary: {},
    userPermissions: {},
  },
  getters: {
    authStatus: state => !!state.authorizationToken,
    userStatus: state => !!state.user,
    criticalError: state => !!state.criticalError.message,
    staffSortedShortList: (state) => {
      let result = [];
      for (var key in state.staffFullNameList) {
        result.push(
          {'name': state.staffFullNameList[key].name,
          'id':Number(key),
          'del':Number(state.staffFullNameList[key].disabled)}
        );
      }
      result.sort(function (a, b) {
        return (a.del - b.del || a.name.localeCompare(b.name));
      });
      return result;
    },
    staffSortedList: (state) => {
      let result = [];
      for (var key in state.staffFullNameList) {
        result.push(
          {'name': state.staffFullNameList[key].name,
          'id':Number(key),
          'del':Number(state.staffFullNameList[key].disabled)}
        );
      }
      result.sort(function (a, b) {
        return (a.del - b.del || a.name.localeCompare(b.name));
      });
      result.unshift({'name':'Любой', 'id':0, 'del':0});
      return result;
    },
    staffManagerShortList: (state) => {
      let result = [];
      for (var key in state.staffManagerList) {
        result.push(
          {'name': state.staffManagerList[key].name,
          'id':Number(key),
          'del':Number(state.staffManagerList[key].disabled)}
        );
      }
      return result;
    },
    staffManagerList: (state) => {
      let result = [];
      for (var key in state.staffManagerList) {
        result.push(
          {'name': state.staffManagerList[key].name,
          'id':Number(key),
          'del':Number(state.staffManagerList[key].disabled)}
        );
      }
      result.unshift({'name':'Любой', 'id':0, 'del':0});
      result.unshift({'name':'Нет ответственного', 'id':-1, 'del':0});
      return result;
    },
  },
  mutations: {
    reset_authorization(state){
      state.authorizationToken = ''
      state.user = {}
    },
    set_authorization_token(state,token){
      state.authorizationToken = token || ''
    },
    set_authorization_message(state,msg){
      state.authMessage = msg || ''
    },
    throw_critical_error(state,err){
      state.criticalError.message = err.message || ''
      state.criticalError.stack = err.stack || ''
    }, 
    reset_critical_error(state){
      state.criticalError.message = ''
      state.criticalError.stack = ''
    },
    load_user_data(state,userData){
      state.user = userData
    },
    loading_start(state){
      state.apiStatus = 'pending'
    },
    loading_finish(state){
      state.apiStatus = 'ok'
    },
    loading_error(state){
      state.apiStatus = 'error'
    },
    loading_auth_error(state){
      state.apiStatus = 'unauthorized'
    },
    logon_pending(state){
      state.logonPending = true
    },
    logon_finished(state){
      state.logonPending = false
    },
    load_staff_full_names(state,staffList){
      state.staffFullNameList = staffList
    },
    load_staff_manager_list(state,staffList){
      state.staffManagerList = staffList
    },
    load_user_permissions(state,permissions){
      state.userPermissions = permissions
    },
    load_metro_stations(state,stations){
      state.metroStations = stations
    },
    create_metro_checkboxes(state,chk){
      state.metroCheckboxes = chk
    },
    app_init_complete(state){
      state.appInitComplete = true
    },

  },
  actions: {
    load_token_from_cookie(context){
      context.commit('set_authorization_token', Vue.$cookies.get('token'))
    },
    reset_authorization(context) {
      context.commit('reset_authorization')
      context.commit('loading_auth_error')
      Vue.$cookies.remove("token")
      localStorage.clear()
    },
    reset_authorization_error(context) {
      context.commit('set_authorization_message')
    },
    throw_critical_error(context,error) {
      context.commit('throw_critical_error', error)
    },
    reset_critical_error(context) {
      context.commit('reset_critical_error')
    },
    login(context,account) {
      context.commit('logon_pending')
      axios({url: '/api/logon/', method: 'POST', data: account})
      .then(response => {
        context.commit('logon_finished')
        if (response.token) {
          context.commit('set_authorization_token', response.token)
          context.commit('set_authorization_message')
          Vue.$cookies.set('token', response.token)
          EventBus.$emit('on-api-connection')
          EventBus.$emit('on-logon-complete')
        } else {
          context.commit('set_authorization_message', 'no token')
        }
      })
      .catch(error => {
        context.commit('logon_finished')
        context.commit('set_authorization_message', error.message || 'authorization error')
      });
    },
    load_user_data(context) {
      return new Promise((resolve, reject) => {
        axios({url: '/api/account/', method: 'GET'})
          .then(
            response => {
              context.commit('load_user_data', response)
              resolve()
            },
            error => {
              reject(error)
            })
      })
    },
    loading_start(context) {
      context.commit('loading_start')
    },
    loading_finish(context) {
      context.commit('loading_finish')
    },
    loading_error(context) {
      context.commit('loading_error')
    },
    load_staff_full_names(context) {
      return localStorageCache(
        context,
        {url: '/api/staff-full-list/', method: 'GET'},
        'staffFullNameList',
        'load_staff_full_names')
    },
    load_staff_manager_names(context) {
      return localStorageCache(
        context,
        {url: '/api/staff-manager-list/', method: 'GET'},
        'staffManagerList',
        'load_staff_manager_list')
    },
    load_user_permissions(context) {
      return localStorageCache(
        context,
        {url: '/api/user-permissions-list/', method: 'GET'},
        'userPermissions',
        'load_user_permissions')
    },
    load_metro_stations(context) {
      return localStorageCache(
        context,
        {url: '/api/metro-stations/', method: 'GET'},
        'metroStations',
        'load_metro_stations')
    },
    create_metro_checkboxes(context) {
      let result = {}
      context.state.metroStations.forEach(item => {
        if ( !result[item.CheckBox] ){
          result[item.CheckBox] = []
        }
        result[item.CheckBox].push({'name': item.Name, 'id': item.ID});
      });
      context.commit('create_metro_checkboxes', result)
    },


    app_init_complete(context) {
      context.commit('app_init_complete')
      EventBus.$emit('on-app-init-complete')
    },


  },
  modules: {
  }
})
