<template>
    <v-dialog
    v-model="dialog"
    scrollable
    :disabled="noContacts"
    max-width="1200"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-chip
            :disabled="noContacts"
            color="light-blue accent-1"
            v-bind="attrs"
            v-on="on"
            >
                <slot>
                    <v-icon class="mr-2">
                        mdi-phone
                    </v-icon>
                    Контакты
                </slot>
            </v-chip>
        </template>

        <v-card>

            <v-toolbar
            color="primary"
            dark
            >
                <v-toolbar-title
                v-if="+fbId"
                >
                    Контакты ФЗ № {{fbId}}
                </v-toolbar-title>
                <v-toolbar-title
                v-else
                >
                    Контакты фирмы № {{firmId}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                icon
                @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <simple-data-loader
                url="/api/firm/contacts"
                :form-data="formData"
                :check-response="checkResponse"
                @loading-complete="onLoadData">
                    <template v-slot:default>

                            <contact-row
                            v-for="item in contacts"
                            :key="item.ID"
                            :contact="item"
                            />

                    </template>
                </simple-data-loader>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
import simpleDataLoader from '@/components/simple-data-loader.vue'
import contactRow from '@/components/contact-row.vue'

export default {
    name: 'firm-contacts-list',
    components: {
        contactRow,
        simpleDataLoader,
    },
    props: {
        firmId: {
            type: Number,
        },
        fbId: {
            type: Number,
        },
        contactsCount: {
            type: Number,
        },
    },
    data () {
        return {
            dialog: false,
            checkResponse: function (response) {
                return (response.contacts && response.contacts.length > 0);
            },
        }
    },
    computed: {
        formData: function(){
            return {
                firm_id: this.firmId,
                fb_id: this.fbId
            };
        },
        noContacts: function() {
            if(+this.fbId && !this.contactsCount){
                return true;
            } else {
                return false;
            }  
        }
    },
    methods: {
        onLoadData: function (data) {
            this.contacts = [];
            if ( data && this.checkResponse(data) ) {
                this.contacts = data.contacts;
            }
        },
    },
}
</script>