export default {

    data () {
        return {
            doRequest: false,
            counter: '',
            searchResults: [],
        }
    },
    computed: {
        query: function(){
            return this.$route.query;
        }
    },
    methods: {

        changeOrder: function (data){
            this.params.setParamValue( 'order', data );
            this.updateQuerystring();
        },

        clearForm: function (){
            this.params.setDefaults();
            this.updateQuerystring();
        },

        formDoSearch: function (data) {
            this.params.setParams(data);
            this.updateQuerystring();
        },

        setFields: function (data) {
            this.params.setParamsSet(data);
            this.updateQuerystring();
        },

        checkResponse: function (response) {
            return (response.list && response.list.length > 0);
        },

        onLoadData: function (data) {
            this.searchResults = [];
            this.doRequest = false;
            this.counter = '';
            if ( data && this.checkResponse(data) ) {
                this.searchResults = data.list;
                this.counter = '' + data.row_count;
            } else {
                this.searchResults = []
            }
        },

        updateQuerystring: function () {
            if ( !this.compareParams(this.params.selectedParams(), this.query) ){
                this.$router.push({ path: this.$route.path, query: this.params.selectedParams() });
            }
        },

        compareParams: function (obj1, obj2) {
            if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
            for (var key in obj1) {
                if ( !obj2[key] || (obj1[key].toString() !== obj2[key].toString()) ) {
                    return false;
                }
            }
            return true;
        },


    },

    created() {
        this.params.setParams(this.query);
        this.doRequest = true;
    }

}