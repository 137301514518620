<template>
    <v-row
    v-if="upload"
    class="mb-8"
    >
        <v-col>
            <div class="caption"><staff-name :id='upload.User_ID'></staff-name> {{upload.Date}} файлов {{upload.File_Count}}, ошибок {{upload.Error_Count}}</div>
            <div class="heading-6"
            v-if="upload.Building_ID"
            >
                <v-chip
                small>
                    <v-icon small class="mr-2">mdi-office-building-outline</v-icon>
                    {{upload.Building_ID}}
                </v-chip>
                
                {{upload.Address}}
            </div>

            <div class="heading-6"
            v-if="upload.Square_ID"
            >
                <v-chip
                small>
                    <v-icon small class="mr-2">mdi-desk</v-icon>
                    {{upload.Square_ID}}
                </v-chip>
                
                {{ squareObject.floorString }}, {{ squareObject.squareString }} 
            </div>

            <div class="heading-6"
            v-if="upload.Comment"
            >
            {{upload.Comment}}
            </div>


                <media-file-preview
                v-for="item in upload.files"
                :file="item"
                :key="item.ID"
                ></media-file-preview>

        </v-col>
    </v-row>
</template>

<script>
import mediaFilePreview from './media-file-preview.vue'
import staffName from '@/components/staff-name.vue'
import Square from '@/components/js/square.js'

export default {
    name: 'media-uploads-item',
    components: { 
      mediaFilePreview,
      staffName,
    },
    props: {
        upload: {
            type: Object
        },
    },
    data () {
      return {}
    },
    created() {
        this.squareObject = new Square(this.upload);
    }
}
</script>