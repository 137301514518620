module.exports = class MetroCheckboxes {

    constructor(m) {
        if (m){
            this._mask = m;
            this._list = this._maskToList(m);
        } else {
            this._mask = '';
            this._list = [];
        }

    }

    get mask() {
        if (this._mask) {
            return this._mask;
        } else {
            return '';
        }
    }

    set mask(m) {
        if (m){
            this._mask = m;
            this._list = this._maskToList(m);
        } else {
            this._mask = '';
            this._list = [];
        }
    }


    get list() {
        if (this._list) {
            return this._list;
        } else {
            return [];
        }
    }


    _bbToInt(byte,bit) {
        var numbers = new Array();
        var i = 1;
        while(bit > 0)
        {
            if ((bit & 1) == 1)
                numbers.push((i + byte*8));
            bit = (bit >> 1);
            i++;
        }
        return numbers;
    }


    _maskToList(m) {
        let str = m.slice(2);
        var list = new Array();
        var pieces = new Array();
        while (str) {
            pieces.push(str.slice(0,2));
            str = str.slice(2);
        }
        for(var j in pieces)
        list = list.concat(this._bbToInt(j,parseInt(('0x' + pieces[j]),16)));
    
        return list;
    }

}