<template>
    <v-row>

        <v-chip
        v-if="+params.user_id"
        class="ma-2"
        close
        @click:close="setFields({'user_id': 0})"
        >
        {{user_name}}
        </v-chip>

        <v-chip
        v-if="dateRange(params.date_from, params.date_to)"
        class="ma-2"
        close
        @click:close="setFields({'date_from': 0, 'date_to': 0})"
        >
        Период: {{dateRange(params.date_from, params.date_to)}}
        </v-chip>

        <v-chip
        v-if="+params.external_only"
        class="ma-2"
        close
        @click:close="setFields({'external_only': 0})"
        >
        Только внешние заходы
        </v-chip>



    </v-row>
</template>

<script>
import searchChipsMixin from '@/mixins/search-chips-mixin.js'

export default {
    name: 'offer-search-chips',
    mixins: [
        searchChipsMixin,
    ],
    computed: {
        user_name: function () {
            return this.$store.state.staffFullNameList[this.params.user_id].shortname;
        },
    },
}
</script>
