<template>
    <div>
        <v-container>
            <v-row align="center">
                <v-col
                cols="12"
                sm="6">
                    <select-manager
                    outlined
                    v-model="userId"
                    @change="doRequest = true"
                    :extension="[{id: -1, name: 'С ответственным'}, {id: -2, name: 'Без ответственного'}]"
                    />
                </v-col>
                <v-col
                cols="12"
                sm="6">
                    <v-checkbox
                    class="ml-2"
                    v-model="showSold"
                    @change="doRequest = true"
                    label="показывать сданные"/>
                </v-col>
            </v-row>
        </v-container>




        <data-loader
        url="/api/search/objects-by-building/"
        :form-data="searchFormData"
        :do-request="doRequest"
        :check-response="checkResponse"
        @loading-complete="onLoadData">
            <template v-slot:default>

                <v-card
                v-for="item in objects"
                :key="item.ID"
                class="ma-4">

                    <square-heading
                    :square="item">
                    </square-heading>

                </v-card>


            </template>
        </data-loader>

    </div>

</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import squareHeading from '@/components/entities/square/square-heading.vue'
import selectManager from '@/components/form-elements/select-manager.vue'

export default {
    name: 'idSearch',
    components: { 
      dataLoader,
      squareHeading,
      selectManager,

    },
    props: {
        buildingId: {
            type: Number
        },
    },
    data () {
      return {
        doRequest: false,
        userId: -1,
        showSold: false,
        objects: [],
      }
    },
    computed: {
        searchFormData: function() {
            return {
                building_id: this.buildingId,
                user_id: this.userId,
                show_sold: this.showSold ? 1 : 0
            }
        },
    },
    methods: {
        checkResponse: function (response) {
            return (response && response.objects);
        },
        onLoadData: function (data) {
            this.objects = data.objects || [];
            this.doRequest = false;
        },
    },
    created() {
        this.doRequest = true;
    },

}
</script>