<template>
    <v-row class="my-8">
        <v-col
        v-if="contact.slContactType == 76"
        cols="12"
        md="4"
        lg="3"
        class="py-0">
            <a
            target="_blank"
            :href="'mailto:' + contact.Contact"
            >
                {{ contact.Contact }}
            </a>
        </v-col>

        <v-col
        v-if="contact.slContactType != 76 && contact.extracted_phone"
        cols="12"
        md="4"
        lg="3"
        class="py-0">
            <span
            class="font-weight-bold d-md-none mr-2">
                <a
                target="_blank"
                :href="'tel:+7' + contact.extracted_phone"
                >
                    +7 {{ contact.extracted_phone.slice(0,3) }} {{ contact.extracted_phone.slice(3,6) }} {{ contact.extracted_phone.slice(6,8) }} {{ contact.extracted_phone.slice(8) }}
                </a>
            </span>
            <span
            class="font-weight-bold d-none d-md-inline mr-2">
                +7 {{ contact.extracted_phone.slice(0,3) }} {{ contact.extracted_phone.slice(3,6) }} {{ contact.extracted_phone.slice(6,8) }} {{ contact.extracted_phone.slice(8) }}
            </span>
            <span
            class="mr-2">
                <a
                target="_blank"
                :href="'whatsapp://send/?phone=7' + contact.extracted_phone"
                >
                <v-icon
                color="green darken-1">
                    mdi-whatsapp
                </v-icon>
                </a>
            </span>
            <span
            class="mr-2">
                <a
                target="_blank"
                :href="'https://t.me/+7' + contact.extracted_phone"
                >
                <v-icon
                color="light-blue accent-4">
                    mdi-navigation-variant
                </v-icon>
                </a>
            </span>
        </v-col>

        <v-col
        cols="12"
        md="8"
        lg="9"
        class="py-0">
            <date-ins-change-del-tooltip time :item="contact"/>
        </v-col>


        <v-col
        cols="12"
        class="py-0">
        {{ contact.About }}
            <div
            v-if="buildings"
            class="mt-2">
                <v-chip
                v-for="item in buildings"
                :key="item"
                small
                class="mr-2 mb-2">
                {{item}}
                </v-chip>
            </div>
        </v-col>

    </v-row>
</template>


<script>
import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'

export default {
    name: 'contact-row',
    components: {
        dateInsChangeDelTooltip,
    },
    props: {
        contact: {
            type: Object
        },
    },
    data () {
      return {}
    },
    computed: {
        buildings: function(){
            return this.contact.buildings;
        }
    },

}
</script>