const requestSearchOptions = {
    user_id: {
        type: 'number',
    },
    status: {
        type: 'mask',
        options: [
            { val: 1, name: "В работе" },
            { val: 2, name: "Не предлагать" },
            { val: 4, name: "Сняли" },
        ],
    },
    order: {
        type: 'number',
        default: 1,
        options: [
            { val: 3, name: 'Создание (новые)' },
            { val: 4, name: 'Создание (старые)' },
            { val: 5, name: 'Изменение (новые)' },
            { val: 6, name: 'Изменение (старые)' },
            { val: 1, name: 'Последнее предложение (новые)' },
            { val: 9, name: 'Следующий звонок (новые)' },
            { val: 10, name: 'Следующий звонок (старые)' },
            { val: 7, name: 'Смена ответственного (новые)' },
        ],
    },
    time_to_call: {
        type: 'bool',
    },
    offers_min: {
        type: 'number',
    },
    shows_min: {
        type: 'number',
    },
    negotiations_min: {
        type: 'number',
    },
    deals_min: {
        type: 'number',
    },
    was_attached_before: {
        type: 'number',
    },
    min_square: {
        type: 'number',
    },
    max_square: {
        type: 'number',
    },
    destination: {
        type: 'mask',
        options: [
            { val: 1, name: "офис" },
            { val: 2, name: "магазин" },
            { val: 4, name: "склад" },
            { val: 8, name: "производство" },
            { val: 16, name: "кафе, ресторан" },
            { val: 32, name: "банк" },
            { val: 64, name: "магазин" },
            { val: 128, name: "салон" },
            { val: 256, name: "медцентр" },
            { val: 512, name: "гостиница" },
        ],
    },
    contract: {
        type: 'number',
        options: [
            { val: 0, name: "Любой" },
            { val: 1, name: "Аренда" },
            { val: 2, name: "Продажа" },
        ],
    },
    min_price: {
        type: 'number',
    },
    max_price: {
        type: 'number',
    },
    show_deleted: {
        type: 'bool',
    },
    insert_date_from: {
        type: 'number',
    },
    insert_date_to: {
        type: 'number',
    },
    last_change_date_from: {
        default: 180,
        type: 'number',
    },
    last_change_date_to: {
        type: 'number',
    },
    last_user_change_date_from: {
        type: 'number',
    },
    last_user_change_date_to: {
        type: 'number',
    },
    last_offer_date_from: {
        type: 'number',
    },
    last_offer_date_to: {
        type: 'number',
    },
    last_show_date_from: {
        type: 'number',
    },
    last_show_date_to: {
        type: 'number',
    },
    last_negotiation_date_from: {
        type: 'number',
    },
    last_negotiation_date_to: {
        type: 'number',
    },
    last_deal_date_from: {
        type: 'number',
    },
    last_deal_date_to: {
        type: 'number',
    },
    separate: {
        type: 'bool',
    },
    tax_office: {
        type: 'number',
    },
    search_string: {
        type: 'string',
    },
}



const offerSearchOptions = {
    user_id: {
        type: 'numbersList',
    },
    manager_id: {
        type: 'numbersList',
    },
    offer_state: {
        type: 'numbersList',
        options: [
            { id: 104, name: "Предложение" },
            { id: 105, name: "Показ" },
            { id: 106, name: "Переговоры" },
            { id: 107, name: "Сделка" },
        ],
    },
    order: {
        type: 'number',
        default: 1,
        options: [
            { val: 1, name: 'Дата' },
            { val: 2, name: 'Сотрудник' },
            { val: 3, name: 'Менеджер' },
            { val: 4 , name: 'Адрес'},
        ],
    },
    square_id: {
        type: 'number',
    },
    request_id: {
        type: 'number',
    },
    building_id: {
        type: 'number',
    },
    fb_id: {
        type: 'number',
    },
    firm_id: {
        type: 'number',
    },
    date_from: {
        default: 180,
        type: 'number',
    },
    date_to: {
        type: 'number',
    },
}


const authLogOptions = {
    user_id: {
        type: 'number',
    },
    date_from: {
        type: 'number',
    },
    date_to: {
        type: 'number',
    },
    external_only: {
        type: 'bool',
    },
}




export {
    requestSearchOptions,
    offerSearchOptions,
    authLogOptions
}