<template>
    <div>
        <v-checkbox
            v-for="item in items"
            v-model="selected"
            :key="item.mask"
            :label="item.name"
            :value="item.val"
            class="d-inline-block mr-4"
        />
    </div>
</template>

<script>
import bitmaskArrayConverter from '@/mixins/bitmask-array-converter.js'

export default {
    name: 'bitmask-checkbox-group',
    mixins: [
        bitmaskArrayConverter,
    ],
    props: {
        value: {
            type: Number
        },
        items: {
            type: Array
        },
    },
    computed: {
        selected: {
            get: function () {
                return this.bitmaskToArray(this.value);
            },
            set: function (newValue) {
                this.$emit('change', this.arrayToBitmask(newValue));
            },
        }
    },

}
</script>