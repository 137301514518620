<template>

    <v-dialog
    v-model="dialog"
    max-width="600"
    scrollable
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
                Параметры
            </v-btn>
        </template>
        <v-card>
            <v-toolbar
            dark
            color="primary"
            >
                <v-toolbar-title>Параметры поиска заявок</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                icon
                dark
                @click="dialog=false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-container class="pa-8">

                    <v-row>
                        <v-col>
                            <select-user
                            outlined
                            v-model="formData.user_id"
                            :extension="[{id: -1, name: 'С ответственным'}, {id: -2, name: 'Без ответственного'}]"
                            label="Ответственный"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <bitmask-checkbox-group
                            outlined
                            v-model="formData.status"
                            :items="requestStatusOptions"
                            @change="setFormFields({ 'status': $event })"
                            />
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col>
                            <v-select
                                v-model="formData.contract"
                                :items="requestContractOptions"
                                label="Договор"
                                item-text="name"
                                item-value="val"
                                outlined
                                hide-details
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <bitmask-select
                            v-model="formData.destination"
                            :items="requestDestinationOptions"
                            @change="setFormFields({ 'destination': $event })"
                            label="Назначение"
                            multiple
                            chips
                            />
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col
                        cols="12"
                        sm="4"
                        class="pa-2">
                            Площадь
                        </v-col>
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.min_square"
                            label="от"
                            @click="formData.min_square = ''"
                            />
                        </v-col>
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.max_square"
                            label="до"
                            @click="formData.max_square = ''"
                            />
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col
                        cols="12"
                        sm="4"
                        class="pa-2">
                            Цена (руб.)
                        </v-col>
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.min_price"
                            label="от"
                            @click="formData.min_price = ''"
                            />
                        </v-col>
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.max_price"
                            label="до"
                            @click="formData.max_price = ''"
                            />
                        </v-col>
                    </v-row>


                <v-row align="center">
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            ИФНС
                        </v-col>
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.tax_office"
                            @click="formData.tax_office = ''"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="4"
                        class="pa-2">
                            <v-checkbox
                            v-model="formData.separate"
                            label="Отдельный вход"
                            ></v-checkbox>
                        </v-col>
                    </v-row>


                <v-row align="center">
                        <v-col
                        cols="12"
                        sm="4"
                        class="pa-2">
                            <v-checkbox
                            v-model="formData.shows_min"
                            label="С показами"
                            dense
                            ></v-checkbox>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="4"
                        class="pa-2">
                            <v-checkbox
                            v-model="formData.negotiations_min"
                            label="Переговоры"
                            dense
                            ></v-checkbox>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="4"
                        class="pa-2">
                            <v-checkbox
                            v-model="formData.deals_min"
                            label="Со сделками"
                            dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>



                <v-row align="center">
                        <v-col
                        class="pa-2">
                            <v-checkbox
                            v-model="formData.time_to_call"
                            label="Пора звонить"
                            dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col>
                            <v-text-field
                                outlined
                                hide-details
                                clearable
                                v-model="formData.search_string"
                                label="Текст"
                                @click="formData.search_string = ''"
                                class="my-4"
                            />
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col>
                            <select-date-range
                                label="Последнее изменение"
                                :minValue="formData.last_change_date_from"
                                :maxValue="formData.last_change_date_to"
                                @setFields="setFormFields({ 'last_change_date_from': $event.min, 'last_change_date_to': $event.max })"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <select-date-range
                                label="Дата создания"
                                :minValue="formData.insert_date_from"
                                :maxValue="formData.insert_date_to"
                                @setFields="setFormFields({ 'insert_date_from': $event.min, 'insert_date_to': $event.max })"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <select-date-range
                                label="Последняя сделка"
                                :minValue="formData.last_deal_date_from"
                                :maxValue="formData.last_deal_date_to"
                                @setFields="setFormFields({ 'last_deal_date_from': $event.min, 'last_deal_date_to': $event.max })"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <select-date-range
                                label="Последний показ"
                                :minValue="formData.last_show_date_from"
                                :maxValue="formData.last_show_date_to"
                                @setFields="setFormFields({ 'last_show_date_from': $event.min, 'last_show_date_to': $event.max })"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <select-date-range
                                label="Последнее предложение"
                                :minValue="formData.last_offer_date_from"
                                :maxValue="formData.last_offer_date_to"
                                @setFields="setFormFields({ 'last_offer_date_from': $event.min, 'last_offer_date_to': $event.max })"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <select-date-range
                                label="Смена ответственного"
                                :minValue="formData.last_user_change_date_from"
                                :maxValue="formData.last_user_change_date_to"
                                @setFields="setFormFields({ 'last_user_change_date_from': $event.min, 'last_user_change_date_to': $event.max })"
                            />
                        </v-col>
                    </v-row>

                </v-container>


            </v-card-text>

            <v-divider/>

            <v-card-actions>
                <v-btn
                color="primary"
                elevation="2"
                @click="formSearchAction"
                large
                class="mx-auto my-2"
                >
                Найти
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

</template>

<script>
import searchFormMixin from '@/mixins/search-form-mixin.js'
import selectUser from '@/components/form-elements/select-user.vue'
import bitmaskCheckboxGroup from '@/components/form-elements/bitmask-checkbox-group.vue'
import bitmaskSelect from '@/components/form-elements/bitmask-select.vue'
import selectDateRange from '@/components/form-elements/select-date-range.vue'
import {requestSearchOptions} from '@/components/search/searchOptions.js'


export default {
    name: 'request-search-form',
    components: { 
        selectUser,
        bitmaskCheckboxGroup,
        bitmaskSelect,
        selectDateRange,
        
    },
    mixins: [
        searchFormMixin,
    ],
    data () {
        return {
            sortOptions: requestSearchOptions.order.options,
            requestStatusOptions: requestSearchOptions.status.options,
            requestDestinationOptions: requestSearchOptions.destination.options,
            requestContractOptions: requestSearchOptions.contract.options,          
        }
    },

}
</script>