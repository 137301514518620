<template>
    <v-chip
    small
    outlined
    :color="color">
        {{text}}
    </v-chip>
</template>

<script>
export default {
    name: 'square-status-chip',
    props: {
        status: {
            type: Number,
            default: 0
        },
    },
    data () {
      return {
          fields: {
              110 : {
                  text: 'ok',
                  color : 'green',
              },
              111 : {
                  text: 'pause',
                  color : 'orange',
              },
              113 : {
                  text: 'sold',
                  color : 'grey',
              },
              117 : {
                  text: 'nd',
                  color : 'black',
              },
          }

      }
    },
        computed: {
        color: function () {
            return this.fields[this.status].color;
        },
        text: function () {
            return this.fields[this.status].text;
        },
    },
}
</script>