<template>

    <v-dialog
    v-model="dialog"
    max-width="600"
    scrollable
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
                Параметры
            </v-btn>
        </template>
        <v-card>
            <v-toolbar
            dark
            color="primary"
            >
                <v-toolbar-title>Параметры поиска заявок</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                icon
                dark
                @click="dialog=false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-container class="pa-8">

                    <v-row>
                        <v-col>
                            <select-date-range
                                label="Период"
                                :minValue="formData.date_from"
                                :maxValue="formData.date_to"
                                @setFields="setFormFields({ 'date_from': $event.min, 'date_to': $event.max })"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-select
                            label="Менеджер"
                            v-model="formData.manager_id"
                            :items="this.$store.getters.staffManagerShortList"
                            item-text="name"
                            item-value="id"
                            outlined
                            hide-details
                            multiple
                            chips
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-select
                            label="Сотрудник"
                            v-model="formData.user_id"
                            :items="this.$store.getters.staffSortedShortList"
                            item-text="name"
                            item-value="id"
                            outlined
                            hide-details
                            multiple
                            chips
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-select
                            label="Действие"
                            v-model="formData.offer_state"
                            :items="searchOptions.offer_state.options"
                            item-text="name"
                            item-value="id"
                            outlined
                            hide-details
                            multiple
                            chips
                            />
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            Заявка
                        </v-col>
                        <v-col
                        cols="6"
                        sm="8"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.request_id"
                            @click="formData.request_id = ''"
                            />
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            Помещение
                        </v-col>
                        <v-col
                        cols="6"
                        sm="8"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.square_id"
                            @click="formData.square_id = ''"
                            />
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            Здание
                        </v-col>
                        <v-col
                        cols="6"
                        sm="8"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.building_id"
                            @click="formData.building_id = ''"
                            />
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            ФЗ
                        </v-col>
                        <v-col
                        cols="6"
                        sm="8"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.fb_id"
                            @click="formData.fb_id = ''"
                            />
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col
                        cols="6"
                        sm="4"
                        class="pa-2">
                            Фирма
                        </v-col>
                        <v-col
                        cols="6"
                        sm="8"
                        class="pa-2">
                            <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[rules_int_or_empty]"
                            v-model="formData.firm_id"
                            @click="formData.firm_id = ''"
                            />
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>

            <v-divider/>

            <v-card-actions>
                <v-btn
                color="primary"
                elevation="2"
                @click="formSearchAction"
                large
                class="mx-auto my-2"
                >
                Найти
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

</template>

<script>
import searchFormMixin from '@/mixins/search-form-mixin.js'
import bitmaskArrayConverter from '@/mixins/bitmask-array-converter.js'
import selectDateRange from '@/components/form-elements/select-date-range.vue'
import {offerSearchOptions} from '@/components/search/searchOptions.js'

export default {
    name: 'offer-search-form',
    components: { 
        selectDateRange,
    },
    mixins: [
        bitmaskArrayConverter,
        searchFormMixin,
    ],
    data () {
        return {
            searchOptions: offerSearchOptions,
        }
    },
}
</script>