<template>
    <v-container>

        <!-- Заявка -->
        <v-card
        v-if="(dataObject.request && dataObject.request.ID)"
        class="mb-2">

            <request-heading
            v-if="dataObject.request"
            :request="dataObject.request">
            </request-heading>

        </v-card>

        <!-- Фирма-арендатор -->
        <v-card
        v-if="(dataObject.firmRenter && dataObject.firmRenter.ID)"
        class="mb-2">

            <!-- 
                Выбор, показывать ли контакты фирмы в заявке, происходит здесь.
                Нужно реализовать эту проверку также на сервере, т.к. сейчас он отдаёт контакты для любой фирмы
            -->
            <firm-card
            :firm="dataObject.firmRenter"
            :showContacts="($checkPermissions('view_Third-Party_Request') || dataObject.request.User_ID == $store.state.user.id)"
            class="word-break-normal"/>

        </v-card>

        <!-- Помещение -->
        <v-card
        v-if="(dataObject.square && dataObject.square.ID)"
        class="mb-2">

            <square-card
            :square="dataObject.square"
            @refresh-data="refreshData"/>

        </v-card>

        <!-- Здание -->
        <v-card
        v-if="(dataObject.building && dataObject.building.ID)"
        class="mb-2">

            <building-card
            :building="dataObject.building"/>

        </v-card>

        <!-- ФЗ -->
        <v-card
        v-if="(dataObject.fb && dataObject.fb.ID)"
        class="mb-2">

            <fb-card
            :fb="dataObject.fb"/>

        </v-card>

        <!-- Фирма-собственик -->
        <v-card
        v-if="(dataObject.firmLandlord && dataObject.firmLandlord.ID)"
        class="mb-2">

            <firm-card
            :firm="dataObject.firmLandlord"
            class="word-break-normal"
            />


        </v-card>


    </v-container>
</template>

<script>
import buildingCard from '@/components/entities/building/building-card.vue'
import squareCard from '@/components/entities/square/square-card.vue'
import requestHeading from '@/components/entities/request/request-heading.vue'
import firmCard from '@/components/entities/firm/firm-card.vue'
import fbCard from '@/components/entities/fb/fb-card.vue'

export default {
    name: 'data-viewer',
    components: { 
      buildingCard,
      squareCard,
      requestHeading,
      firmCard,
      fbCard

    },
    props: {
        dataObject: {
            type: Object,
        },
    },

    data () {
      return {
        showSquareSupplementaryEditor: false,
      }
    },

    methods: {
        refreshData: function () {
            this.$emit('refresh-data');
        },
    },


}
</script>