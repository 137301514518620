<template>
    <v-container>
        <div class="text-h5">Загруженные фотографии объекта</div>
        <div class="text-subtitle-1">{{pageHeading}}</div>

        <data-loader
        pagination
        url="/api/media-cloud/media-uploads-list/"
        :form-data="searchFormData"
        :do-request="doRequest"
        :check-response="checkResponse"
        @loading-complete="onLoadData">
            <template v-slot:default>
                <media-uploads-item
                v-for="item in uploads"
                :key="item.ID"
                :upload="item"></media-uploads-item>
            </template>
        </data-loader>
    </v-container>
</template>

<script>
import mediaUploadsItem from './components/media-uploads-item.vue'
import dataLoader from '@/components/data-loader.vue'


export default {
    name: 'object-uploads',
    components: { 
      mediaUploadsItem,
      dataLoader
    },
    data () {
      return {
            doRequest: false,
            checkResponse: function (response) {
                return (response.uploads && response.uploads.length > 0);
            },
            uploads: [],
            searchFormData: {
                building_id: (this.$route.params.type === 'building' && +this.$route.params.id),
                square_id: (this.$route.params.type === 'object' && +this.$route.params.id),
                user_id: 0
            }
      }
    },
    computed: {
        pageHeading: function() {

            let result = '';

            if ( this.$route.params.type === 'building' ) {
                result = 'Здание № ';
            } else if ( this.$route.params.type === 'object' ) {
                result = 'Помещение № ';
            }

            return (result + this.$route.params.id);
        }

    },
    methods: {
        onLoadData: function (data) {
            this.uploads = [];
            this.doRequest = false;
            if ( data && this.checkResponse(data) ) {
                this.uploads = data.uploads;
            }
        },
    },
    created() {
        this.doRequest = true;
    },
}
</script>

<style>

</style>