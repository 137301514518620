<template>
    <v-tooltip
    color="primary"
    right
    :disabled="disabled">
        <template v-slot:activator="{ on, attrs }">
            <span
            v-bind="attrs"
            v-on="on">
                <slot></slot>
            </span>
        </template>
        <span
        v-if="text">{{text}}</span>
        <date-sql-date-formatter
        v-if="date"
        time
        :date="date"/>
        <staff-name
        v-if="user"
        :id='user'/>
    </v-tooltip>
</template>

<script>
    import staffName from '@/components/staff-name.vue'
    import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue'

    export default {
        name: 'date-user-tooltip',
        components: {
            staffName,
            dateSqlDateFormatter,
        },
        props: {
            date: {
                type: String,
            },
            user: {
                type: Number,
            },
            text: {
                type: String,
            },
            short: {
                type: Boolean,
                default: false,
            },
            dateOnly: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            disabled: function () {
                return !(this.date && this.date !== '');

            },
        },

    }
</script>
