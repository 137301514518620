<template>
    <data-loader
    url="/api/change-logs/objects-log"
    :form-data="searchFormData"
    :do-request="doRequest"
    :check-response="checkResponse"
    @loading-complete="onLoadData">
        <template v-slot:default>
            <v-simple-table
            dense
            >
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Дата</th>
                            <th class="text-left">Столбец</th>
                            <th class="text-left">Старое значение</th>
                            <th class="text-left">Сотрудник</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in searchResults"
                        :key="item.id"
                        :item="item"
                        >
                            <td>{{ item.DateIns }}</td>
                            <td>{{ item.ColumnName }}</td>
                            <td>{{ item.OldValue }}</td>
                            <td>
                                <staff-name :id='item.User_ID'></staff-name>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
    </data-loader>
</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import staffName from '@/components/staff-name.vue'

export default {
    name: 'square-change-log',
    props: {
        squareId: {
            type: Number,
        },
    },
    components: {
        dataLoader,
        staffName
    },
    data () {
        return {
            doRequest: false,
            searchResults: [],
            checkResponse: function (response) {
                return (response.changes && response.changes.length > 0);
            },
        }
    },
    computed: {
        searchFormData: function () {
            return {
                square_id: this.squareId
            }
        },
    },
    methods: {
        clickFind: function () {
            this.doRequest = true;
        },
        onLoadData: function (data) {
            this.searchResults = [];
            this.doRequest = false;
            if ( data && this.checkResponse(data) ) {
                this.searchResults = data.changes;
            }
            
        },
    },
    created() {
        this.doRequest = true;
    }
}
</script>