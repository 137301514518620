<template>
    <v-dialog
    v-model="showDialog"
    v-bind="$attrs">
        <v-card>
            <v-card-title>
                <slot name="title">
                    {{title}}
                </slot>
                <v-spacer></v-spacer>
                <v-btn
                icon
                @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <slot name="default"/>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'entity-dialog',
        props: {
            show: {
                type: Boolean,
            },
            type: {
                type: String,
            },
            id: {
                type: Number,
            },
        },
        //components: {},
        data () {
            return {
                typeList: {
                    square:'Помещение',
                    building:'Здание',
                    fb:'ФЗ',
                },
            }
        },
        computed: {
            showDialog: {
                get () { return this.show },
                set (value) {
                    if(!value){
                        this.$emit('close-dialog');
                    }
                },
            },
            title: function () {
                return this.typeList[this.type] + ' № ' + this.id;
            }
        },
        methods: {
            closeDialog: function () {
                this.$emit('close-dialog');
            },
        },
    }
</script>

<style>
</style>