<template>
    <v-card>
        <v-navigation-drawer
      permanent
      right
        >
            <v-list-item class="px-2">
                <v-icon>mdi-clipboard-outline</v-icon>
            </v-list-item>
        
            <v-list-item-title>Clipboard</v-list-item-title>

            <v-btn
            icon
            @click.stop="mini = !mini"
            >
            <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        
        </v-navigation-drawer>
    </v-card>
</template>

<script>
export default {
    name: 'test-page',
    data () {
      return {
        drawer: true,
      }
    },
}
</script>
