<template>
    <search-page
    :counter="counter"
    @clear-search-form="clearForm">

        <template v-slot:header>Лог авторизации</template>

        <template v-slot:search-form>
           <auth-log-search-form
            :params="params.searchParams"
            @do-search="formDoSearch"/>
        </template>

        <template v-slot:search-chips>
            <auth-log-chips
            :params="params.searchParams"
            @setFields="setFields"/>
        </template>

<!--
        <template v-slot:data-loader>
            <auth-log-list
            :do-request="doRequest"
            :search-params="params.searchParams"
            @loading-complete="doRequest=false"
            />
        </template>
-->
        <template v-slot:data-loader>
            <data-loader
            pagination
            url="/api/auth-log"
            :form-data="params.searchParams"
            :do-request="doRequest"
            @loading-complete="onLoadData">
                <template v-slot:default>
                    <v-simple-table
                    dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Дата</th>
                                    <th>Логин</th>
                                    <th>IP</th>
                                    <th>Success</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in searchResults"
                                :key="item.ID">
                                    <td>
                                        <date-sql-date-formatter :date="item.date" time/>
                                    </td>
                                    <td>
                                        {{item.login}}
                                    </td>
                                    <td>
                                        {{(item.ip.slice(0, 12) == '192.168.108.') ? 'local' : item.ip}}
                                    </td>
                                    <td>
                                        <span
                                        v-if="+item.user_id">
                                        YES
                                        </span>
                                        <span
                                        v-if="!+item.user_id">
                                        NO
                                        </span>
                                    </td>
                                </tr>

                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </data-loader>

        </template>



    </search-page>
</template>

<script>
import searchParamsClass from '@/components/search/searchParams.js'
import {authLogOptions} from '@/components/search/searchOptions.js'
import searchPage from '@/components/search/search-page.vue'
import searchPageMixin from '@/mixins/search-page-mixin.js'
import authLogChips from './components/auth-log-chips.vue'
import authLogSearchForm from './components/auth-log-search-form.vue'
import dataLoader from '@/components/data-loader.vue'
import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue'

export default {
    name: 'auth-log',
    components: { 
        searchPage,
        authLogChips,
        authLogSearchForm,
        dataLoader,
        dateSqlDateFormatter,

    },
    mixins: [
        searchPageMixin
    ],
    data () {
        return {
            fields: authLogOptions,
            params: new searchParamsClass(authLogOptions),
            counter: '',
            searchResults: [],
        }
    },

}
</script>