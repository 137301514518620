<template>
    <tr :class="{ 'grey--text': !item.status, 'grey lighten-4': !item.status }">
        <td>
            <a
            target="_blank" 
            :href="megapolis_href">
                {{item.square_id}}
            </a>
        </td>
        <td class="text-right grey--text">
            {{item.building_id}}
        </td>
        <td>
            {{item.address}}
        </td>
        <td class="text-right">
            {{item.floor}}
        </td>
        <td class="text-right">
            {{item.square}} м²
        </td>
        <td class="text-right">
            {{destination}}
        </td>
        <td class="text-right grey--text">
            <date-sql-date-formatter shortdate :date="item.date"/>
        </td>
        <td>
            <v-icon
            color = "green darken-1"
            @click="refreshAd(item.square_id)"
            title="Переопубликовать объявление"
            >
                mdi-refresh
            </v-icon>
        </td>
        <td>
            <v-icon
            color = "red darken-1"
            @click="deleteAd(item.square_id)"
            title="Удалить объявление"
            >
                mdi-delete-forever
            </v-icon>
        </td>
    </tr>
</template>


<script>
import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue';

export default {
    name: 'cian-ad-feed-item',

    components: { 
      dateSqlDateFormatter,
    },

    props: {
        item: {
            type: Object
        },
    },
    data () {
      return {}
    },
    computed: {
        destination: function () {
            if (this.item.slContractType === 8) {
                if (this.item.destination === 1) {
                    return 'продажа офиса';
                } else if (this.item.destination === 2) {
                    return 'продажа магазина';
                } else if (this.item.destination === 4) {
                    return 'продажа склада';
                } else if (this.item.destination === 8) {
                    return 'продажа производства';
                } else if (this.item.destination === 16) {
                    return 'продажа ПСН';
                } else if (this.item.destination === 32) {
                    return 'продажа здания';
                } else {
                    return 'unknown';
                }
            } else {
                if (this.item.destination === 1) {
                    return 'аренда офиса';
                } else if (this.item.destination === 2) {
                    return 'аренда магазина';
                } else if (this.item.destination === 4) {
                    return 'аренда склада';
                } else if (this.item.destination === 8) {
                    return 'аренда производства';
                } else if (this.item.destination === 16) {
                    return 'аренда ПСН';
                } else if (this.item.destination === 32) {
                    return 'аренда здания';
                } else {
                    return 'unknown';
                }
            }
        },
        megapolis_href: function () {
            return 'http://megapolis.mega-realty.ru/proxy.htm?type=square&id=' + this.item.square_id;
        },
    },
    methods: {
        deleteAd: function (id) {
            if ( confirm("Точно удалить?") ) {
                this.$eventBus.$emit('on-start-loading-data');
                this.$http({url: '/api/cian/ad-feed/del/', method: 'POST', data: {id: id}})
                .then(
                    () => {
                        this.$eventBus.$emit('on-stop-loading-data');
                        this.$eventBus.$emit('on-get-status', true, 'Объявление успешно удалено');
                        this.refreshList();
                        return;
                    },
                    () => {
                        this.$eventBus.$emit('on-stop-loading-data');
                        this.$eventBus.$emit('on-get-error', 'Ошибка при удалении объявления');
                        return;
                    }
                );
            }
        },
        refreshAd: function (id) {
            if ( confirm("Опубликовать объявление повторно?") ) {
                this.$eventBus.$emit('on-start-loading-data');
                this.$http({url: '/api/cian/ad-feed/refresh/', method: 'POST', data: {id: id}})
                .then(
                    () => {
                        this.$eventBus.$emit('on-stop-loading-data');
                        this.$eventBus.$emit('on-get-status', true, 'Объявление успешно переопубликовано');
                        this.refreshList();
                        return;
                    },
                    () => {
                        this.$eventBus.$emit('on-stop-loading-data');
                        this.$eventBus.$emit('on-get-error', 'Ошибка при обновлении объявления');
                        return;
                    }
                );
            }
        },
        refreshList: function () {
            this.$emit('refresh-list');
            return;
        },
    }
}
</script>