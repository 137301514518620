<template>
    <v-container>

        <data-editor
        max-width="50%"
        url="/api/old-address/city-editor"
        :show="editor"
        :new-item="addNewItem"
        @close-dialog="editor = false"
        @request-complete="doRequest = true"
        :del-condition="!!currentItem.id && !currentItem.street_count"
        :save-condition="!!currentItem.name"
        :form-data="currentItem"
        title="Редактор населённых пунктов"
        >
            <v-row>
                <v-col
                cols="12"
                >
                    <v-text-field
                        v-model="currentItem.name"
                        label="Название"
                        outlined
                        clearable
                        hide-details="true"
                    ></v-text-field>
                </v-col>
            </v-row>
        </data-editor>



        <v-row>
            <v-col>
                <span class="text-h5">Населённые пункты</span>
                <v-btn
                small
                outlined
                rounded
                class="ml-8"
                @click="newCity">
                    <v-icon>mdi-plus</v-icon> добавить населённый пункт
                </v-btn>
            </v-col>
        </v-row>


        <data-loader
        url="/api/old-address/city-list"
        :do-request="doRequest"
        :check-response="checkResponse"
        @loading-complete="onLoadData">
            <template v-slot:default>
                <v-simple-table
                dense
                class="row-pointer">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Населённый пункт</th>
                                <th class="text-left">Улицы</th>
                                <th class="text-left">Адреса</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <city-names-item
                            v-for="item in cityList"
                            :key="item.id"
                            :item="item"
                            @item-selected="onCitySelected"></city-names-item>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </data-loader>
    </v-container>
</template>

<script>
    import cityNamesItem from './components/city-names-item.vue'
    import dataLoader from '@/components/data-loader.vue'
    import dataEditor from '@/components/data-editor.vue'

    export default {
        name: 'city-names',
        components: {
            cityNamesItem,
            dataLoader,
            dataEditor,
        },
        data () {
            return {
                cityList: [],
                doRequest: false,
                checkResponse: function (response) {
                    return (response.cities && response.cities.length > 0);
                },
                editor: false,
                addNewItem: false,
                currentItem: {},
            }
        },
        //computed: {},
        methods: {
            onLoadData: function (data) {
                this.cityList = [];
                this.doRequest = false;
                if ( data && this.checkResponse(data) ) {
                    this.cityList = data.cities;
                }
            },
            onCitySelected: function (city) {
                this.addNewItem = false;
                this.currentItem = {
                    id: city.ID,
                    name: city.Name,
                    street_count: city.streets,
                    adr_count: city.addresses
                };
                this.editor = true;
            },
            newCity: function () {
                this.addNewItem = true;
                this.currentItem = {};
                this.editor = true;
            },
        },
        created() {
            this.doRequest = true;
        },
    }
</script>


<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>