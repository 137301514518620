<template>
    <span v-bind:class="{ 'text--disabled': disabled }">
        {{name}}
    </span>
</template>

<script>
export default {
    name: 'staff-name',
    props: {
        id: {
            type: Number,
            default: 0
        },
        fullname: {
            type: Boolean,
            default: false
        }
    },
    data () {
      return {}
    },
    computed: {
        name: function () {
            if (this.id > 0 && this.$store.state.staffFullNameList[this.id]){
                if (this.fullname){
                    return this.$store.state.staffFullNameList[this.id].name
                } else {
                    return this.$store.state.staffFullNameList[this.id].shortname
                }
                
            } else {
                return 'unknown'
            }
        },
        disabled: function () {
            if (this.id > 0 && this.$store.state.staffFullNameList[this.id] && this.$store.state.staffFullNameList[this.id].disabled === 0){
                return false
            } else {
                return true
            }
        },
    },
}
</script>