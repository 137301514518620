<template>
    <v-container>
        <v-row>
            <v-col>
                <span class="text-h5">Ошибки в адресах</span>
            </v-col>
            <v-col>
                <select-manager
                outlined
                v-model="userId"
                label="Ответственный"
                @change="doRequest=true"
                ></select-manager>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <data-loader
                url="/api/old-address/broken-address"
                :form-data="{user_id: userId}"
                :do-request="doRequest"
                @loading-complete="onLoadData">
                    <template v-slot:default>
                        <div
                        v-for="managerId in Object.keys(itemsByManager)"
                        :key="managerId"
                        >
                            <h3><staff-name :id='+managerId'></staff-name></h3>

                            <v-simple-table dense class="mb-6">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th width="5em" class="text-right">id</th>
                                            <th class="text-left">Адрес</th>
                                            <th class="text-left">Помещения в работе</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                        v-for="item in itemsByManager[managerId]"
                                        :key="item.id"
                                        >
                                            <td>
                                                <a
                                                target="_blank"
                                                :href="'http://megapolis.mega-realty.ru/proxy.htm?type=building&id=' + item.id">
                                                    {{item.id}}
                                                </a>
                                            </td>
                                            <td>
                                                <a :href="'http://maps.yandex.ru/?text=' + item.address" target="_blank">
                                                    {{item.address}}
                                                </a>  
                                            </td>
                                            <td>{{item.sq_count}}</td>
                                            <td>
                                                <v-icon
                                                @click="refreshItem(item.id)"
                                                title="Перепроверить адрес"
                                                >
                                                    mdi-refresh
                                                </v-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </template>
                </data-loader>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import selectManager from '@/components/form-elements/select-manager.vue'
import staffName from '@/components/staff-name.vue'
export default {
    name: 'broken-address',
    components: {
        dataLoader,
        selectManager,
        staffName,
    },
    data () {
        return {
            addressList: [],
            userId: 0,
            doRequest: true,
        }
    },
    computed: {
        itemsByManager: function () {
            let result = {};

            this.addressList.forEach(function(item){
                if(!result[item.manager]){
                    result[item.manager] = [];
                }
                result[item.manager].push(item);
            })

            return result;
        }
    },
    methods: {
        onLoadData: function (data) {
            this.addressList = [];
            this.doRequest = false;
            if (data) {
                this.addressList = data.list;
            }
        },
        refreshItem: function (id) {
            if ( confirm("Запустить повторную проверку?") ) {
                this.$eventBus.$emit('on-start-loading-data');
                this.$http({url: '/api/old-address/broken-address/delete/', method: 'POST', data: {id: id}})
                .then(
                    () => {
                        this.$eventBus.$emit('on-stop-loading-data');
                        this.$eventBus.$emit('on-get-status', true, 'Адрес удалён и будет проверен повторно');
                        this.doRequest = true;
                        return;
                    },
                    () => {
                        this.$eventBus.$emit('on-stop-loading-data');
                        this.$eventBus.$emit('on-get-error', 'Ошибка при обновлении адреса');
                        return;
                    }
                );
            }
        },
    },
    created() {
        this.userId = this.$store.state.user.id;
    },
}
</script>