<template>
    <v-chip v-if="actionName" :color="actionColor">
        {{actionName}}
        <v-icon
        v-if="action.isRefused"
        class="ml-2"
        small
        >
        mdi-emoticon-sad-outline
        </v-icon>
    </v-chip>
</template>

<script>
export default {
    name: 'request-action-chip',
    props: {
        action: {
            type: Object,
        },
    },
    computed: {
        actionName: function () {
            if ( this.action.status == 104 ) {
                return 'предложено'
            } else if ( this.action.status == 105 ) {
                return 'показано'
            } else if ( this.action.status == 106 ) {
                return 'переговоры'
            } else if ( this.action.status == 107 ) {
                return 'сделка'
            } else {
                return ''
            }
        },
        actionColor: function () {
            if ( this.action.status == 105 ) {
                return 'light-blue lighten-1'
            } else if ( this.action.status == 106 ) {
                return 'yellow lighten-1'
            } else if ( this.action.status == 107 ) {
                return 'green accent-4'
            } else {
                return ''
            }
        },
    },
}
</script>

<style>

</style>