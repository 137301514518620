<template>
    <span v-bind="$attrs">{{dateString}}</span>
</template>


<script>

export default {
    name: 'date-sql-date-formatter',
    props: {
        date: {
            type: String
        },
        time: {
            type: Boolean,
            default: false,
        },
        shortdate: {
            type: Boolean,
            default: false,
        },
    },
    data () {
      return {}
    },
    computed: {
        parsedDate: function () {
            return new Date(this.date);
        },
        dateString: function () {

            let options = {}

            if ( this.shortdate ) {
                Object.assign(options, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                });
            } else {
                Object.assign(options, {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                });
            }

            if ( this.time ) {
                Object.assign(options, {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false
                });
            }


            let formatter = new Intl.DateTimeFormat("ru", options);

            let result = formatter.format(this.parsedDate);

            return result;

       },
    },


}
</script>