<template>
    <v-container class="pb-4">
        <div class="text-center">
            <v-btn
                color="primary"
                class="mb-8"
                x-large
                @click="showUploadDialog"
                v-if="uploadStatus !== 'processing' && !completeWithErrors"
            >
                <v-icon class="mr-4">mdi-camera</v-icon>Загрузить фото
            </v-btn>
        </div>

        <v-form
        ref="form"
        >
            <input
            class="d-none"
            ref="uploader"
            type="file"
            accept="image/*"
            multiple
            @change="onFilesSelected"
            >

            <v-select
            v-if="!disabledForm"
            :items="file_sources"
            label="Источник материалов"
            class="mt-4"
            v-model="fileSource"
            outlined
            ></v-select>
        
            <v-textarea
            v-if="!disabledForm"
            outlined
            auto-grow
            name="comment"
            label="Комментарий к загрузке"
            v-model="comment"
            ></v-textarea>
        </v-form>



        <v-progress-linear
        v-if="!noFiles && !errorMessage && uploadStatus !== 'processing' && !completeWithErrors"
        v-model="uploadPercentage"
        class="mb-4"
        ></v-progress-linear>

        <v-alert
        v-if="uploadStatus === 'processing' || completeWithErrors"
        :dismissible="completeWithErrors"
        :color="completeWithErrors ? 'error' : (uploadStatus === 'complete') ? 'success' : 'yellow'"
        >
            {{alertMessage}}
            
            <v-divider
            class="my-4"
            ></v-divider>

            <v-list
            max-width="396px"
            v-if="uploadedFiles"
            flat>
                <media-file-processing-item
                v-for="file in uploadedFiles"
                v-bind="file"
                :key="file.id"></media-file-processing-item>
            </v-list>
        </v-alert>

        <v-alert
        v-if="uploadStatus === 'complete' && !completeWithErrors"
        type="success"
        >
            Файлы успешно загружены
        </v-alert>

        <v-alert
        dismissible
        v-if="errorMessage"
        type="error"
        @click="clearError"
        >
            Загрузка завершилась ошибкой: {{errorMessage}}
        </v-alert>
    </v-container>
</template>

<script>
import mediaFileProcessingItem from '@/components/media-upload-file-processing-item.vue'

export default {
    name: 'media-upload',
    props: {
        type: {
            type: String,
        },
        id: {
            type: Number,
        },
    },
    components: {
        mediaFileProcessingItem,
    },
    data () {
        return {
            formFiles: [],
            uploadResult: {
                file_counter: 0,
                error_counter: 0,
            },
            uploadedFiles: [],
            processedFiles: [],
            uploadPercentage: 0,
            uploadStatus: 'none',
            errorMessage: '',
            comment: '',
            fileSource: 2,
            file_sources: [
                {value:2, text:'источник неизвестен'},
                {value:3, text:'собственная съёмка'},
                {value:4, text:'прислал собственник'},
                {value:5, text:'сайт в интернете'},
                {value:6, text:'чужие объявления'},
            ]
        }
    },
    computed: {
        noFiles: function() {
            return !(this.formFiles.length > 0)
        },
        disabledForm: function() {
            return !(this.uploadStatus === 'none')
        },
        completeWithErrors: function() {
            return (this.uploadStatus === 'complete' && this.uploadedFiles.filter(f => f.error).length > 0)
        },
        alertMessage: function() {
            if (this.uploadStatus === 'error') {
                return 'Загрузка завершилась ошибкой: ' + this.errorMessage;
            } else if (this.completeWithErrors) {
                return 'При обработке файлов возникли ошибки';
            } else if (this.uploadStatus === 'complete') {
                return 'Все файлы успешно загружены и обработаны';
            } else if (this.uploadStatus === 'processing') {
                return 'Файлы загружены и обрабатываются' + ((this.uploadResult.error_counter > 0) ? '. Внимание! При загрузке возникли ошибки.' : '');
            } else {
                return '';
            }
        }
    },
    methods: {
        sendForm: function () {
            if (this.formFiles.length > 0) {
                let formData = new FormData();

                for (let file of this.formFiles) {
                    formData.append("files", file, file.name);
                }

                // fields
                if (this.comment)
                    formData.append("comment", this.comment);
                if (this.fileSource)
                    formData.append("source", this.fileSource);

                if(this.type === "building") {
                    formData.append("building_id", this.id);
                } else if (this.type === "square") {
                    formData.append("square_id", this.id);
                }

                this.$http({
                    url: '/api/media-cloud/upload-media-files/',
                    method: 'POST',
                    data: formData,
                    onUploadProgress : (progressEvent) => {
                        this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                    }})
                    .then(response => {
                        this.uploadResult.file_counter = response.file_counter;
                        this.uploadResult.error_counter = response.error_counter;
                        if (response.files) {
                            response.files.forEach( f => {
                                let tmp = f;
                                tmp.complete = false;
                                this.uploadedFiles.push(tmp);
                            })
                        }
                        if (response.files.length > 0 && response.file_counter > response.error_counter) {
                            this.errorMessage = '';
                            this.checkFileProcessing();
                        } else {
                            this.uploadStatus = 'error';
                            this.errorMessage = 'Ошибка загрузки файлов';
                        }
                    })
                    .catch(error => {
                        this.errorMessage = error.message || 'server error';
                        this.uploadStatus = 'error';
                    });


            }
        },
        onFilesSelected: function (e) {
            this.formFiles = e.target.files;
            this.sendForm();
        },
        checkFiles: function() {
            var pa = [];
            this.uploadedFiles.forEach( (file, index) => {
                pa.push(new Promise((resolve) => {
                    if (file.complete) {
                        return resolve(true);
                    } else if (file.error) {
                        return resolve(true);
                    } else if (file.isImage) {
                        this.$http({
                            url: '/api/media-cloud/check-cloud-file/' + file.key + '/3',
                            method: 'GET',
                            })
                        .then(
                            response => {
                                if (response.result) {
                                    this.$set(this.uploadedFiles[index], 'complete', true);
                                    let tmp = file;
                                    this.processedFiles.push(tmp);
                                    return resolve(true);
                                } else {
                                    return resolve(false);
                                }
                            },
                            () => {
                                return resolve(false);
                            }
                        )
                    } else {
                        // Если это не картинка — временно возвращаем true, потом добавим проверку
                        return resolve(true);
                    }
                }));
            })
            return pa;
        },
        stopProcessing: function(){
            this.uploadedFiles.forEach( (file, index) => {
                if (!file.complete) {
                    this.$set(this.uploadedFiles[index], 'error', true);
                }
            })
            this.uploadStatus = 'complete';
            const f = () => {
                this.clearForm();
                this.$emit('upload-complete');
            }
            if( !this.completeWithErrors ) {
                setTimeout(f, 1000);
            }            
        },
        checkFileProcessing: function() {
            this.uploadStatus = 'processing';
            let i = 0;
            let attempts = 60;
            let delay = 5000;
            var f = async () => {
                i++;
                try {
                    let r = await Promise.all(this.checkFiles());
                    if (i < attempts && r.filter(b => !b).length > 0) {
                        setTimeout(f, delay);
                    }
                    else {
                        this.stopProcessing();
                    }
                } catch { () => {
                    this.stopProcessing();
                    }
                }
            };
            setTimeout(f, delay);
        },
        clearForm: function() {
            this.$refs.form.reset()
            this.uploadPercentage = 0
            this.fileSource = 2
            this.comment = ''
            this.formFiles = []
            this.uploadResult = {
                file_counter: 0,
                error_counter: 0,
            }
            this.uploadedFiles = []
            this.processedFiles = []
            this.errorMessage = ''
            this.uploadStatus = 'none'
        },
        clearError: function() {
            this.clearForm();
            this.$emit('upload-complete');
        },
        showUploadDialog: function () {
            this.$refs.uploader.click();
        },
    },
}
</script>