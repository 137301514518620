<template>

    <v-dialog
    v-model="dialog"
    max-width="600"
    scrollable
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
                Параметры
            </v-btn>
        </template>
        <v-card>
            <v-toolbar
            dark
            color="primary"
            >
                <v-toolbar-title>Параметры поиска</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                icon
                dark
                @click="dialog=false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-container class="pa-8">

                    <v-row>
                        <v-col>
                            <select-user
                            outlined
                            v-model="formData.user_id"
                            label="Сотрудник"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <select-date-range
                                label="Период"
                                :minValue="formData.date_from"
                                :maxValue="formData.date_to"
                                @setFields="setFormFields({ 'date_from': $event.min, 'date_to': $event.max })"
                            />
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col
                        class="pa-2">
                            <v-checkbox
                            v-model="formData.external_only"
                            label="Только внешние заходы"
                            dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>

            <v-divider/>

            <v-card-actions>
                <v-btn
                color="primary"
                elevation="2"
                @click="formSearchAction"
                large
                class="mx-auto my-2"
                >
                Найти
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

</template>

<script>
import searchFormMixin from '@/mixins/search-form-mixin.js'
import selectDateRange from '@/components/form-elements/select-date-range.vue'
import selectUser from '@/components/form-elements/select-user.vue'

export default {
    name: 'auth-log-search-form',
    components: { 
        selectDateRange,
        selectUser,
    },
    mixins: [
        searchFormMixin,
    ],
}
</script>