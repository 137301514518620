
export default {

    methods: {
        bitmaskToArray: function (bitmask) {
            let result = [];
            if (+bitmask){
                result = (+bitmask).toString(2).split("").reverse().map(function(item, index) {
                    if ( +item ) {
                        return 2**index;
                    } else {
                        return 0;
                    }
                }).filter(item => item > 0);
            } 
            return result;
        },
        arrayToBitmask: function (arr) {
            let result = 0;
            if (arr) {
                arr.forEach(function(item) {
                    result = (result | item)
                })
            }
            return result;
        }
    }

}

