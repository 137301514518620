<template>
    <v-container>

        <v-row>
            <v-col>
                <span class="text-h5">Бонусы</span>
            </v-col>
            <v-col v-if="showUserSelectUserControl">
                <select-manager
                outlined
                v-model="userId"
                label="Ответственный"
                @change="doRequest=true"
                ></select-manager>
            </v-col>
        </v-row>


        <v-row>
            <v-col
            cols="12">
                <data-loader
                url="/api/favorites"
                :do-request="doRequest"
                :form-data="{user_id: userId}"
                @loading-complete="onLoadData">
                    <template v-slot:default>
                        <v-data-table dense
                        :items-per-page="-1"
                        hide-default-footer
                        :headers="headers"
                        :items="favorites"
                        class="elevation-1"
                        >
                            <template v-slot:[`item.id`]="{ item }">
                                <a
                                target="_blank" 
                                :href="'http://megapolis.mega-realty.ru/proxy.htm?type=square&id=' + item.id">
                                    {{item.id}}
                                </a>
                            </template>
                            <template v-slot:[`item.address`]="{ item }">
                                <span :class="{ 'grey--text': !item.status, 'red lighten-4': !item.status }">{{item.address}}</span>
                            </template>
                            <template v-slot:[`item.square`]="{ item }">
                                {{item.square}} м²
                            </template>
                            <template v-slot:[`item.photo`]="{ item }">
                                <div class="text-no-wrap">
                                    <v-chip
                                    small
                                    title="Фото здания"
                                    :color="photoCounterColor(item.building_photo_count)">
                                        {{item.building_photo_count || 0}}
                                    </v-chip>
                                    <v-chip
                                    small
                                    title="Фото помещения"
                                    :color="photoCounterColor(item.photo_count)">
                                        {{item.photo_count || 0}}
                                    </v-chip>
                                    <v-chip
                                    small
                                    title="Планировки"
                                    :color="photoCounterColor(item.floorplan_count)">
                                        {{item.floorplan_count || 0}}
                                    </v-chip>
                                </div>
                            </template>
                            <template v-slot:[`item.calltime`]="{ item }">
                                {{item.calltime}} дн.
                            </template>
                            <template v-slot:[`item.changetime`]="{ item }">
                                {{item.changetime}} дн.
                            </template>
                            <template v-slot:[`item.no_ad`]="{ item }">
                                <v-icon
                                v-if="item.no_ad"
                                color="red"
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </template>
                            <template v-slot:[`item.destination`]="{ item }">
                                <v-chip
                                small
                                v-if="item.destination"
                                target="_blank"
                                :href="'https://www.cian.ru/cat.php?context=' + item.id + '&deal_type=' + (item.slContractType == 8 ? 'sale' : 'rent') + '&engine_version=2&offer_type=offices&office_type%5B0%5D=1&office_type%5B1%5D=2&office_type%5B2%5D=3&office_type%5B3%5D=4&office_type%5B4%5D=5&office_type%5B5%5D=7&office_type%5B6%5D=11&region=1'"
                                color="green lighten-2">
                                    {{bitCount(item.destination)}}
                                </v-chip>
                            </template>
                        </v-data-table>


                    </template>
                </data-loader>

            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p>Бонусов: {{countFavorites}}</p>
                <p>Из них в рекламе: {{countFavoritesAd}}</p>
                <p>Объявлений всего: {{countAdSum}}</p>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import selectManager from '@/components/form-elements/select-manager.vue'
export default {
    name: 'favorites-list',
    components: {
        dataLoader,
        selectManager
    },
    data () {
        return {
            favorites: [],
            userId: 0,
            doRequest: true,
            headers: [
                { text: 'id', value: 'id', class: "text-no-wrap" },
                { text: 'Адрес', value: 'address', class: "text-no-wrap" },
                { text: 'Этаж', value: 'floor', class: "text-no-wrap" },
                { text: 'Площадь', value: 'square', class: "text-no-wrap" },
                { text: 'Фото', value: 'photo', class: "text-no-wrap" },
                { text: 'Прозвон', value: 'calltime', class: "text-no-wrap" },
                { text: 'Изменено', value: 'changetime', class: "text-no-wrap" },
                { text: 'Конкуренты', value: 'count', class: "text-no-wrap" },
                { text: 'Запрет', value: 'no_ad', class: "text-no-wrap" },
                { text: 'Фид', value: 'destination', class: "text-no-wrap" },              
            ],
        }
    },
    computed: {
        showUserSelectUserControl: function () {
            return this.$checkPermissions('view_Third-Party_Favorites');
        },
        countFavorites: function () {
            return this.favorites.length;
        },
        countFavoritesAd: function () {
            return this.favorites.filter(item => item.destination).length;
        },
        countAdSum: function () {
            return this.favorites.map(item => this.bitCount(item.destination)).reduce((sum, current) => sum + current, 0);
        },
    },
    methods: {
        onLoadData: function (data) {
            this.favorites = [];
            this.doRequest = false;
            if (data) {
                this.favorites = data.list;
            }
        },
        bitCount: function (data) {
            let result = 0;
            for (let i = Number(data) || 0; i > 0; i = i >> 1) {
                if( (i & 1) === 1 ){
                    result++;
                }
            }
            return result;
        },
        photoCounterColor: function (data) {
            return (data ? 'green lighten-2' : 'yellow lighten-2');
        },

    },
    created() {
        this.userId = this.$store.state.user.id;
    },
}
</script>