<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <span class="text-h5">Завершённые загрузки
                    <v-btn
                    icon
                    small
                    color="primary"
                    @click="doRequest = true"
                    >
                    <v-icon>mdi-cached</v-icon>
                    </v-btn>
                </span>
             </v-col>
        </v-row>
        <v-row align="center">
            <v-col
            v-if="showUserSelectControl"
            sm="4"
            cols="12"
            >
                <select-user
                outlined
                v-model="requestUserId"
                ></select-user>
            </v-col>
            <v-col
            sm="4"
            md="3"
            cols="12">
                <v-text-field
                    v-model="requestBuildingId"
                    label="ID здания"
                    outlined
                    clearable
                    hide-details
                    dense
                ></v-text-field>
            </v-col>
            <v-col
            sm="4"
            md="3"
            cols="12">
                <v-text-field
                    v-model="requestObjectId"
                    label="ID помещения"
                    outlined
                    clearable
                    hide-details
                    dense
                ></v-text-field>
            </v-col>
            <v-col
            sm="6"
            cols="12">
                <v-text-field
                    v-model="requestTextQuery"
                    label="Поиск в комментарии"
                    outlined
                    clearable
                    hide-details
                    dense
                ></v-text-field>
            </v-col>
            <v-col
            sm="6"
            cols="12">
                <v-btn
                    color="primary"
                    @click="doRequest = true"
                >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    Найти
                </v-btn>
            </v-col>
        </v-row>

        <data-loader
        pagination
        url="/api/media-cloud/media-uploads-list/"
        :form-data="searchFormData"
        :do-request="doRequest"
        :check-response="checkResponse"
        @loading-complete="onLoadData">
            <template v-slot:default>
                <media-uploads-item
                v-for="item in uploads"
                :key="item.ID"
                :upload="item"></media-uploads-item>
            </template>
        </data-loader>

    </v-container>
</template>

<script>
import mediaUploadsItem from './components/media-uploads-item.vue'
import selectUser from '@/components/form-elements/select-user.vue'
import dataLoader from '@/components/data-loader.vue'

export default {
    name: 'mediaUploadsList',
    components: { 
      mediaUploadsItem,
      selectUser,
      dataLoader
    },
    data () {
      return {
            doRequest: false,
            checkResponse: function (response) {
                return (response.uploads && response.uploads.length > 0);
            },
            uploads: [],
            requestTextQuery: '',
            requestUserId: 0,
            requestBuildingId: undefined,
            requestObjectId: undefined
      }
    },
    computed: {
        showUserSelectControl: function () {
            return this.$checkPermissions('view_Third-Party_Uploads')
        },
        storeUserId: function () {
            return this.$store.state.user.id
        },
        searchFormData: function () {
            return {
                text: this.requestTextQuery,
                user_id: this.requestUserId,
                building_id: this.requestBuildingId,
                square_id: this.requestObjectId,
                }
        },
    },
    methods: {
        onLoadData: function (data) {
            this.uploads = [];
            this.doRequest = false;
            if ( data && this.checkResponse(data) ) {
                this.uploads = data.uploads;
            }
        },
    },
    created() {
        this.requestUserId = this.storeUserId;
        this.doRequest = true;
        this.$eventBus.$on('on-api-connection', this.doRequest = true);
        this.$eventBus.$on('on-new-media-upload', this.doRequest = true);
    },
    watch: {
        storeUserId: function (n) {
            this.requestUserId = n;
        },
    },
}
</script>