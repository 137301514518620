import mediaUploads from './media-uploads'
import fileUpload from './file-upload'
import objectUploads from './object-uploads'
import buildingFotoMap from './building-foto-map'

export default [
  {
    path: '/media-uploads',
    name: 'media-uploads',
    component: mediaUploads
  },

  {
    path: '/object-uploads/:type/:id',
    name: 'object-uploads',
    component: objectUploads
  },

  {
    path: '/file-upload/:type?/:id?',
    name: 'file-upload',
    component: fileUpload,
    meta: { keepAlive: true }
  },

  {
    path: '/building-foto-map',
    name: 'building-foto-map',
    component: buildingFotoMap
  },


];