<template>
    <v-list-item
    v-if="this.$store.state.user"
    two-line
    :to="{ path: '/'}"
    link
    >
        <v-list-item-action>
            <v-icon>
                mdi-account
            </v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>
                {{!!this.$store.state.user.id ? (this.$store.state.user.name + ' ' + this.$store.state.user.surname) : 'Учётная запись'}}
            </v-list-item-title>
            <v-list-item-subtitle
            link
            @click="dialog = true">
                Logout
            </v-list-item-subtitle>
        </v-list-item-content>

        <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title>Выйти из системы?</v-card-title>
            <v-card-text>Несохранённые данные могут быть потеряны</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">Остаться</v-btn>
            <v-btn color="green darken-1" text @click="logout">Выйти</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <v-overlay
            :value="loading"
            :absolute="true">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
    </v-list-item>
</template>

<script>
export default {
    name: 'app-menu-user-block',
    data () {
      return {
          loading: true,
          dialog: false
      }
    },
    methods: {
        init: async function () {
            this.loading = true
            try {
                await this.$store.dispatch("load_user_data")
                this.loading = false
            } catch(err) {
                this.loading = false
            }
        },
        logout: function () {
            this.$store.dispatch("reset_authorization")
            this.dialog = false
        },
    },
    created() {
        this.init()
        this.$eventBus.$on('on-api-connection', this.init)
    },
}
</script>