<template>

    <v-expansion-panel class="mb-4">
        <request-heading
        :request="request">
        </request-heading> 
        <v-expansion-panel-header>
            <request-actions-summary
            :request="request">
            </request-actions-summary> 
        </v-expansion-panel-header>
        <v-expansion-panel-content>

            <request-actions
            :requestId="request.ID"/>

        </v-expansion-panel-content>
    </v-expansion-panel>


</template>





<script>
import requestHeading from '@/components/entities/request/request-heading.vue'
import requestActionsSummary from '@/components/entities/request/request-actions-summary.vue'
import requestActions from '@/components/entities/request/request-actions.vue'

export default {
    name: 'request-search-item',
    components: { 
      requestHeading,
      requestActionsSummary,
      requestActions,
    },
    props: {
        request: {
            type: Object
        },
    },



}
</script>