<template>

    <data-loader
    pagination
    url="/api/offer/search"
    :form-data="searchParams"
    :do-request="doRequest"
    @loading-complete="onLoadData">
        <template v-slot:default>
            <v-simple-table
            dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Действие</th>
                            <th>Сотрудник</th>
                            <th>Дата</th>
                            <th>Заявка</th>
                            <th>Адрес</th>
                            <th>Площадь</th>
                            <th>Менеджер</th>
                        </tr>
                    </thead>
                    <tbody>
                        <offer-search-item
                        v-for="item in searchResults"
                        :key="item.ID"
                        :offer="item"
                        />
                    </tbody>
                </template>
            </v-simple-table>
        </template>
    </data-loader>

</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import offerSearchItem from './offer-search-item.vue'

export default {
    name: 'offers-list',
    components: { 
        dataLoader,
        offerSearchItem,
    },
    props: {
        doRequest: {
            type: Boolean,
            default: false
        },
        searchParams: {
            type: Object
        },
    },
    data () {
        return {
            counter: '',
            searchResults: [],
        }
    },
    methods: {
        onLoadData: function (data) {
            this.searchResults = [];
            this.counter = '';
            if ( data ) {
                this.searchResults = data.list;
                this.counter = '' + data.row_count;
            }
            this.$emit('loading-complete')
        },
    }

}
</script>