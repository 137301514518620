<template>
    <span>
        <v-chip
        :key="item.val"
        v-for="item in selected"
        class="ma-2"
        close
        @click:close="setFields({[name]: value - item.val})"
        >
        {{item.name}}
        </v-chip>
    </span>
</template>


<script>
import bitmaskArrayConverter from '@/mixins/bitmask-array-converter.js'

export default {
    name: 'search-chips-mask',
    mixins: [
        bitmaskArrayConverter,
    ],
    props: {
        name: {
            type: String
        },
        value: {
            type: Number
        },
        options: {
            type: Array
        },
    },

    computed: {
        selected: function () {
            return this.selectedByMask(this.value, this.options);
        },
    },

    methods: {
        setFields: function (obj) {
            if (obj) {
                this.$emit( 'setFields', obj )
            }
        },
        selectedByMask: function (mask,options) {
            let result = [];
            let s = this.bitmaskToArray(mask);
            if (options){
                options.forEach(function(item){
                    if (s.includes(item.val)){
                        result.push(item);
                    }
                })
            }
            return result;
        },
    }
}
</script>