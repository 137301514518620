<template>
    <v-container>


        <v-overlay
            :value="loading"
            :absolute="true">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col
            cols="6">
                <v-text-field
                    label="Ссылка на YouTube"
                    v-model="newLink.link"
                ></v-text-field>
            </v-col>
            <v-col
            cols="6"
            sm="3"
            lg="2">
                <v-checkbox
                v-model="newLink.public"
                label="публиковать"
                ></v-checkbox>
            </v-col>
            <v-col
            cols="12"
            sm="3"
            lg="4"
            class="my-auto">
                <v-btn
                color="primary"
                :disabled="!correctLink && currentType.value !== 0"
                @click="insertLink"
                >
                Прикрепить
                </v-btn>
            </v-col>
        </v-row>

        <data-loader
        url="/api/youtube-links/list/"
        :form-data="currentType.data"
        :do-request="doRequest"
        @loading-complete="onLoadData">
            <template v-slot:default>

                <div
                v-if="youtubeLinksList && youtubeLinksList.length > 0">

                    <v-row>
                        <v-col>
                            <span class="text-h6">Прикреплённые видео</span>
                        </v-col>
                    </v-row>

                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        URL
                                    </th>
                                    <th>
                                        публиковать
                                    </th>
                                    <th>
                                        удалить
                                    </th>
                                    <th>
                                        добавлено
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in youtubeLinksList"
                                :key="item.id"
                                
                                >
                                    <td>
                                        <a
                                        target="_blank"
                                        :href="item.link"
                                        :class="{ 'text--disabled': item.del }"
                                        >
                                            {{ item.link }}
                                        </a>
                                    </td>
                                    <td>
                                        <v-checkbox
                                        v-model="item.public"
                                        @change="setPublic(item.id,item.public)"
                                        v-if="!item.del"
                                        ></v-checkbox>
                                    </td>
                                    <td>

                                        <v-icon
                                        color = "red darken-1"
                                        @click="deleteLink(item.id)"
                                        v-if="!item.del"
                                        >
                                            mdi-delete-forever
                                        </v-icon>

                                        <v-tooltip
                                        v-if="item.del"
                                        class="text--disabled"
                                        top
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                удалено
                                                </span>
                                            </template>
                                            {{item.date_del}}
                                            <v-chip
                                            small
                                            class="ml-2"
                                            >
                                                <staff-name :id='item.user_del'></staff-name>
                                            </v-chip>
                                        </v-tooltip>
                                    </td>
                                <td>
                                        <span class="mr-2">{{item.date}}</span>
                                            <v-chip
                                            small
                                            >
                                                <staff-name :id='item.user_ins'></staff-name>
                                            </v-chip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </template>
        </data-loader>




    </v-container>
</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import staffName from '@/components/staff-name.vue'

export default {
    name: 'youtubeObjectLinks',

    components: {
        dataLoader,
        staffName,
    },

    props: {
        id: {
            type: Number
        },
        type: {
            type: String
        },
    },

    data () {
      return {

        doRequest: false,
        searchResults: [],
        loading: false,
        errorMessage: '',
        newLink: {
            link: '',
            public: false
        },
        youtubeLinksList: [] 
      }
    },
    computed: {
        currentType: function () {

            const currentTypes = {
                square: {
                    value: 1,
                    name: 'Помещение',
                    data: {
                        square_id: +this.id
                    }
                },
                building: {
                    value: 2,
                    name: 'Здание',
                    data: {
                        building_id: +this.id
                    }
                }
            };

            if (this.id && this.type) {
                return currentTypes[this.type];
            } else {
                return {
                    value: 0,
                    name: '',
                    data: {}
                }
            }
        },
        correctLink: function () {
            return this.newLink.link && (this.newLink.link.startsWith('https://youtu.be/') || this.newLink.link.startsWith('https://www.youtube.com/'))
        },
    },
    methods: {
        onLoadData: function (data) {
            this.youtubeLinksList = [];
            this.doRequest = false;
            if ( data ) {
                this.youtubeLinksList = data.list;
            }
        },
        insertLink: function () {
            if(this.newLink.link && this.currentType.value !== 0){
                this.loading = true;
                const d = {
                    link: this.newLink.link,
                    public: this.newLink.public
                };
                Object.assign(d, this.currentType.data);
                this.$http({url: '/api/youtube-links/add/', method: 'POST', data: d})
                .then(
                    () => {
                        this.newLink.link = '';
                        this.newLink.public = false;
                        this.loading = false;
                        this.errorMessage = '';
                        this.doRequest = true;
                    },
                    () => {
                        this.newLink.link = '';
                        this.newLink.public = false;
                        this.loading = false;
                        this.errorMessage = 'Ошибка загрузки данных';
                });
            }
        },
        deleteLink: function (id) {
            if(confirm('Точно удалить?')) {
                this.loading = true;
                this.$http({url: '/api/youtube-links/del/', method: 'POST', data: {id: id}})
                .then(
                    () => {
                        this.loading = false;
                        this.errorMessage = '';
                        this.doRequest = true;
                    },
                    () => {
                        this.loading = false;
                        this.errorMessage = 'Ошибка загрузки данных';
                });
            }
        },
        setPublic: function (id,publicState) {

            this.loading = true;
            this.$http({url: '/api/youtube-links/edit/', method: 'POST', data: {id: id, public: publicState}})
            .then(
                () => {
                    this.loading = false;
                    this.errorMessage = '';
                    this.doRequest = true;
                },
                () => {
                    this.loading = false;
                    this.errorMessage = 'Ошибка загрузки данных';
            });

        }

    },
    created() {
        this.doRequest = true;
    }

}
</script>