<template>
    <v-card
    :key="file.ID"
    class="ma-1 elevation-6 float-left"
    max-width="224px"
    >
        <v-container
        v-if="file.Type == 2 && file.Error != 1">
            <div
            style="height: 200px; width: 200px; padding-top: 80px"
            align="center"
            >
                <v-icon
                x-large
                >mdi-file-video-outline</v-icon>
                <div>video file</div>
            </div>
        </v-container>
        <v-container
        v-else-if="file.Type == 1 && file.Error != 1">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-img
                    height="200"
                    width="200"
                    :src="thumb_src"
                    v-bind="attrs"
                    v-on="on"
                    >
                        <template v-slot:placeholder>
                            <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular indeterminate color="grey"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </template>
                <div>File name: {{file.Original_Filename}}</div>
                <!--
                <div>Camera: {{file.ExifCamera}}</div>
                <div>EXIF Date: {{file.ExifDate}}</div>
                -->
                <div>Date: {{file.Date}}</div>
                <div>Height: {{file.Image_Height}}</div> 
                <div>Width: {{file.Image_Width}}</div> 
            </v-tooltip>
        </v-container>
        <v-container
        v-else>
            <div
            style="height: 200px; width: 200px; padding-top: 80px"
            align="center"
            >
                <v-icon
                x-large
                color="error"
                >mdi-alert</v-icon>
                <div>error</div>
            </div>
        </v-container>
        <v-card-actions>

            <v-spacer></v-spacer>

            <v-btn
            v-if="file.binding"
            icon>
            <v-icon>mdi-link-variant</v-icon>
            </v-btn>

            <v-btn icon>
            <v-icon>mdi-image-edit</v-icon>
            </v-btn>

            <v-btn icon>
            <a :href="download_src"><v-icon>mdi-download</v-icon></a>
            </v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
export default {
    name: 'media-file-preview',
    props: {
        file: {
            type: Object
        },
    },
    data () {
      return {
      }
    },
    computed: {
        thumb_src: function () {
            return '/private-cloud/thumbnails/' + this.file.Object_Key + '.jpg'
        },
        download_src: function () {
            return '/private-cloud/fullsize/' + this.file.Object_Key + '.jpg/download'
        }
    },
}
</script>