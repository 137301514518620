
export default {

    methods: {
        fixPhoneString: function (val) {
            if ( val && (typeof val === 'string' || val instanceof String)) {
                if ( val.startsWith('+7') ) {
                    val = val.slice(2);
                }
                val = val.replace(/\D/g, '');
                if ( val.length == 11 && val.startsWith('8') ) {
                    val = val.slice(1);
                }
            }
            return val;
        }
    }

}

