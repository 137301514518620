<template>
  <v-app id="inspire">
    <critical-error></critical-error>
    <login-form></login-form>

    <!-- Status message -->
    <v-snackbar
      v-model="showStatusMessage"
      :color="statusColor"
      :timeout="5000"
    >
      {{ statusMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="showStatusMessage = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Error message -->
    <v-snackbar
      :value="showErrorMessage"
      :multi-line="true"
      :timeout="-1"
    >
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          icon
          v-bind="attrs"
          @click="showErrorMessage = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-navigation-drawer
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    >


      <main-menu/>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item
            link
            disabled
          >
            <v-list-item-action>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Настройки
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <api-indicator></api-indicator>
        </v-list>
      </template>

    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4 hidden-sm-and-down"
      >
        <span>Megapolis 2.0</span>
      </v-toolbar-title>
      <v-text-field
        flat
        solo-inverted
        hide-details
        disabled
        prepend-inner-icon="mdi-magnify"
        label="Search"

      ></v-text-field>
      <v-spacer></v-spacer>

      <v-list
      class="py-0 d-none d-md-block"
      color="blue darken-3"
      dark
      nav
      :to="{ path: '/'}">
        <app-menu-user-block/>
      </v-list>


      <v-btn
      icon
      disabled
      >
        <v-icon>mdi-bell</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main
    v-if="this.$store.state.appInitComplete">
      <v-overlay
          :value="loading"
          :absolute="true">
          <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import loginForm from './modules/app/components/login-form.vue'
import criticalError from './modules/app/components/critical-error.vue'
import apiIndicator from './modules/app/components/api-indicator.vue'
import mainMenu from './main-menu.vue'
import appMenuUserBlock from './modules/app/components/app-menu-user-block.vue'

  export default {
    components: { 
      loginForm,
      criticalError,
      apiIndicator,
      mainMenu,
      appMenuUserBlock,
    },
    data: () => ({
      loading: false,
      dialog: false,
      drawer: null,
      showErrorMessage: false,
      showStatusMessage: false,
      menuItems: [],
      errorMessage: '',
      statusSuccess: false,
      statusMessage: '',
    }),
    computed: {
      statusColor: function () {
        if (this.statusSuccess) {
          return 'success';
        } else {
          return 'red accent-2';
        }
      },
    },
    methods: {
      init: function () {
        return Promise.all([
          this.$store.dispatch("load_staff_full_names"),
          this.$store.dispatch("load_staff_manager_names"),
          this.$store.dispatch("load_user_permissions"),
          this.$store.dispatch("load_user_data"),
          this.$store.dispatch("load_metro_stations")
        ]).then(
          () => {
            this.$store.dispatch('create_metro_checkboxes');
            this.$store.dispatch('app_init_complete');
          },
          () => this.$store.dispatch("throw_critical_error", new Error('Ошибка инициализации приложения'))
        )
      },
      testConnection: function () {
        this.$http({url: '/api/ping/', method: 'GET'})
          .then(
            () => {
              this.$eventBus.$emit('on-api-connection')
            },
            () => {
              return
            }
          )
      },
    },
    created() {
      this.$eventBus.$on('on-api-connection', this.init);
      this.$store.dispatch('load_token_from_cookie');
      this.$eventBus.$on('on-get-error', (msg) => {
        if (msg && msg !== '') {
          this.errorMessage = msg;
        } else {
          this.errorMessage = 'Ошибка!';
        }
        this.showErrorMessage = true;
      });
      this.$eventBus.$on('on-get-status', (success, msg) => {
        this.statusSuccess = success;
        if ( msg && msg !== '') {
          this.statusMessage = msg;
        } else if (success) {
          this.statusMessage = 'Status OK';
        } else {
          this.statusMessage = 'Status NO';
        }
        this.showStatusMessage = true;        
      });
      this.$eventBus.$on('on-start-loading-data', () => {this.loading = true});
      this.$eventBus.$on('on-stop-loading-data', () => {this.loading = false});
      this.testConnection();
    },
  }
</script>