<template>
    <v-container fluid>

        <v-row>
            <v-col>
                <span class="text-h5">Загрузка изображений без привязки к объекту</span>
            </v-col>
        </v-row>

        <v-form
        ref="form"
        >
            <div
            v-on:drop="addDropFile"
            v-on:dragover="allowDrop"
            >
                <v-file-input
                multiple
                small-chips
                show-size
                counter
                loading="false"
                accept="image/*"
                prepend-icon="mdi-camera"
                label="Выберите файлы или перетащите их сюда"
                v-model='files'
                :disabled="disabledForm"
                @click="uploadStatus = 'none'"
                class="mb-4"
                >
                </v-file-input>
            </div>

            <v-progress-linear
            v-if="!noFiles && !errorMessage"
            v-model="uploadPercentage"
            class="mb-4"
            ></v-progress-linear>

            <v-alert
            v-if="uploadStatus === 'processing' || completeWithErrors"
            :dismissible="completeWithErrors"
            :color="completeWithErrors ? 'error' : (uploadStatus === 'complete') ? 'success' : 'yellow'"
            @click="clearForm"
            >
                {{alertMessage}}
                
                <v-divider
                class="my-4"
                ></v-divider>

                <v-list
                v-if="uploadedFiles"
                flat>

                    <media-file-processing-item
                    v-for="file in uploadedFiles"
                    v-bind="file"
                    :key="file.id"></media-file-processing-item>

                </v-list>

            </v-alert>

            <v-alert
            dismissible
            v-if="uploadStatus === 'complete' && !completeWithErrors"
            type="success"
            @click="clearForm"
            >
                Файлы успешно загружены
            </v-alert>


            <v-alert
            dismissible
            v-if="errorMessage"
            type="error"
            @click="clearError"
            >
                Загрузка завершилась ошибкой: {{errorMessage}}
            </v-alert>

            <v-select
            v-if="!disabledForm"
            :items="file_sources"
            label="Источник материалов"
            class="mt-4"
            v-model="fileSource"
            outlined
            ></v-select>
        
            <v-textarea
            v-if="!disabledForm"
            outlined
            auto-grow
            name="comment"
            label="Комментарий к загрузке"
            v-model="comment"
            ></v-textarea>

        </v-form>
 
        <v-btn
        color="primary"
        :disabled="noFiles || uploadStatus !== 'none'"
        @click="submitFiles"
        >
        Загрузить
        </v-btn>

        <v-row>
            <v-col>
                <span
                v-if="processedFiles.length > 0"
                class="text-subtitle-1 mt-4"
                >Успешно обработано:</span>
            </v-col>
        </v-row>

        <v-row>
            <file-upload-preview
            v-for="item in processedFiles"
            :file="item"
            :key="item.key"
            ></file-upload-preview>
        </v-row>

    </v-container>
</template>


<script>
import mediaFileProcessingItem from './components/media-file-processing-item.vue'
import fileUploadPreview from './components/file-upload-preview.vue'

export default {
    name: 'mediaFileUpload',
    components: { 
      mediaFileProcessingItem,
      fileUploadPreview,
    },
    data () {
      return {

        // File upload
        files: [],
        uploadResult: {
            file_counter: 0,
            error_counter: 0,
        },
        uploadedFiles: [],
        processedFiles: [],
        comment: '',
        fileSource: 2,
        uploadPercentage: 0,
        errorMessage: '',
        uploadStatus: 'none',
        file_sources: [
            {value:2, text:'источник неизвестен'},
            {value:3, text:'собственная съёмка'},
            {value:4, text:'прислал собственник'},
            {value:5, text:'сайт в интернете'},
            {value:6, text:'чужие объявления'},
        ]
      }
    },
    computed: {
        noFiles: function() {
            return !(this.files.length > 0)
        },
        disabledForm: function() {
            return !(this.uploadStatus === 'none')
        },
        completeWithErrors: function() {
            return (this.uploadStatus === 'complete' && this.uploadedFiles.filter(f => f.error).length > 0)
        },
        alertMessage: function() {
            if (this.uploadStatus === 'error') {
                return 'Загрузка завершилась ошибкой: ' + this.errorMessage;
            } else if (this.completeWithErrors) {
                return 'При обработке файлов возникли ошибки';
            } else if (this.uploadStatus === 'complete') {
                return 'Все файлы успешно загружены и обработаны';
            } else if (this.uploadStatus === 'processing') {
                return 'Файлы загружены и обрабатываются' + ((this.uploadResult.error_counter > 0) ? '. Внимание! При загрузке возникли ошибки.' : '');
            } else {
                return '';
            }
        }
    },
    methods: {
        clearForm: function() {
            if (this.uploadStatus === 'complete') {
                this.$refs.form.reset()
                this.uploadPercentage = 0
                this.fileSource = 2
                this.comment = ''
                this.files = []
                this.uploadResult = {
                    file_counter: 0,
                    error_counter: 0,
                }
                this.uploadedFiles = []
                this.processedFiles = []
                this.errorMessage = ''
                this.uploadStatus = 'none'
            }
        },
        clearError: function() {
            this.errorMessage = ''
            this.uploadStatus = 'none'
        },
        addDropFile: function(event) {
            event.preventDefault();
            Array.from(event.dataTransfer.files).forEach((item) => {
                if (this.files.filter(f => f.name === item.name).length == 0) {
                    this.files.push(item)
                }
            });
        },
        allowDrop:function(event) {
            event.preventDefault();
        },
        submitFiles: function() {
            if (this.files.length > 0) {
                this.uploadStatus = 'upload';
                let formData = new FormData();

                // files
                for (let file of this.files) {
                    formData.append("files", file, file.name);
                }

                // fields
                if (this.comment)
                    formData.append("comment", this.comment);
                if (this.fileSource)
                    formData.append("source", this.fileSource);

                this.$http
                    .post("/api/media-cloud/upload-media-files/", formData, {
                        onUploadProgress : (progressEvent) => {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }
                    })
                    .then(response => {
                        this.uploadResult.file_counter = response.file_counter;
                        this.uploadResult.error_counter = response.error_counter;
                        if (response.files) {
                            response.files.forEach( f => {
                                let tmp = f;
                                tmp.complete = false;
                                this.uploadedFiles.push(tmp);
                            })
                        }
                        if (response.files.length > 0 && response.file_counter > response.error_counter) {
                            this.errorMessage = '';
                            this.checkFileProcessing();
                        } else {
                            this.uploadStatus = 'error';
                            this.errorMessage = 'Ошибка загрузки файлов';
                        }
                    })
                    .catch(error => {
                        this.errorMessage = error.message || 'server error';
                        this.uploadStatus = 'error';
                    });
            } else {
                console.log("there are no files.");
            }
        },
        checkFiles: function() {
            var pa = [];
            this.uploadedFiles.forEach( (file, index) => {
                pa.push(new Promise((resolve) => {
                    if (file.complete) {
                        return resolve(true);
                    } else if (file.error) {
                        return resolve(true);
                    } else if (file.isImage) {
                        this.$http({url: '/api/media-cloud/check-cloud-file/' + file.key + '/3', method: 'GET'})
                        .then(
                            response => {
                                if (response.result) {
                                    this.$set(this.uploadedFiles[index], 'complete', true);
                                    let tmp = file;
                                    this.processedFiles.push(tmp);
                                    return resolve(true);
                                } else {
                                    return resolve(false);
                                }
                            },
                            () => {
                                return resolve(false);
                            }
                        )
                    } else {
                        // Если это не картинка — временно возвращаем true, потом добавим проверку
                        return resolve(true);
                    }
                }));
            })
            return pa;
        },
        stopProcessing: function(){
            this.uploadedFiles.forEach( (file, index) => {
                if (!file.complete) this.$set(this.uploadedFiles[index], 'error', true);
            })
            this.uploadStatus = 'complete';
        },
        checkFileProcessing: function() {
            this.uploadStatus = 'processing';
            let i = 0;
            let attempts = 60;
            let delay = 5000;
            var f = async () => {
                i++;
                try {
                    let r = await Promise.all(this.checkFiles());
                    if (i < attempts && r.filter(b => !b).length > 0) {
                        setTimeout(f, delay);
                    }
                    else {
                        this.stopProcessing();
                    }
                } catch { () => {
                    this.stopProcessing();
                    }
                }
            };
            setTimeout(f, delay);
        },
    },

}
</script>