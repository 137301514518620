<template>

    <v-dialog
    v-model="dialog"
    max-width="540"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                text
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
                {{buttonText}}
            </v-btn>
        </template>
        <v-card>
            <v-toolbar
            dark
            color="primary"
            >
                <v-toolbar-title>Выбор объекта</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                icon
                dark
                @click="dialog=false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container class="pa-8">

            <v-row>

                <v-col
                cols="12"
                sm="4"
                >
                    <v-text-field
                        v-model="id"
                        label="ID"
                        :rules="[rules.int]"
                        outlined
                        clearable
                    ></v-text-field>
                </v-col>

                <v-col
                cols="12"
                sm="4"
                >
                    <v-select
                    :items="typeList"
                    label="Что ищем"
                    v-model="type"
                    outlined
                    hide-details="auto"
                    ></v-select>
                </v-col>

                <v-col
                cols="12"
                sm="4"
                >
                    <v-btn
                    color="primary"
                    elevation="2"
                    x-large
                    :disabled = idIsUndefined
                    @click="clickFind"
                    >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    Найти
                    </v-btn>
                </v-col>
            </v-row>

            </v-container>

        </v-card>
    </v-dialog>



</template>

<script>
    export default {
        name: 'select-entity-dialog',
        props: {
            path: {
                type: String,
            },
            defaultType: {
                type: String,
            },
            typeList: {
                type: Array,
                default: () => [
                    {value:'object', text:'Помещение'},
                    {value:'building', text:'Здание'},
                    {value:'fb', text:'ФЗ'},
                ]
            },
            buttonText: {
                type: String,
                default: 'Выбрать объект'
            },
        },
        //components: {},
        data () {
            return {
                dialog: false,
                id: '',
                type: (this.path && this.$route.params.type) || this.defaultType || undefined,
                rules: {
                    int: value => (+value == 0 || !!+value || 'Введите число')
                },
            }
        },
        computed: {
            idIsUndefined: function () {
                return !(this.type && parseInt(this.id) && parseInt(this.id) > 0);
            },
            paramsType: function () {
                return this.$route.params.type;
            },
            paramsId: function () {
                return this.$route.params.id;
            },
        },
        methods: {
            clickFind: function () {
                if( this.path ) {
                    if (this.type != this.$route.params.type || this.id != this.$route.params.id ) {
                        this.$router.push({ path: this.path + this.type + '/' + this.id });
                    }
                }
                this.dialog = false;
                this.$emit('on-search', { type: this.type, id: this.id });
            },
            pathChanged: function () {
                this.type = this.$route.params.type;
                this.id = +this.$route.params.id;
                if (this.type && this.id) {
                    this.$emit('on-search', { type: this.type, id: this.id });
                } else {
                    this.$emit('on-clear');
                }
            },
        },
        created() {
            this.pathChanged();
            if(this.path && +this.$route.params.id){
                this.id = +this.$route.params.id;
            }
        },
        watch: {
            paramsType: function () {
                this.pathChanged();

            },
            paramsId: function () {
                this.pathChanged();
            },
        }
    }
</script>

<style>
</style>