<template>

    <v-select
    v-model="currentOrder"
    :items="options"
    item-text="name"
    item-value="val"
    @input="changeOrder"
    outlined
    dense
    hide-details
    />


</template>

<script>

export default {
    name: 'search-sorter',
    props: {
        order: {
            type: Number
        },
        options: {
            type: Array
        },
    },

    data () {
        return {
            currentOrder: this.order,
        }
    },

    methods: {
        changeOrder: function (data) {
            this.$emit( 'change-order', data )
        }
    }

}
</script>
