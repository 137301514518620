<template>
    <v-card
    v-if="building"
    class="mb-8"
    >

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
            <a target="_blank" :href="'https://www.cian.ru/cat.php?deal_type=rent&engine_version=2&house[0]=' + building.building_id + '&offer_type=offices'">{{building.address}}</a>

            <a
              target="_blank"
              v-for="b in building.megapolis_buildings"
              :key="b"
              :href="'http://megapolis.mega-realty.ru/proxy.htm?type=building&id=' + b"
            >
              <v-chip
                class="ms-2"
                color="primary"
                small
                label
                link
              >
                {{ b }}
              </v-chip>
            </a>
        </v-list-item-title>


        <v-list-item-subtitle v-if="building.bc_id">
            <a target="_blank" v-if="building.use_parent_name" :href="'https://www.cian.ru' + building.parent_url">{{building.parent_name}}</a><span v-if="building.use_parent_name"> — </span><a target="_blank" :href="'https://www.cian.ru' + building.url">{{building.name}}</a>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-container>
        <cian-new-offers-offer
        v-for="item in building.offers"
        :key="item.id"
        :offer="item"></cian-new-offers-offer>
    </v-container>

    </v-card>
</template>


<script>
import cianNewOffersOffer from './cian-new-offers-offer.vue'

export default {
    name: 'cianNewOffersBuilding',
    components: { 
      cianNewOffersOffer
    },
    props: {
        building: {
            type: Object
        },
    },
    data () {
      return {}
    },
}
</script>