<template>
    <v-container>
        <v-overlay
            :value="!ready"
            :absolute="true">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>

        <v-row>
            <v-col>
                <span class="text-h5">Новые объявления</span>
            </v-col>
        </v-row>

        <v-row>

            <v-col
            cols="12"
            sm="6"
            >
                <select-manager
                outlined
                v-model="userId"
                label="Ответственный"
                ></select-manager>
            </v-col>

            <v-col
            cols="12"
            sm="6"
            >
                <v-text-field
                    v-model="minPrice"
                    label="Минимальная цена"
                    :rules="[v => +v || 'Введите число',]"
                    outlined
                    clearable
                    dense
                ></v-text-field>
            </v-col>


            <v-col
            cols="12"
            sm="6"
            md="4"
            lg="2"
            class="my-auto"
            >
            <v-btn
            text
            small
            color="primary"
            @click="setToday"
            >
            Сегодня
            </v-btn>
            <v-btn
            text
            small
            color="primary"
            @click="setYesterday"
            >
            Вчера
            </v-btn>
            <v-btn
            text
            small
            color="primary"
            @click="setWeek"
            >
            Неделя
            </v-btn>
            </v-col>
            <v-col
            cols="12"
            sm="3"
            md="4"
            lg="2"
            >
            <v-menu
                v-model="dateFromPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFrom"
                    label="Начальная дата"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                ></v-text-field>
                </template>
                <v-date-picker
                no-title
                v-model="dateFrom"
                @input="dateFromPicker = false"
                ></v-date-picker>
            </v-menu>
            </v-col>
            <v-col
            cols="12"
            sm="3"
            md="4"
            lg="2"
            >
            <v-menu
                v-model="dateToPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateTo"
                    label="Конечная дата"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                ></v-text-field>
                </template>
                <v-date-picker
                no-title
                v-model="dateTo"
                @input="dateToPicker = false"
                ></v-date-picker>
            </v-menu>
            </v-col>
            <v-col
            cols="12"
            sm="3"
            md="4"
            lg="2"
            >
                <v-select
                :items="accountAges"
                label="Возраст аккаунта"
                v-model="accountAge"
                outlined
                hide-details="auto"
                ></v-select>
            </v-col>
            <v-col
            cols="12"
            sm="3"
            md="4"
            lg="2"
            >
                <v-select
                :items="buildingsLimits"
                label="Число зданий"
                v-model="buildingsLimit"
                outlined
                hide-details="auto"
                ></v-select>
            </v-col>

            <v-col
            cols="12"
            sm="3"
            md="4"
            lg="2"
            >
                <v-btn
                color="primary"
                elevation="2"
                x-large
                @click="loadData"
                >
                <v-icon left>
                    mdi-magnify
                </v-icon>
                Найти
                </v-btn>
            </v-col>
        </v-row>


        <v-alert
        v-if="!initialState && ready && (!offers || offers.length == 0)"
        type="info"
        outlined
        text
        >
            Нет данных
        </v-alert>


        <v-alert
        v-if="initialState"
        type="info"
        outlined
        text
        >
            Воспользуйтесь поиском
        </v-alert>


        <v-chip
        v-if="ready && offers && offers.length > 0"
        color="primary"
        outlined
        >
        Найдено {{offers.length}}
        </v-chip>

        <span
        v-if="ready && excludedAgencies && excludedAgencies.length > 0"
        class="ml-4 mr-2">Скрыты предложения от</span>

        <v-chip
        v-for="item in excludedAgencies"
        :key="item.id"
        color="primary"
        class="mr-2"
        close
        @click:close="deleteAgencyFromExcluded(item.id)"
        >
        {{item.name}}
        </v-chip>

        <v-row>
            <v-col>
                <v-list
                v-if="ready && offers && offers.length > 0"
                flat>
                    <cian-new-offers-building
                    v-for="item in offers"
                    :key="item.building_id"
                    :building="item"></cian-new-offers-building>
                </v-list>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import cianNewOffersBuilding from './components/cian-new-offers-building.vue'
import selectManager from '@/components/form-elements/select-manager.vue'

export default {
    name: 'cianNewOffers',
    components: { 
      cianNewOffersBuilding,
      selectManager
    },
    data () {
        return {
            ready: true,
            initialState: true,
            offers: [],

            dateFrom: new Date().toISOString().substr(0, 10),
            dateTo: new Date().toISOString().substr(0, 10),
            dateFromPicker: false,
            dateToPicker: false,
            userId: 0,
            minPrice: undefined,

            accountAge: 0,
            accountAges: [
                {value:0, text:'Любой'},
                {value:1, text:'Сегодня'},
                {value:2, text:'2 дня'},
                {value:3, text:'3 дня'},
                {value:7, text:'7 дней'},
                {value:30, text:'30 дней'},
            ],

            buildingsLimit: 0,
            buildingsLimits: [
                {value:0, text:'Любое'},
                {value:3, text:'3'},
                {value:5, text:'5'},
                {value:10, text:'10'},
            ],

            excludedAgencies: [],

        }
    

    },
    computed: {
        excludedAgenciesIdList: function () {
            return this.excludedAgencies.map(item => item.id);
        },
        searchParams: function () {
            return {
                date_from: this.dateFrom,
                date_to: this.dateTo,
                max_age: this.accountAge,
                max_buildings: this.buildingsLimit,
                user_id: this.userId,
                min_price: this.minPrice,
                excluded_agencies: this.excludedAgenciesIdList,
                }
        },

    },
    methods: {
        setToday: function () {
            this.dateTo = new Date().toISOString().substr(0, 10);
            this.dateFrom = new Date().toISOString().substr(0, 10);
        },
        setYesterday: function () {
            let d = new Date();
            d.setDate(d.getDate() - 1);
            this.dateFrom = this.dateTo = d.toISOString().substr(0, 10);
        },
        setWeek: function () {
            this.dateTo = new Date().toISOString().substr(0, 10);
            let d = new Date();
            d.setDate(d.getDate() - 6);
            this.dateFrom = d.toISOString().substr(0, 10);
            
        },
        loadData: function () {
            this.ready = false;
            this.initialState = false;
            this.$http({url: '/api/cian/new-offers/', method: 'POST', data: this.searchParams})
            .then(
                response => {
                    if(response.new_offers) {
                        this.offers = response.new_offers;
                    } else {
                        this.offers = [];
                    }
                    this.ready = true;
                },
                () => {
                    this.offers = [];
                    this.ready = true;
                })
        },
        excludeAgency: function (obj) {
            if (obj && obj.id) {
                if (!this.excludedAgenciesIdList.includes(obj.id)) {
                    this.excludedAgencies.push(obj);
                    this.loadData();
                }
            }
        },
        deleteAgencyFromExcluded: function (id) {
            let index = this.excludedAgencies.findIndex(item => item.id === id);
            if (index >= 0) {
                this.excludedAgencies.splice (index, 1);
                this.loadData();
            }
        }
    },
    created() {
        this.$eventBus.$on('hide-agency-from-cian-offers-list', this.excludeAgency);
    },

}
</script>