<template>
    <v-container>

        <v-row>
            <v-col>
                <span class="text-h5">Лог проверки размещений на Циан</span>
            </v-col>
        </v-row>

        <data-loader
        pagination
        url="/api/cian/ad-checker-log"
        :do-request="doRequest"
        @loading-complete="onLoadData">
            <template v-slot:default>
                <v-simple-table
                dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Всего объявлений</th>
                                <th>Ошибок</th>
                                <th>Удалено</th>
                                <th>Добавлено</th>
                                <th>Обновлено</th>
                                <th>Опубликовано</th>
                                <th>Неактивно</th>
                                <th>Отклонено</th>
                                <th>Дата</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="item in list">
                                <tr
                                :key="item.date"
                                :item="item">
                                    <td>
                                        {{item.offersCount}}
                                    </td>
                                    <td>
                                        {{item.offerErrors}}
                                    </td>
                                    <td>
                                        {{item.deletedOffers}}
                                    </td>
                                    <td>
                                        {{item.insertedOffers}}
                                    </td>
                                    <td>
                                        {{item.updatedOffers}}
                                    </td>
                                    <td>
                                        {{item.activeOffers}}
                                    </td>
                                    <td>
                                        {{item.inactiveOffers}}
                                    </td>
                                    <td>
                                        {{item.refusedOffers}}
                                    </td>
                                    <td>
                                        <date-sql-date-formatter :date="item.date" shortdate time/>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </data-loader>

    </v-container>
</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue'


export default {
    name: 'cian-ad-checker-log',

    components: { 
      dataLoader,
      dateSqlDateFormatter
    },

    data () {
        return {
            list: [],
            doRequest: false,
        }
    },
    methods: {
        onLoadData: function (data) {
            this.list = [];
            this.doRequest = false;
            if ( data ) {
                this.list = data.list;
            }
        },
    },
    created() {
        this.doRequest = true;
    }
}
</script>
