<template>
    <div>
        <v-overlay
            :value="loading"
            :absolute="true">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>

        <div v-if="ok">
            <slot></slot>
        </div>

        <div v-if="error">
            <strong>Error</strong>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'simple-data-loader',
        //components: {},
        props: {
            url: {
                type: String
            },
            formData: {
                type: Object,
            },
            checkResponse: {
                type: Function
            },
        },
        data () {
            return {
                loading: false,
                ok: false,
            }
        },
        methods: {
            loadData: function () {
                this.ok = false;
                this.error = false;
                this.loading = true;
                this.$http({url: this.url, method: 'POST', data: this.formData})
                .then(
                    response => {
                        this.loading = false;
                        if ( response && (!this.checkResponse || this.checkResponse(response)) ) {
                            this.ok = true;
                        }
                        this.$emit('loading-complete', response);
                    },
                    () => {
                        this.loading = false;
                        this.error = true;
                        this.$emit('loading-complete');
                    })
            }
        },
        created() {
            this.loadData();
        },
    }
</script>