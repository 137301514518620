<template>
    <tr
    v-bind:class="{ 'text--disabled': !squareObject.status }">
        <td>
            <a
            target="_blank"
            :href="'http://megapolis.mega-realty.ru/proxy.htm?type=square&id=' + squareObject.id"
            >
                {{ squareObject.id }}
            </a>
        </td>
        <td>{{ squareObject.squareString }}</td>
        <td>{{ squareObject.floorString }}</td>
        <td>{{ squareObject.contractString }}</td>
        <td>{{ squareObject.priceString }}</td>
        <td><square-status-chip :status="squareObject.slSquareState"></square-status-chip></td>
    </tr>
</template>


<script>
import Square from '@/components/js/square.js'
import squareStatusChip from '@/components/entities/square/square-status-chip'
export default {
    name: 'shortSquareTableRow',
    components: {
        squareStatusChip,
    },
    props: {
        square: {
            type: Object
        },
    },
    data () {
      return {
          squareObject: {}
      }
    },
    created() {
        this.squareObject = new Square(this.square);
    }
}
</script>