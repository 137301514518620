<template>
    <search-page
    :counter="counter"
    @clear-search-form="clearForm">

        <template v-slot:header>Заявки</template>

        <template v-slot:search-form>
           <request-search-form
            :params="params.searchParams"
            @do-search="formDoSearch"/>
        </template>

        <template v-slot:sorter>
            <search-sorter
            :options="fields.order.options"
            :order="params.searchParams.order"
            @change-order="changeOrder"
            />
        </template>

        <template v-slot:search-chips>
            <request-search-chips
            :params="params.searchParams"
            @setFields="setFields"/>
        </template>

        <template v-slot:data-loader>
            <data-loader
            pagination
            url="/api/request/search"
            :form-data="params.searchParams"
            :do-request="doRequest"
            @loading-complete="onLoadData">
                <template v-slot:default>
                    <v-container>
                        <v-expansion-panels accordion>
                            <request-search-item
                            v-for="item in searchResults"
                            :key="item.ID"
                            :request="item"
                            />
                        </v-expansion-panels>
                    </v-container>
                </template>
            </data-loader>
        </template>

    </search-page>
</template>

<script>
import searchParamsClass from '@/components/search/searchParams.js'
import requestSearchForm from './components/request-search-form.vue'
import {requestSearchOptions} from '@/components/search/searchOptions.js'
import searchPage from '@/components/search/search-page.vue'
import searchSorter from '@/components/search/search-sorter.vue'
import dataLoader from '@/components/data-loader.vue'
import requestSearchItem from './components/request-search-item.vue'
import requestSearchChips from "./components/request-search-chips.vue"
import searchPageMixin from '@/mixins/search-page-mixin.js'

export default {
    name: 'requestSearch',
    components: { 
        searchPage,
        requestSearchForm,
        dataLoader,
        requestSearchItem,
        requestSearchChips,
        searchSorter,
    },
    mixins: [
        searchPageMixin
    ],
    data () {
        return {
            fields: requestSearchOptions,
            params: new searchParamsClass(requestSearchOptions),
        }
    },


}
</script>