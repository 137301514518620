<template>
    <v-row>

        <v-chip
        v-if="+params.request_id"
        class="ma-2"
        close
        @click:close="setFields({'request_id': 0})"
        >
        Заявка № {{params.request_id}}
        </v-chip>

        <v-chip
        v-if="+params.square_id"
        class="ma-2"
        close
        @click:close="setFields({'square_id': 0})"
        >
        Помещение № {{params.square_id}}
        </v-chip>

        <v-chip
        v-if="dateRange(params.date_from, params.date_to)"
        class="ma-2"
        close
        @click:close="setFields({'date_from': 0, 'date_to': 0})"
        >
        Период: {{dateRange(params.date_from, params.date_to)}}
        </v-chip>

        <search-chips-numbers-list
        name="manager_id"
        :value="params.manager_id"
        :options="this.$store.getters.staffManagerShortList"
        @setFields="setFields"
        />


        <search-chips-numbers-list
        name="user_id"
        :value="params.user_id"
        :options="this.$store.getters.staffSortedShortList"
        @setFields="setFields"
        />

        <search-chips-numbers-list
        name="offer_state"
        :value="params.offer_state"
        :options="searchOptions.offer_state.options"
        @setFields="setFields"
        />

    </v-row>
</template>

<script>
import searchChipsMixin from '@/mixins/search-chips-mixin.js'
import searchChipsNumbersList from '@/components/search/search-chips-numbers-list.vue'
import {offerSearchOptions} from '@/components/search/searchOptions.js'

export default {
    name: 'offer-search-chips',
    components: {
        searchChipsNumbersList,
    },
    mixins: [
        searchChipsMixin,
    ],
    data () {
        return {
            searchOptions: offerSearchOptions,
        }
    },

}
</script>
