<template>
    <v-container>
        <v-row>
            <v-col>
                <span class="text-h5"><slot name="header"></slot></span>
            </v-col>
        </v-row>

        <v-row>
            <v-col>

                <slot name="search-form"></slot>

                <v-btn
                small
                class="ml-2"
                color="default"
                @click="clearForm">
                    Сбросить
                </v-btn>

                <span
                v-if="counter"
                class="ml-2 text-caption"
                >Найдено: {{counter}}</span>
                
            </v-col>
            <v-col>
                <slot name="sorter"></slot>
            </v-col>
        </v-row>

        

        <slot name="search-chips"></slot>

        <v-row>
            <slot name="data-loader"></slot>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'search-page',
    props: {
        counter: {
            type: String
        }
    },
    methods: {
        clearForm: function(){
            this.$emit( 'clear-search-form' )
        }
    }
}
</script>