<template>
    <v-container>

        <search-by-id
        :form-data="searchFormData"
        :do-request="doRequestSearch"
        @loading-complete="doRequestSearch=false"
        @refresh-data="doRequestSearch=true"
        />

        <v-tabs
        v-if="currentId"
        class="mt-4">

<!--
            <v-tab>Действия 1</v-tab>

            <v-tab-item>
                <offers-list
                :do-request="true"
                :search-params="offerSearchParams"
                />
            </v-tab-item>
-->
            <v-tab>Действия</v-tab>

            <v-tab-item>
                <request-actions
                :requestId="currentId"/>
            </v-tab-item>




        </v-tabs>

    </v-container>
</template>

<script>
import searchById from '@/components/search-by-id.vue'
import requestActions from '@/components/entities/request/request-actions.vue'

export default {
    name: 'idSearch',
    components: { 
      searchById,
      requestActions
    },
    data () {
      return {
        doRequestSearch: true,
      }
    },
    computed: {
        currentId: function() {
            return (+this.$route.params.id || undefined)
        },
        searchFormData: function() {
            return {
                id: this.currentId,
                type: 'request'
            }
        },
        offerSearchParams: function() {
            return {
                request_id: this.currentId,
            }
        },
    },
}
</script>