import Vue from 'vue'
import VueRouter from 'vue-router'



// Pages
import accountInfo from '@/modules/app/account-info'
import testPage from '@/modules/app/test-page'
import accessKeysList from '@/modules/access-keys/access-keys-list';
import idSearch from '@/modules/id-search/id-search';
import requestSearch from '@/modules/request/request-search';
import requestById from '@/modules/request/request-by-id';
import offerSearch from '@/modules/offer/offer-search';
import offerById from '@/modules/offer/offer-by-id';
import buildingById from '@/modules/building/building-by-id';
import objectById from '@/modules/object/object-by-id';
import authLog from '@/modules/auth-log/auth-log';


import favorites from '@/modules/favorites/favorites-list';

import mediaUploadRoutes from '@/modules/media-upload/routes.js';
import youtubeLinksRoutes from '@/modules/youtube-links/routes.js';
import cianRoutes from '@/modules/cian/routes.js';
import addressRoutes from '@/modules/address/routes.js';

Vue.use(VueRouter)

  const baseRoutes = [
  {
    path: '/',
    name: 'account-info',
    component: accountInfo
  },
  {
    path: '/test-page/:type?/:id?',
    name: 'test-page',
    component: testPage
  },
  {
    path: '/access-keys/:type?/:id?',
    name: 'access-keys-list',
    component: accessKeysList,
  },
  {
    path: '/id-search/:type?/:id?',
    name: 'id-search',
    component: idSearch,
  },
  {
    path: '/building/:id/',
    name: 'building-by-id',
    component: buildingById
  },
  {
    path: '/object/:id/',
    name: 'object-by-id',
    component: objectById
  },
  {
    path: '/request/:id/',
    name: 'request-by-id',
    component: requestById
  },
  {
    path: '/offer/:id/',
    name: 'offer-by-id',
    component: offerById
  },
  {
    path: '/request-search',
    name: 'request-search',
    component: requestSearch,
  },
  {
    path: '/offer-search',
    name: 'offer-search',
    component: offerSearch,
  },
  {
    path: '/favorites',
    name: 'rfavorites',
    component: favorites,
  },
  {
    path: '/auth-log',
    name: 'auth-log',
    component: authLog,
  },
]


const routes = baseRoutes.concat(
  mediaUploadRoutes,
  youtubeLinksRoutes,
  addressRoutes,
  cianRoutes,
);


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
