<template>
    <v-list-item
    link
    @click="ping"
    >
    <v-list-item-action>
        <v-icon
        v-if="this.$store.state.apiStatus === 'ok'"
        color="teal"
        >
        mdi-lan-connect
        </v-icon>
        <v-icon
        v-else-if="this.$store.state.apiStatus === 'error'"
        color="red darken-4"
        >
        mdi-lan-disconnect
        </v-icon>
        <v-icon
        v-else-if="this.$store.state.apiStatus === 'pending'"
        class="blink"
        >
        mdi-lan-pending
        </v-icon>
    </v-list-item-action>
    <v-list-item-content>
        <v-list-item-title>
            API {{this.$store.state.apiStatus}}
        </v-list-item-title>
    </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'api-indicator',
    data () {
      return {}
    },
    methods: {
      ping: function () {
        this.$http({url: '/api/ping/', method: 'GET'})
        .catch(() => {
            return
        });
      }
    },
}
</script>

<style scoped>
    .blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 0.75s steps(5, start) infinite;
    }
    @keyframes blink-animation {
    to {
        visibility: hidden;
    }
    }
    @-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
    }
</style>