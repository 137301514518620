<template>
    <v-container>

        <search-by-id
        :form-data="searchFormData"
        :do-request="doBuildingSearch"
        @loading-complete="doBuildingSearch=false"
        @refresh-data="doBuildingSearch=true"
        />

        <v-tabs
        v-if="currentId"
        class="mt-4">

            <v-tab>Помещения</v-tab>
            <v-tab disabled>Фирмы</v-tab>

            <v-tab-item>
                <squares-by-building
                :buildingId="currentId"/>
            </v-tab-item>

            <v-tab-item>
                Фирмы в здании
            </v-tab-item>

        </v-tabs>

    </v-container>
</template>

<script>
import searchById from '@/components/search-by-id.vue'
import squaresByBuilding from './components/squares-by-building.vue'

export default {
    name: 'idSearch',
    components: { 
      searchById,
      squaresByBuilding
    },
    data () {
      return {
        doBuildingSearch: true,
      }
    },
    computed: {
        currentId: function() {
            return (+this.$route.params.id || undefined)
        },
        searchFormData: function() {
            return {
                id: this.currentId,
                type: 'building'
            }
        },
    },

}
</script>