module.exports = class SearchParams {


    constructor(f) {
        this.fields = {};
        this.searchParams = {};

        if (f){
            this.fields = {...f};
        }

        this.setDefaults();
    }


    selectedParams(){
        let result = {};
        for (var key in this.fields) {
            if(this.searchParams[key]){
                if(this.searchParams[key] !== this.fields[key].default){
                    if ((this.fields[key].type === 'number' ||
                         this.fields[key].type === 'bool' ||
                         this.fields[key].type === 'mask') &&
                         this.searchParams[key] !== 0){
                        result[key] = this.searchParams[key];
                    } else if (this.fields[key].type === 'string' && this.searchParams[key] !== '') {
                        result[key] = this.searchParams[key];
                    } else if (this.fields[key].type === 'numbersList' && Array.isArray(this.searchParams[key])) {
                        result[key] = this.searchParams[key];
                    }
                }
            }
        } 
        return result;
    }



    setDefaultValue(key){
        if(this.fields[key].default){
            this.searchParams[key] = this.fields[key].default;
        } else if (this.fields[key].type === 'bool') {
            this.searchParams[key] = 0;
        } else if (this.fields[key].type === 'number') {
            this.searchParams[key] = 0;
        } else if (this.fields[key].type === 'mask') {
            this.searchParams[key] = 0;
        } else if (this.fields[key].type === 'string') {
            this.searchParams[key] = '';
        } else if (this.fields[key].type === 'numbersList') {
            this.searchParams[key] = [];
        }
    }


    setParamValue(key,val){

        this.setDefaultValue(key);

        if(val){
            if(this.fields[key].check && typeof this.fields[key].check === 'function'){
                if(this.fields[key].check(val)){
                    this.searchParams[key] = val;
                }
            } else if (this.fields[key].type === 'bool' && +val === 1){
                this.searchParams[key] = 1;
            } else if (this.fields[key].type === 'number' && +val) {
                this.searchParams[key] = +val;
            } else if (this.fields[key].type === 'mask' && +val) {
                this.searchParams[key] = +val;
            } else if (this.fields[key].type === 'string' && val.trim()) {
                this.searchParams[key] = val.trim();
            } else if (this.fields[key].type === 'numbersList') {
                if(Array.isArray(val)){
                    this.searchParams[key] = val.map( item => +item ).filter( item => item );
                } else if (+val) {
                    this.searchParams[key] = [];
                    this.searchParams[key].push(+val);
                }
            }
        }
    }


    setDefaults(){
        for (var key in this.fields) {
            this.setDefaultValue( key );
        }
    }


    setParams(data){
        for (var key in this.fields) {
            this.setParamValue( key, data[key] );
        }
    }


    setParamsSet(data){
        if (data) {
            for (var key in data) {
                this.setParamValue( key, data[key] );
            }
        }
    }


}