<template>
    <v-container>

        <v-row>
            <v-col>
                <span class="text-h5">Наши объявления на Циан</span>
            </v-col>
        </v-row>

        <data-loader
        pagination
        url="/api/cian/ad-archive"
        :do-request="doRequest"
        @loading-complete="onLoadData">
            <template v-slot:default>
                <v-simple-table
                dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>Адрес</th>
                                <th>Площадь</th>
                                <th>cian_id</th>
                                <th>Создание</th>
                                <th>Начало</th>
                                <th>Окончание</th>
                                <th>Статистика</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="item in list">
                                <tr :class="{ 'grey--text': !item.active, 'grey lighten-4': !item.active }"
                                :key="item.cian_id"
                                :item="item">
                                    <td>
                                        {{item.id}}
                                    </td>
                                    <td>
                                        {{item.address}}
                                    </td>
                                    <td>
                                        {{item.square}} м²
                                    </td>
                                    <td :title="item.category">
                                        {{item.cian_id}}
                                    </td>
                                    <td class="text-right grey--text">
                                        <date-sql-date-formatter shortdate :date="item.creationDate"/>
                                    </td>
                                    <td class="text-right grey--text">
                                        <date-sql-date-formatter shortdate :date="item.startDate"/>
                                    </td>
                                    <td class="text-right grey--text">
                                        <date-sql-date-formatter shortdate :date="item.paidTill"/>
                                    </td>
                                    <td>
                                        <div class="text-no-wrap">
                                            <v-chip
                                            small
                                            title="Просмотров объявления">
                                                {{item.stat_totalViews || 0}}
                                            </v-chip>
                                            <v-chip
                                            small
                                            title="Показов телефона">
                                                {{item.stat_phoneShows || 0}}
                                            </v-chip>
                                            <v-chip
                                            small
                                            title="Добавлений в избранное">
                                                {{item.stat_addToFavorites || 0}}
                                            </v-chip>
                                            <v-chip
                                            small
                                            title="Покрытие">
                                                {{item.stat_coverage || 0}} %
                                            </v-chip>
                                            <v-chip
                                            small
                                            title="Подходящих поисков">
                                                {{item.stat_searchesCount || 0}}
                                            </v-chip>
                                            <v-chip
                                            small
                                            title="Показов на странице">
                                                {{item.stat_showsCount || 0}}
                                            </v-chip>

                                        </div>
                                    </td>

                                </tr>
                            </template>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </data-loader>
    </v-container>
</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue'


export default {
    name: 'cian-ad-archive',

    components: { 
      dataLoader,
      dateSqlDateFormatter
    },

    data () {
        return {
            list: [],
            doRequest: false,
        }
    },
    methods: {
        onLoadData: function (data) {
            this.list = [];
            this.doRequest = false;
            if ( data ) {
                this.list = data.list;
            }
        },
    },
    created() {
        this.doRequest = true;
    }
}
</script>