<template>
    <v-select
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :items="[...this.extension, ...this.$store.getters.staffManagerList]"
    item-text="name"
    item-value="id"
    outlined
    hide-details
    >
        <template v-slot:selection="data">
            {{ data.item.name }}
        </template>
        <template v-slot:item="data">
            <span v-bind:class="{ 'text--disabled': data.item.del }">{{ data.item.name }}</span>
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'select-manager',
    props: {
        value: {
            type: [Number, Array]
        },
        extension: {
            type: Array,
            default: function (){ return [] }
        },
    },
    data () {
      return {}
    },

}
</script>