<template>
    <v-row
    v-if="squareObject.ID">
        <v-col
        cols="12"
        sm="4">

            <square-main-menu
            :square="squareObject"
            @open-supplementary-editor="showSupplementaryEditor = true"/>

            <v-chip
            :color="color"
            small
            class="mr-2"> 
                {{status}}
            </v-chip>

            <span class="text-no-wrap">
                {{squareObject.shortTitle}}
            </span>
        </v-col>

        <v-col
        cols="12"
        sm="5"
        >
            <span
            class="subtitle-1 font-weight-bold">
            {{squareObject.squareString}}
            </span>

            <span
            v-if="squareObject.floorString"
            class="subtitle-1">
            , {{squareObject.floorString}}
            </span>

            <span
            v-if="(square.isSeparate && square.slPlanType != 32)"
            class="subtitle-1">
            , отдельный вход
            </span>


        </v-col>


        <v-col
        cols="12"
        sm="3"
        >
            <div
            class="subtitle-1">
            {{squareObject.priceString}}
            </div>

            <div
            class="text-caption text--secondary">
            {{squareObject.meterPriceString}}
            </div>

        </v-col>

        <square-supplementary-editor
        :square="squareObject"
        :show="showSupplementaryEditor"
        @on-change-data="refreshData"
        @close-dialog="showSupplementaryEditor = false">
        </square-supplementary-editor>

    </v-row>
</template>


<script>
import Square from '@/components/js/square.js'
import squareMainMenu from '@/components/entities/square/square-main-menu.vue'
import squareSupplementaryEditor from '@/components/entities/square/square-supplementary-editor.vue'

export default {
    name: 'square-heading',
    components: {
        squareMainMenu,
        squareSupplementaryEditor,
    },
    props: {
        square: {
            type: Object,
        },
    },
    data () {
        return {
            squareObject: new Square(this.square),
            showSupplementaryEditor: false
        }
    },
    computed: {
        color: function () {
            let result = 'default';
            if ( this.square.slSquareState ) {
                if ( this.square.slSquareState === 110 ) {
                    result = 'teal accent-3';
                } else if ( this.square.slSquareState === 111 ) {
                    result = 'red accent-1';
                } else if ( this.square.slSquareState === 113 ) {
                    result = 'orange accent-1';
                }
            }
            return result;
        },

        status: function () {
            let result = 'нет статуса';
            if ( this.square.slSquareState ) {
                if ( this.square.slSquareState === 110 ) {
                    result = 'в работе';
                } else if ( this.square.slSquareState === 111 ) {
                    result = 'не предлагать';
                } else if ( this.square.slSquareState === 113 ) {
                    if (this.square.slContractType == 8){
                        result = 'продано';
                    } else {
                        result = 'сдано';
                    }
                }
            }
            return result;
        },
    },

    methods: {
        refreshData: function () {
            this.$emit('refresh-data');
        },
    },

}
</script>
