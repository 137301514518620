<template>
    <div>
        <v-select
            v-bind="$attrs"
            v-model="selected"
            item-text="name"
            item-value="val"
            outlined
            hide-details
        ></v-select>
    </div>
</template>

<script>
import bitmaskArrayConverter from '@/mixins/bitmask-array-converter.js'

export default {
    name: 'bitmask-select',
    mixins: [
        bitmaskArrayConverter,
    ],
    props: {
        value: {
            type: Number
        },
    },
    computed: {
        selected: {
            get: function () {
                return this.bitmaskToArray(this.value);
            },
            set: function (newValue) {
                this.$emit('change', this.arrayToBitmask(newValue));
            },
        }
    },

}
</script>