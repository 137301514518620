<template>
    <v-list-item>
        <v-list-item-icon>
            <v-icon
            :color="color">{{icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>
                  {{name}}
                  <span
                  :class="color + '--text font-weight-medium'"
                  >{{message}}</span>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'media-file-processing-item',
    props: {
        name: {
            type: String
        },
        error: {
            type: Boolean
        },
        complete: {
            type: Boolean
        },
        isImage: {
            type: Boolean
        },
        isVideo: {
            type: Boolean
        },
    },
    data () {
      return {}
    },
    computed: {
        message: function() {
            if (this.error) {
                return 'ERROR';
            } else if (this.complete) {
                return 'COMPLETE';
            } else {
                return '';
            }
        },
        icon: function() {
            if (this.error) {
                return 'mdi-alert';
            } else if (this.complete) {
                return 'mdi-check-bold';
            } else {
                return 'mdi-spin mdi-loading';
            }
        },
        color: function() {
            if (this.error) {
                return 'error';
            } else if (this.complete) {
                return 'success';
            } else {
                return '';
            }
        },        
    },
}
</script>