<template>
    <v-row>
        <v-col>

            <v-select
                v-bind="$attrs"
                v-model="selected"
                :items="items"
                item-text="label"
                item-value="value"
                outlined
                hide-details
            ></v-select>

        </v-col>
        <v-col>

            <v-menu
            v-model="pickerFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-chip
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    >
                    <span
                    v-if="selected">
                    {{dateFrom}}
                    </span>
                    <span
                    v-if="!selected">
                    не задано
                    </span>
                    </v-chip>
                </template>
                <v-date-picker
                    v-model="dateFrom"
                    @input="pickerFrom = false"
                    :first-day-of-week="1"
                    locale="ru-ru"
                ></v-date-picker>
            </v-menu>


            <v-menu
            v-model="pickerTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-chip
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on">
                    {{dateTo}}
                    </v-chip>
                </template>
                <v-date-picker
                    v-model="dateTo"
                    @input="pickerTo = false"
                    :first-day-of-week="1"
                    locale="ru-ru"
                ></v-date-picker>
            </v-menu>


        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'select-date-range',
    props: {
        minValue: {
            type: Number,
            default: 0
        },
        maxValue: {
            type: Number,
            default: 0
        },
    },
    data(){
        return {
            pickerFrom: false,
            pickerTo: false,
            items: [
                { label:'Указанный период', value: -1 },
                { label:'Любой срок', value: 0 },
                { label:'Сегодня', value: 1 },
                { label:'7 дней', value: 7 },
                { label:'30 дней', value: 30 },
                { label:'90 дней', value: 90 },
                { label:'180 дней', value: 180 },
                { label:'365 дней', value: 365 },
            ],
        }
    },
    computed: {
        dateFrom: {
            get: function () {
                let n = +this.minValue;
                if ( n > 0) {
                    n--;
                }
                return new Date(Date.now()-86400000*(n)).toISOString().slice(0, 10);
            },
            set: function (newValue) {
                this.$emit( 'setFields', { min: this.dayDiff(newValue)+1, max: this.maxValue } );
            }
        },
        dateTo: {
            get: function () {
                return new Date(Date.now()-86400000*(+this.maxValue || 0)).toISOString().slice(0, 10);
            },
            set: function (newValue) {
                this.$emit( 'setFields', { min: this.minValue, max: this.dayDiff(newValue) } );
            }
        },
        selected: {
            get: function () {
                if ( +this.maxValue === 0 ){
                    if ( +this.minValue === 0 ){
                        return 0;
                    } else if ( +this.minValue === 1 ){
                        return 1;
                    } else if ( +this.minValue === 7 ){
                        return 7;
                    } else if ( +this.minValue === 30 ){
                        return 30;
                    } else if ( +this.minValue === 90 ){
                        return 90;
                    } else if ( +this.minValue === 180 ){
                        return 180;
                    } else if ( +this.minValue === 365 ){
                        return 365;
                    } else {
                        return -1;
                    }
                }
                return -1;
            },
            set: function (newValue) {
                if ( newValue !== -1) {
                    this.$emit( 'setFields', { min: newValue, max: 0 } );
                }

            },
        },
    },
    methods: {
        dayDiff: function (date) {
            let t = new Date();
            t.setHours(0, 0, 0, 0);

            let d = new Date(date);
            d.setHours(0, 0, 0, 0);

            if (t > d) {
                return (t - d)/86400000; 
            } else {
                return 0;
            }

        }
    }

}
</script>