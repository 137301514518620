// This class is designed to use fields from the [MEGAPOLIS].[dbo].[mega2_Squares] table

import lib from './Lib.js';

const { declineNouns } = lib;


export default class Square {
    constructor(o) {
        this.ID = o.ID;
        this.FirmBuilding_id = o.FirmBuilding_id;
        this.Square = o.Square;
        this.Price = o.Price;
        this.MeterPrice = o.MeterPrice;
        this.RubPrice = o.RubPrice;
        this.slCurrency = o.slCurrency;
        this.ExchangeRate = o.ExchangeRate;
        this.PriceInclusion = o.PriceInclusion;
        this.slContractType = o.slContractType;
        this.Floor = o.Floor;
        this.slDestination = o.slDestination;
        this.slPlanType = o.slPlanType;
        this.isWC = o.isWC;
        this.isSeparate = o.isSeparate;
        this.RoomCount = o.RoomCount;
        this.RoomWindowCount = o.RoomWindowCount;
        this.CarCount = o.CarCount;
        this.MGTSCount = o.MGTSCount;
        this.isINet = o.isINet;
        this.slGuardType = o.slGuardType;
        this.slDecorType = o.slDecorType;
        this.isVentilation = o.isVentilation;
        this.slConditionType = o.slConditionType;
        this.isFree = o.isFree;
        this.ReadyDate = o.ReadyDate;
        this.slSquareState = o.slSquareState;
        this.About = o.About;
        this.User_Ins = o.User_Ins;
        this.DateIns = o.DateIns;
        this.User_Upd = o.User_Upd;
        this.DateUpd = o.DateUpd;
        this.User_Del = o.User_Del;
        this.DateDel = o.DateDel;
        this.InfoDel = o.InfoDel;
        this.ElectricPower = o.ElectricPower;
        this.isFurniture = o.isFurniture;
        this.RateManager = o.RateManager;
        this.isHeat = o.isHeat;
        this.ceilingHeight = o.ceilingHeight;
        this.supplementary = o.supplementary;

        // From firm_building table
        this.Firm_ID = o.Firm_ID;
        this.Building_ID = o.Building_ID;
        this.User_ID = o.User_ID;
        this.CallDate = o.CallDate;
        this.ComissionValue = o.ComissionValue;
        this.isNoCian = o.isNoCian;
        this.isRemoteShow = o.isRemoteShow;


    }

    get id() {
        if (this.ID) {
            return this.ID;
        } else {
            return '';
        }
    }

    get status() {
        return this.slSquareState == 110;
    }

    get squareString() {
        if (this.Square) {
            return this.Square + ' м²';
        } else {
            return 'НЕТ ПЛОЩАДИ';
        }
    }

    get priceString() {
        if (this.RubPrice) {
            return new Intl.NumberFormat("ru-RU").format(this.RubPrice) + ' р.' + ((this.slContractType != 8) ? ' в месяц' : '');
        } else {
            return 'НЕТ ЦЕНЫ';
        }
    }

    get meterPriceString() {
        if (this.MeterPrice) {
            return new Intl.NumberFormat("ru-RU").format(this.MeterPrice) + ' р./м²' + ((this.slContractType != 8) ? ' в год' : '');
        } else {
            return 'НЕТ ЦЕНЫ';
        }
    }

    get floorString() {
        if ( !this.Floor || this.Floor == 0 || this.slPlanType == 32 ) {
            return '';
        } else if (this.Floor == -2) {
            return 'подвал';
        } else if (this.Floor == -1) {
            return 'цоколь';
        } else {
            return this.Floor + '-й этаж';
        }
    }


    get contractString() {
        if (!this.slContractType) {
            return 'НЕТ ДОГОВОРА';
        } else  if (this.slContractType == 8) {
            return 'продажа';
        } else {
            return 'аренда';
        }
    }

    get contractNameString() {
        if (this.slContractType == 1) {
            return 'прямая аренда';
        } else if (this.slContractType == 2) {
            return 'субаренда';
        } else if (this.slContractType == 4) {
            return 'ДСД';
        } else if (this.slContractType == 8) {
            return 'продажа';
        } else {
            return 'неизвестный договор';
        }
    }

    get destinationString() {
        let arr = [];

        if (this.slDestination & 1){
            arr.push('офис');
        }

        if (this.slDestination & 32){
            arr.push('банк');
        }

        if (this.slDestination & 66){
            if((this.slDestination & 66) == 64){
                arr.push('магазин (кроме продуктового)');
            } else if((this.slDestination & 66) == 2){
                arr.push('продуктовый магазин');
            } else if((this.slDestination & 66) == 66){
                arr.push('магазин');
            }
        }

        if (this.slDestination & 128){
            arr.push('салон');
        }

        if (this.slDestination & 256){
            arr.push('медцентр');
        }

        if (this.slDestination & 16){
            arr.push('кафе, ресторан');
        }

        if (this.slDestination & 8){
            arr.push('производство');
        }

        if (this.slDestination & 4){
            if( this.isHeat == 1 ){
                arr.push('тёплый склад');
            } else if ( this.isHeat == 0 ){
                arr.push('неотапливаемый склад');
            } else {
                arr.push('склад');
            }
        }

        if (this.slDestination & 512){
            arr.push('гостиницу');
        }

        let result = '';
        result = arr.join(', ');

        if(result){
            return 'под ' + result;
        } else {
            return 'НЕТ НАЗНАЧЕНИЯ';
        }

    }


    get planningString() {

        let planningOptions = {
            1: 'блок',
            2: 'комната',
            4: 'помещение коридорной планировки',
            8: 'помещение свободной планировки',
            16: 'этаж целиком',
            32: 'здание целиком'
        }

        let result = ( planningOptions[this.slPlanType] || 'помещение' );

        // Если это не комната и не свободная планировка, и если комнат больше одной
        if ( (this.slPlanType & 10) == 0 && this.RoomCount > 1){
            result += (', ' + this.RoomCount + ' ');

            if( this.slDestination == 1 ){
                result += declineNouns(this.RoomCount, 'кабинет', 'кабинета', 'кабинетов');
            } else {
                result += declineNouns(this.RoomCount, 'комната', 'комнаты', 'комнат');
            }

            if( this.RoomWindowCount > 0 ){

                if( this.RoomWindowCount == this.RoomCount){
                    result += ' (все с окнами)';
                } else {
                    result += (' (из них ' + this.RoomWindowCount + ' с окнами)');
                }
                
            }
        }

        if( this.RoomWindowCount == 0 ){
            result += ' без окон';
        }

        if ( this.slPlanType != 32 ){
            result += ', есть санузел';
        }

        return result;
    }


    get optionsString() {
        let arr = [];


        if (this.slDecorType == 36){
            arr.push('высококачественная отделка');
        } else if (this.slDecorType == 37){
            arr.push('стандартная отделка');
        } else if (this.slDecorType == 38){
            arr.push('требуется косметический ремонт');
        } else if (this.slDecorType == 39){
            arr.push('требуется ремонт');
        } else if (this.slDecorType == 40){
            arr.push('новое помещение под отделку');
        } else if (this.slDecorType == 143){
            arr.push('ремонт делает собственник за свой счёт');
        } else if (this.slDecorType == 158){
            arr.push('отделка в стиле лофт');
        } 

        if (this.ceilingHeight){
            arr.push('высота потолков ' + (this.ceilingHeight/100) + ' м');
        }

        if (this.isHeat === false ){
            arr.push('не отапливается');
        }

        if (this.isHeat === true ){
            arr.push('есть отопление');
        }

        if (this.isVentilation){
            arr.push('вентиляция');
        }

        if (this.slConditionType == 51){
            arr.push('кондиционер');
        } else if (this.slConditionType == 72){
            arr.push('центральное кондиционирование');
        }

        if (this.isFurniture){
            arr.push('есть мебель');
        }

        if (this.ElectricPower){
            arr.push('электрическая мощность ' + this.ElectricPower + ' кВт');
        }


        if (this.slGuardType == 59){
            arr.push('пропускной режим');
        } else if (this.slGuardType == 61){
            arr.push('есть охрана');
        } else if (this.slGuardType == 71){
            arr.push('свободный доступ');
        }

        if (this.CarCount){
            arr.push('парковка на ' + this.CarCount + declineNouns(this.CarCount, ' машиноместо', ' машиноместа', ' машиномест'));
        }

        let readyDate = new Date(this.ReadyDate);
        let now = new Date();

        if (this.ReadyDate && readyDate > now){
            arr.push('помещение освобождается ' + new Intl.DateTimeFormat("ru",{year: "numeric", month: "long", day: "numeric"}).format(readyDate));
        } else {
            arr.push('помещение свободно');
        }

        return arr.join(', ');

    }

    get shortTitle() {
        let result = this.contractString;

        if(this.slPlanType == 32) {
            result += (' здания');
        } else if (this.slDestination == 1 || this.slDestination == 33) {
            result += (' офиса');
        } else if (this.slDestination == 2 || this.slDestination == 64 || this.slDestination == 66) {
            result += (' магазина');
        } else if (this.slDestination == 4) {
            result += (' склада');
        } else {
            result += (' помещения');
        }

        result = result && result[0].toUpperCase() + result.slice(1);

        return result;
    }



}
