<template>
    <v-container>

        <v-row>
            <v-col>
                <span class="text-h5">XML-фид для Циан</span>
            </v-col>
        </v-row>


        <v-row>
            <v-col
            cols="12">
                <data-loader
                pagination
                url="/api/cian/ad-feed"
                :do-request="doRequest"
                @loading-complete="onLoadData">
                    <template v-slot:default>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th width="5em" class="text-right">id</th>
                                        <th class="text-right">Здание</th>
                                        <th class="text-left">Адрес</th>
                                        <th class="text-right">Этаж</th>
                                        <th class="text-right">Площадь</th>
                                        <th class="text-right">Назначение</th>
                                        <th class="text-right">Добавлено</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <cian-ad-feed-item
                                    v-for="item in feed"
                                    :key="item.id"
                                    :item="item"
                                    @refresh-list="doRequest=true"></cian-ad-feed-item>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                </data-loader>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import cianAdFeedItem from './components/cian-ad-feed-item.vue';
import dataLoader from '@/components/data-loader.vue'

export default {
    name: 'cian-ad-feed',

    components: { 
      cianAdFeedItem,
      dataLoader
    },

    data () {
        return {
            feed: [],
            doRequest: false,
        }
    },
    methods: {
        onLoadData: function (data) {
            this.feed = [];
            this.doRequest = false;
            if (data) {
                this.feed = data.list;
            }
        },
    },
    created() {
        this.doRequest = true;
    },

}
</script>