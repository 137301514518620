import streetNames from './street-names'
import cityNames from './city-names'
import brokenAddress from './broken-address'

export default [

    {
        path: '/street-names/:city?',
        name: 'street-names',
        component: streetNames,
        title: 'Названия улиц',
    },
    {
        path: '/city-names',
        name: 'city-names',
        component: cityNames,
        title: 'Населённые пункты',
    },
    {
        path: '/broken-address',
        name: 'broken-address',
        component: brokenAddress,
        title: 'Ошибки в адресах',
    },

];