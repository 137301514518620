<template>
    <v-container>
        <component-loader
            :inProcess="loading"
            :errorMessage="errorMessage"
            :reloadFunction="init"
        >
        </component-loader>
        <v-card
        v-if="this.$store.state.user.id"
        max-width="344"
        outlined
        >
            <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                    {{ this.$store.state.user.name }} {{ this.$store.state.user.surname }}
                </v-list-item-title>
                <v-list-item-subtitle>Аккаунт {{ this.$store.state.user.account }}</v-list-item-subtitle>
                <p class="mt-3">
                    ID {{ this.$store.state.user.id }}
                </p>
            </v-list-item-content>
            </v-list-item>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'accountInfo',
    data () {
      return {
          loading: false,
          errorMessage: '' 
      }
    },
    methods: {
        init: function () {

            this.loading = true;
            this.$store.dispatch("load_user_data").then(
                () => {
                    this.loading = false
                    this.errorMessage = ''
                },
                () => {
                    this.loading = false
                    this.errorMessage = 'Ошибка загрузки данных'
                }
            )

        }
    },
    created() {
        this.init()
    },
    components: {
        componentLoader: () => import('@/components/component-loader')
    }
}
</script>