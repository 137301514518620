<template>
    <tr
    @click.stop="selectThisItem">
        <td>
            {{item.Name}}
        </td>
        <td>
            <a :href="'/street-names/' + item.ID">{{item.streets}}</a>
        </td>
        <td>
            {{item.addresses}}
        </td>
        <td>
            <date-ins-change-del-tooltip
            :item="item"
            class="grey--text"/>
        </td>
    </tr>
</template>

<script>
    import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'

    export default {
        name: 'city-names-item',
        components: {
            dateInsChangeDelTooltip,
        },
        props: {
            item: {
                type: Object
            },
        },
        data () {
            return {}
        },
        //computed: {},
        methods: {
            selectThisItem: function () {
                this.$emit('item-selected', this.item);
            }
        },
    }
</script>

<style>
</style>