export default {

    methods: {
        declineNouns: function (num, nom, genS, genP){
            var number = Number(num);
            if (number)
            {
                if ((number > 10) && ((number % 100) < 20) && ((number % 100) >= 10))
                    return genP;
                else
                {
                    var rem = (number % 10)
                    if (rem == 1)
                        return nom;
                    else if ((rem == 2) || (rem == 3) || (rem == 4))
                        return genS;
                    else
                        return genP;
                }
            }
            else if (number == 0)
                return genP;
            else
                return '';
        },
    }

}