<template>
    <v-container>
        <v-row>
            <v-col
            cols="12"
            sm="9">

                <v-row
                v-if="request.ID">
                    <v-col
                    cols="12"
                    sm="5">

                        <request-main-menu :request="request"/>

                        {{heading}}

                        <div
                        v-if="request.taxInspectorate"
                        class="subtitle-1 text--secondary">
                            ИФНС № {{request.taxInspectorate}}
                        </div>

                    </v-col>

                    <v-col
                    cols="12"
                    sm="7">
                        <span
                        v-if="square"
                        class="subtitle-1 font-weight-bold">
                        {{square}}
                        </span>

                        <span
                        v-if="price"
                        class="subtitle-1 text--secondary text-no-wrap">
                            {{price}}
                        </span>

                        <div
                        v-if="request.MetroBox_IDs">
                            <metro-stations-list
                            :mask="request.MetroBox_IDs"/>
                        </div>
                    </v-col>
                </v-row>

                <v-row
                v-if="request.About"
                class="mt-n3">
                    <v-col>
                        <div
                        class="text--secondary word-break-normal">
                            {{request.About}}
                        </div>
                    </v-col>
                </v-row>



            </v-col>
            <v-col
            cols="12"
            sm="3">

                <staff-name-chip
                :id="request.User_ID"/>
                
                <div class="text-caption">
                    <next-call-editor
                    :nextCall="nextCall"
                    @next-call-changed="nextCallChanged"
                    />

                    <date-user-tooltip
                    :date="request.NextCall_DateUpd"
                    :user="request.NextCall_User_Upd">
                        <date-sql-date-formatter
                        shortdate
                        time
                        v-if="nextCall.NextCallDate"
                        :date="nextCall.NextCallDate"/>
                    </date-user-tooltip>

                </div>
                <div v-if="nextCall.NextCallComment"
                class="text-caption grey--text text--darken-1 word-break-normal">
                    {{nextCall.NextCallComment}}
                </div>
            </v-col>
        </v-row>






        <v-row
        class="mt-n3">
            <v-col>
                <date-ins-change-del-tooltip time :item="request"/>
            </v-col>

        </v-row>
    </v-container>
</template>

<style scoped>
.text-no-wrap {
  word-break: normal !important;
}

.word-break-normal {
  word-break: normal !important;
}
</style>



<script>
import requestMainMenu from '@/components/entities/request/request-main-menu.vue'
import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'
import dateUserTooltip from '@/components/date-user-tooltip.vue'
import staffNameChip from '@/components/staff-name-chip.vue'
import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue'
import nextCallEditor from '@/components/entities/request/next-call-editor.vue'

import metroStationsList from '@/components/entities/request/metro-stations-list.vue'


export default {
    name: 'request-heading',
    components: {
        requestMainMenu,
        staffNameChip,
        dateInsChangeDelTooltip,
        dateUserTooltip,
        dateSqlDateFormatter,
        nextCallEditor,
        metroStationsList
    },
    props: {
        request: {
            type: Object,
        },
    },
    data () {
        return {
            nextCall: {},
            contacts: [],            
        }
    },
    computed: {
        showContacts: function () {
            return (this.$checkPermissions('view_Third-Party_Request') || this.request.User_ID == this.$store.state.user.id )
        },
        heading: function () {
            let result = '';

            result += this.contractType ? (this.contractType + ' ') : '';
            result += this.destination ? (this.destination + ' ') : '';
            result = result.trim();

            if(result){
                return result.charAt(0).toUpperCase() + result.slice(1);
            } else {
                return 'Заявка';
            }


        },
        square: function () {
            let result = '';
            if ( this.request.MinSquare ) {
                result += ' от ' + this.request.MinSquare;
            }
            if ( this.request.MaxSquare ) {
                result += ' до ' + this.request.MaxSquare;
            }
            if ( result ) {
                result = result + ' м²'
            }
            return result;
        },
        destination: function () {
            let result = '';

            let destinations = {
                1: 'офис',
                2: 'продуктовый магазин',
                4: 'склад',
                8: 'производство',
                16: 'кафе, ресторан',
                32: 'банк',
                64: 'магазин',
                128: 'салон',
                256: 'медцентр',
                512: 'гостиницу'
            }

            if ( this.request.slDestination && destinations[this.request.slDestination]) {
                result = 'под ' + destinations[this.request.slDestination];
            }
            return result;
        },

        contractType: function () {
            let result = '';

            if ( this.request.slNeedContractType && (this.request.slNeedContractType & 8 ) > 0) {
                result = 'покупка';
            } else if ( this.request.slNeedContractType && (this.request.slNeedContractType & 7 ) > 0) {
                result = 'аренда'
            }
            return result;
        },

        contract: function () {
            let result = '';

            let contracts = {
                1: 'прямая аренда',
                3: 'кроме ДСД',
                7: 'аренда',
                8: 'покупка',
            }

            if ( this.request.slNeedContractType && contracts[this.request.slNeedContractType]) {
                result = contracts[this.request.slNeedContractType];
            }
            return result;
        },

        price: function () {
            let result = '';

            let contracts = {
                100: ' USD',
                101: ' УЕ',
                102: ' EUR',
                103: ' руб.',
            }


            if (this.request.MonthPrice) {
                result += 'до ' + new Intl.NumberFormat("ru-RU").format(this.request.MonthPrice) + contracts[this.request.slCurrency] + ' в мес.';
            }

            if (this.request.MeterPrice) {
                if (result) {
                    result += ', ';
                }
                result += 'до ' + new Intl.NumberFormat("ru-RU").format(this.request.MeterPrice) + contracts[this.request.slCurrency] + ' за м²'
            }

            return result;
        },

    },

    methods: {
        nextCallChanged: function (data) {
            this.nextCall = data;
            this.$emit('next-call-changed');
        },
    },

    created() {
        this.nextCall.Request_ID = this.request.ID;
        this.nextCall.NextCallDate = this.request.NextCallDate;
        this.nextCall.NextCallComment = this.request.NextCallComment;
        this.nextCall.User_Upd = this.request.NextCall_User_Upd;
        this.nextCall.DateUpd = this.request.NextCall_DateUpd;
    },

}
</script>

