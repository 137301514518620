import youtubeLinksTimeline from './youtube-links-timeline'
import youtubeObjectLinks from './youtube-object-links'

export default [
    {
        path: '/youtube-links',
        name: 'youtube-links',
        component: youtubeLinksTimeline,
        title: 'Youtube Links',
    },
    {
      path: '/youtube-links/object',
      name: 'youtube-object-links',
      component: youtubeObjectLinks,
      props: (route) => ({
        buildingId: route.query.square_id ? undefined : +route.query.building_id,
        squareId: +route.query.square_id
      })
    },


];