import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import { EventBus } from './utils/event-bus.js';

import YmapPlugin from 'vue-yandex-maps'
Vue.use(YmapPlugin, {
  apiKey:'2d7e468f-c578-4731-9e38-46257a4505d8',
  zoom:18
})



Vue.config.productionTip = false;

// HTTP
axios.interceptors.request.use((config) => {
  config.headers['Authorization'] = store.state.authorizationToken;
  store.dispatch("loading_start");
  return config;
}, (error) => {
  store.dispatch("loading_error");
  return Promise.reject(error);
});


axios.interceptors.response.use(function (response) {
  if (store.state.apiStatus === 'unauthorized') EventBus.$emit('on-api-connection');
  store.dispatch("loading_finish");
  return response.data;
}, function (err) {
    if (!!err.response && err.response.status === 401) {
      store.dispatch("reset_authorization");
    } else if (!!err.response && err.response.status === 403) {
      console.log('Access denied');
    } else {
      store.dispatch("throw_critical_error", err.response ? err.response.data : err);
      store.dispatch("loading_error");
    }
    return Promise.reject(err.response ? err.response.data : err)
});

Vue.prototype.$http = axios

// Event bus
Vue.prototype.$eventBus = EventBus

// Permissions
Vue.prototype.$checkPermissions = (key) => {
  return !!(store.state.user.id > 0 && store.state.userPermissions[key]);
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


