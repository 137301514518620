<template>
    <data-loader
    pagination
    url="/api/request/details"
    :form-data="{order_id: requestId}"
    :do-request="true"
    :check-response="checkResponse"
    @loading-complete="onLoadData">
        <template v-slot:default>
            <v-container>
                <request-action-row
                    v-for="item in actionsLog"
                    :key="item.log_item_key"
                    :action="item"
                />
            </v-container>
        </template>
    </data-loader>
</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import requestActionRow from './request-action-row.vue'

export default {
    name: 'request-actions',
    components: {
        dataLoader,
        requestActionRow
    },
    props: {
        requestId: {
            type: Number,
        },
    },
    data () {
        return {
            actionsLog: [],
            checkResponse: function (response) {
                return (response.actions && response.actions.length > 0);
            },
        }
    },
    methods: {
        onLoadData: function (data) {
            this.actionsLog = [];
            this.doRequest = false;
            if ( data && this.checkResponse(data) ) {
                this.actionsLog = data.actions;
            }
        },
    }


}
</script>