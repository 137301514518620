<template>
    <v-row align="center" class="border-bottom">
        <!-- FIRST -->
        <v-col
        class="text-caption grey--text"
        cols="12"
        lg="2">
            <date-sql-date-formatter :date="action.DateIns"
            shortdate
            time
            />
        </v-col>


        <!-- SECOND -->
        <v-col
        v-if="user_id"
        cols="12"
        lg="2"
        class="py-0">
            <staff-name
            :id='user_id'/>
        </v-col>

        <v-col
        v-if="action.type == 'call'"
        cols="12"
        lg="2">
            <v-chip color="teal accent-4">
                <v-icon class="mr-2">
                    mdi-phone
                </v-icon>
                {{action.phone_part1}} {{action.phone_part2}}
            </v-chip>
            
        </v-col>

        <v-col
        v-if="action.type == 'site_request'"
        cols="12"
        lg="2">
            <v-chip color="cyan accent-3">
                <v-icon class="mr-2">
                    mdi-email-outline
                </v-icon>
                Заявка
            </v-chip>
        </v-col>


        <!-- THIRD -->
        <v-col
        cols="12"
        lg="8"
        v-if="action.type == 'communication'"
        >
        {{action.Note}}
        </v-col>

        <v-col
        cols="12"
        lg="8"
        v-if="action.type == 'user_change'"
        class="font-weight-bold purple--text"
        >
        {{action.old}} ⇒ {{action.new}}
        </v-col>

        <v-col
        cols="12"
        lg="6"
        v-if="action.type == 'call'"
        >
        {{action.source}}
        </v-col>

        <v-col
        cols="12"
        lg="6"
        v-if="action.type == 'site_request'"
        >
            <div class="text-caption grey--text">

                <span
                v-if="action.phone">
                    {{action.phone}}
                </span>

                <span
                v-if="action.mail">
                    {{action.mail}}
                </span>

                <span
                v-if="action.name">
                    {{action.name}}
                </span>

                <span
                v-if="action.company">
                    {{action.company}}
                </span>

            </div>

            <span
            v-if="action.object_id">
                Помещение <a :href="'/object/' + action.object_id" target="_blank">{{action.object_id}}</a>
            </span>

            <span
            v-if="action.min_square">
                От {{action.min_square}} м².
            </span>

            <span
            v-if="action.max_square">
                До {{action.max_square}} м².
            </span>

            <span
            v-if="action.meter_price">
                До {{action.meter_price}} руб./м² в год.
            </span>

            <span
            v-if="action.price">
                До {{action.price}} руб. в месяц.
            </span>

        </v-col>


        <v-col
        cols="12"
        lg="6"
        v-if="action.type == 'offer'"
        >

        <div class="mr-4 mb-2 d-block d-sm-inline">
            <request-action-chip :action="action"/>
        </div>

        <a :href="'/object/' + action.Square_ID" target="_blank">
            {{action.sq_Address}}
        </a>
        <span
        v-if="action.sq_square">, {{action.sq_square}} м²</span>


        </v-col>


        <!-- FOURTH -->

        <v-col
        cols="12"
        lg="2"
        v-if="action.type == 'call'"
        class="text-caption grey--text"
        >
        {{action.contact_about}}
        </v-col>

        <v-col
        cols="12"
        lg="2"
        v-if="action.type == 'offer'"
        class="text-caption grey--text"
        >
        {{action.About}}
        </v-col>

        <v-col
        cols="12"
        lg="2"
        v-if="action.type == 'site_request'"
        class="text-caption grey--text"
        >
        {{action.description}}
        </v-col>
    </v-row>
</template>

<script>
import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue'
import staffName from '@/components/staff-name.vue'
import requestActionChip from '@/components/entities/request/request-action-chip.vue'

export default {
    name: 'request-action-row',
    components: {
        dateSqlDateFormatter,
        staffName,
        requestActionChip,
    },
    props: {
        action: {
            type: Object,
        },
    },
    computed: {
        user_id: function () {
            if ((this.action.type == 'communication') || (this.action.type == 'offer') || (this.action.type == 'user_change')){
                return this.action.User_Ins;
            } else {
                return undefined;
            }
        },
    },
}
</script>

<style scoped>
    .border-bottom {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
</style>

