<template>
    <v-overlay
        v-if="!this.$store.getters.authStatus"
        :opacity="100"
        :z-index="990"
    >
        <v-progress-circular
            v-if="this.$store.state.logonPending"
            indeterminate size="64"
        >
        </v-progress-circular>
        <div
            v-if="!this.$store.state.logonPending"
            class="text-center"
        >
            <v-form @submit.prevent="auth">
                <v-text-field
                label="Login"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                v-model="login"
                ></v-text-field>

                <v-text-field
                id="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
                ></v-text-field>
                <div class="text-center">
                    <v-btn
                        color="primary"
                        type="submit"
                    >Login</v-btn>
                </div>
            </v-form>


            <v-btn
                v-if="this.$store.state.authMessage"
                color="error"
                class="mt-5"
                @click="closeError"
            >
                {{ this.$store.state.authMessage }}
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </v-overlay>
</template>

<script>
export default {
    name: 'login-form',
    data () {
      return {
          login: '',
          password: '',
      }
    },

    methods: {
      auth: function () {
            let login = this.login 
            let password = this.password
            this.$store.dispatch('login', { login, password });
      },
      closeError: function () {
        this.$store.dispatch("reset_authorization_error");
      }
    },
}
</script>