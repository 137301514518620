<template>
    <v-list-item
    target="_blank"
    :href="cianLink">
        <v-list-item-title>
            <v-icon left>
                mdi-search-web
            </v-icon>
            Поискать в Циане
        </v-list-item-title>
    </v-list-item>
</template>


<script>
import MetroCheckboxes from '@/components/js/MetroCheckboxes.js'

export default {
    name: 'request-search-in-cian',
    props: {
        request: {
            type: Object,
        },
    },
    data () {
        return {
            cianStations: {
                1:1, // Авиамоторная
                2:2, // Автозаводская
                3:3, // Академическая
                4:4, // Александровский сад
                5:5, // Алексеевская
                24:245, // Алма-Атинская
                6:6, // Алтуфьево
                7:7, // Аннино
                8:8, // Арбатская
                9:9, // Аэропорт
                10:10, // Бабушкинская
                11:11, // Багратионовская
                12:12, // Баррикадная
                13:13, // Бауманская
                14:14, // Беговая
                198:369, // Беломорская
                15:15, // Белорусская
                16:16, // Беляево
                17:17, // Бибирево
                18:18, // Библиотека им. Ленина
                177:240, // Борисово
                22:20, // Боровицкая
                192:365, // Боровское шоссе
                23:21, // Ботанический сад
                25:22, // Братиславская
                26:23, // Бульвар Адмирала Ушакова
                27:24, // Бульвар Дмитрия Донского
                154:137, // Бульвар Рокоссовского
                28:25, // Бунинская аллея
                187:287, // Бутырская
                31:26, // Варшавская
                32:27, // ВДНХ
                196:353, // Верхние Лихоборы
                33:28, // Владыкино
                34:29, // Водный стадион
                35:30, // Войковская
                36:31, // Волгоградский проспект
                37:32, // Волжская
                30:234, // Волоколамская
                38:33, // Воробьевы горы
                153:35, // Выставочная
                40:34, // Выхино
                190:363, // Говорово
                43:36, // Динамо
                44:37, // Дмитровская
                45:38, // Добрынинская
                46:39, // Домодедовская
                42:237, // Достоевская
                47:40, // Дубровка
                48:270, // Жулебино
                178:239, // Зябликово
                50:41, // Измайловская
                52:42, // Калужская
                53:43, // Кантемировская
                54:44, // Каховская
                55:45, // Каширская
                56:46, // Киевская
                57:47, // Китай-город
                58:48, // Кожуховская
                59:49, // Коломенская
                60:50, // Комсомольская
                61:51, // Коньково
                20:282, // Котельники
                62:52, // Красногвардейская
                63:159, // Краснопресненская
                64:53, // Красносельская
                65:54, // Красные ворота
                66:55, // Крестьянская застава
                67:56, // Кропоткинская
                68:57, // Крылатское
                69:58, // Кузнецкий мост
                70:59, // Кузьминки
                71:60, // Кунцевская
                72:61, // Курская
                73:62, // Кутузовская
                74:63, // Ленинский проспект
                181:271, // Лермонтовский проспект
                155:273, // Лесопарковая
                183:338, // Ломоносовский проспект
                75:64, // Лубянка
                200:372, // Лухмановская
                76:65, // Люблино
                77:66, // Марксистская
                39:236, // Марьина роща
                78:67, // Марьино
                80:68, // Маяковская
                81:69, // Медведково
                150:70, // Международная
                82:71, // Менделеевская
                182:337, // Минская
                83:235, // Митино
                188:361, // Мичуринский проспект
                84:72, // Молодежная
                29:233, // Мякинино
                85:73, // Нагатинская
                86:74, // Нагорная
                87:75, // Нахимовский проспект
                199:373, // Некрасовка
                202:303, // Нижегородская
                88:76, // Новогиреево
                89:243, // Новокосино
                90:77, // Новокузнецкая
                193:366, // Новопеределкино
                92:78, // Новослободская
                21:19, // Новоясеневская
                93:79, // Новые Черемушки
                189:362, // Озерная
                195:296, // Окружная
                204:375, // Окская
                94:80, // Октябрьская
                95:81, // Октябрьское поле
                96:82, // Орехово
                97:83, // Отрадное
                98:84, // Охотный ряд
                100:85, // Павелецкая
                101:86, // Парк Культуры
                102:87, // Парк Победы
                51:88, // Партизанская
                103:89, // Первомайская
                104:90, // Перово
                206:350, // Петровский Парк
                105:91, // Петровско-Разумовская
                106:92, // Печатники
                107:93, // Пионерская
                108:94, // Планерная
                109:95, // Площадь Ильича
                110:96, // Площадь Революции
                111:97, // Полежаевская
                112:98, // Полянка
                113:99, // Пражская
                114:100, // Преображенская площадь
                115:101, // Пролетарская
                116:102, // Проспект Вернадского
                117:103, // Проспект Мира
                118:104, // Профсоюзная
                119:105, // Пушкинская
                180:244, // Пятницкое шоссе
                184:339, // Раменки
                194:367, // Рассказовка
                120:106, // Речной вокзал
                121:107, // Рижская
                122:108, // Римская
                99:284, // Румянцево
                123:109, // Рязанский проспект
                124:110, // Савеловская
                132:285, // Саларьево
                125:111, // Свиблово
                126:112, // Севастопольская
                197:354, // Селигерская
                127:113, // Семеновская
                128:114, // Серпуховская
                142:229, // Славянский бульвар
                129:115, // Смоленская
                130:116, // Сокол
                131:117, // Сокольники
                191:364, // Солнцево
                79:275, // Спартак
                133:118, // Спортивная
                203:376, // Стахановская
                134:228, // Строгино
                135:120, // Студенческая
                136:121, // Сухаревская
                137:122, // Сходненская
                138:123, // Таганская
                139:124, // Тверская
                140:125, // Театральная
                141:126, // Текстильщики
                143:127, // Теплый Стан
                91:283, // Технопарк
                144:128, // Тимирязевская
                145:129, // Третьяковская
                19:281, // Тропарево
                146:131, // Тульская
                147:132, // Тургеневская
                148:133, // Тушинская
                149:134, // Улица 1905 года
                151:135, // Улица Академика Янгеля
                152:136, // Улица Горчакова
                201:371, // Улица Дмитриевского
                156:138, // Улица Скобелевская
                157:139, // Улица Старокачаловская
                158:140, // Университет
                159:141, // Филевский парк
                160:142, // Фили
                186:286, // Фонвизинская
                161:143, // Фрунзенская
                185:349, // Ховрино
                208:351, // Хорошевская
                162:144, // Царицыно
                163:145, // Цветной бульвар
                207:352, // ЦСКА
                164:146, // Черкизовская
                165:147, // Чертановская
                166:148, // Чеховская
                167:149, // Чистые пруды
                168:150, // Чкаловская
                169:151, // Шаболовская
                209:311, // Шелепиха
                179:238, // Шипиловская
                170:152, // Шоссе Энтузиастов
                171:153, // Щелковская
                172:154, // Щукинская
                173:155, // Электрозаводская
                205:374, // Юго-Восточная
                174:156, // Юго-Западная
                175:157, // Южная
                176:158, // Ясенево
            }
            
        }
    },
    computed: {
        cianLink: function () {

            const params = new URLSearchParams({});

            params.append('currency', 2);

            if(this.request.slNeedContractType === 8){
                params.append('deal_type', 'sale');
            } else {
                params.append('deal_type', 'rent');
            }

            params.append('engine_version', 2);

            params.append('foot_min', (this.request.FromMetro || 15));

            if ( this.request.MetroBox_IDs ) {

                let selectedCheckboxes = new MetroCheckboxes(this.request.MetroBox_IDs);
                let stationList = this.$store.state.metroCheckboxes;
                let selectedStations = [];

                if(selectedCheckboxes && selectedCheckboxes.list && selectedCheckboxes.list.length > 0){
                    selectedCheckboxes.list.forEach(function(item) {
                        if(stationList[item]){
                            stationList[item].forEach(function(obj) {
                                selectedStations.push(obj.id);
                            })
                            
                        }
                    })
                }

                if ( selectedStations && selectedStations.length > 0 ){
                    let cs = this.cianStations;
                    selectedStations.forEach(function(item, index) {
                        if( cs[item] ){
                            params.append('metro[' + index + ']', cs[item]);
                        }

                    });
                }


            }

            params.append('offer_type', 'offices');
            params.append('only_foot', 2);

            if (this.request.MinSquare){
                params.append('minarea', +this.request.MinSquare);
            }

            if (this.request.MaxSquare){
                params.append('maxarea', +this.request.MaxSquare);
            }

            if (this.request.MonthPrice){
                params.append('maxprice', +this.request.MonthPrice);
            } else if (this.request.MeterPrice){
                params.append('maxprice', +this.request.MeterPrice);
                params.append('m2', 1);
            }


            if (this.request.slDestination == 1){
                params.append('office_type[0]', 1);
                params.append('office_type[1]', 11);
            } else if (this.request.slDestination == 2 || this.request.slDestination == 64){
                params.append('office_type[0]', 2);
            } else if (this.request.slDestination == 128){
                params.append('office_type[0]', 5);
                params.append('office_type[1]', 12);
            } else if (this.request.slDestination == 32){
                params.append('office_type[0]', 2);
                params.append('office_type[1]', 11);
            } else if (this.request.slDestination == 16){
                params.append('office_type[0]', 4);
            } else if (this.request.slDestination == 4){
                params.append('office_type[0]', 3);
            } else if (this.request.slDestination == 8){
                params.append('office_type[0]', 7);
            } else if (this.request.slDestination == 512){
                params.append('office_type[0]', 5);
                params.append('office_type[1]', 12);
                params.append('specialty_types[0]', 7022);
                params.append('specialty_types[1]', 7023);
            } else if (this.request.slDestination == 256){
                params.append('office_type[0]', 5);
                params.append('office_type[1]', 12);
                params.append('specialty_types[0]', 7024);
                params.append('specialty_types[1]', 7082);
            }



            if (this.request.isSeparate){
                params.append('input_type[0]', 1);
                params.append('input_type[1]', 2);
            }

            return 'https://www.cian.ru/cat.php?' + params.toString();


        },

    },

}
</script>

