<template>
    <v-card
    v-if="fb"
    class="mb-8"
    >
        <v-card-title>
            <v-chip
            class="mr-2">
                <v-icon left>
                    mdi-account-key
                </v-icon>
                {{fb.ID}}
            </v-chip>
            <staff-name-chip :id='fb.User_ID'></staff-name-chip>
            <v-dialog
            v-model="dialog"
            max-width="800px">
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                class="mr-2"
                :color="(fb.keys && fb.keys.length > 0) ? 'green' : 'grey'"
                v-bind="attrs"
                v-on="on"
                >
                mdi-shield-key
                </v-icon>
            </template>
            <v-card>
                <v-card-title>
                    <v-chip
                    class="mr-2">
                        {{fb.ID}}
                    </v-chip>
                    <staff-name-chip :id='fb.User_ID'></staff-name-chip>
                    <span
                    v-if="!fb.Name">
                        Без названия
                    </span>
                    {{fb.Name}}
                </v-card-title>

                <v-container>
                    <v-simple-table
                    v-if="fb.keys && fb.keys.length > 0"
                    class="d-inline-block mx-2">
                        <template v-slot:default>
                            <tbody>
                                <tr
                                v-for="item in fb.keys"
                                :key="item.uuid">
                                    <td><a :href="'http://landlord.mega-realty.ru/key/' + item.uuid" target="_blank">{{ item.uuid }}</a></td>
                                    <td>{{ item.phone }}</td>
                                    <td>{{ item.mail }}</td>
                                    <td>
                                        <v-icon>mdi-delete-forever</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-container>
                
                <v-container>
                    <v-row>
                        <v-col
                        cols="4">
                            <v-text-field
                            v-model="newKeyPhone"
                            @change="fixPhone"
                            label="Телефон"
                            prefix="+7"
                            outlined
                            clearable
                            dense 
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="4">
                            <v-text-field
                            v-model="newKeyMail"
                            label="Электронная почта"
                            outlined
                            clearable
                            dense 
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="4">
                            <v-btn
                            color="primary"
                            elevation="2"
                            :disabled = newKeyContactsIsUndef
                            @click="addNewKey"
                            >
                            Создать ключ
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>

            </v-dialog>

            <span
            v-if="!fb.Name">
                Без названия
            </span>
            {{fb.Name}}

        </v-card-title>

        <v-card-subtitle>
            {{fb.About}}
        </v-card-subtitle>

        <v-simple-table
        v-if="fb.squares && fb.squares.length > 0"
        class="d-inline-block mx-2">
            <template v-slot:default>
                <tbody>
                    <short-square-table-row
                    v-for="item in fb.squares"
                    :key="item.ID"
                    :square="item"
                    >
                    </short-square-table-row>
                </tbody>
            </template>
        </v-simple-table>



    </v-card>
</template>


<script>
import staffNameChip from '@/components/staff-name-chip.vue'
import shortSquareTableRow from '@/components/short-square-table-row.vue'
import fixPhoneStringMixin from '@/mixins/fix-phone-string.js'

export default {
    name: 'accessKeysFb',
    components: {
        staffNameChip,
        shortSquareTableRow,
    },
    props: {
        fb: {
            type: Object
        },
    },
    mixins: [fixPhoneStringMixin],
    data () {
        return {
            dialog: false,
            newKeyDialog: false,
            newKeyPhone: '',
            newKeyMail: '',
        }
    },
    computed: {
        newKeyContactsIsUndef: function () {
            return !(this.newKeyPhone || this.newKeyMail);
        },
        newKeyFormData: function () {
            return {
                fb_id: this.fb.ID,
                phone: this.newKeyPhone,
                mail: this.newKeyMail
            };
        },


    },
    methods: {
        fixPhone: function () {
            if ( this.newKeyPhone ) {
                this.newKeyPhone = this.fixPhoneString(this.newKeyPhone);
            }
        },
        addNewKey: function () {
            this.$http({url: '/api/access-keys/new/', method: 'POST', data: this.newKeyFormData})
            .then(
                response => {
                    this.$eventBus.$emit('update-fb-access-keys-list', this.fb.ID, response.keys);
                },
                () => {
                    console.log('ERROR');
                })

        }
    },

}
</script>