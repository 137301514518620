<template>
    <v-card
    class="ma-1"
    >
        <v-container>
            <v-img
            height="200"
            width="200"
            :src="thumb_src"
            >
                <template v-slot:placeholder>
                    <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                    >
                    <v-progress-circular indeterminate color="grey"></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
        </v-container>
        <v-container>
           {{file.name}}
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: 'file-upload-preview',
    props: {
        file: {
            type: Object
        },
    },
    data () {
      return {
      }
    },
    computed: {
        thumb_src: function () {
            return '/private-cloud/thumbnails/' + this.file.key + '.jpg'
        },
    },
}
</script>