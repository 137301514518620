<template>
    <data-editor
    max-width="50%"
    url="/api/square/supplementary-editor"
    :show="show"
    @close-dialog="closeDialog"
    @request-complete="dataChanged"
    :save-condition="!!supplementaryData.supplementary"
    :form-data="supplementaryData"
    title="Редактор описания"
    >
        <v-row>
            <v-col
            cols="12"
            >
                <v-textarea
                outlined
                auto-grow
                name="supplementary"
                label="Описание"
                v-model="supplementaryText"
                ></v-textarea>
            </v-col>
        </v-row>
    </data-editor>
</template>

<script>
    import dataEditor from '@/components/data-editor.vue'

    export default {
        name: 'square-supplementary-editor',
        components: {
            dataEditor
        },
        props: {
            square: {
                type: Object,
            },
            show: {
                type: Boolean,
            }
        },
        data () {
            return {
                supplementaryText: this.square.supplementary
            }
        },
        computed: {
            supplementaryData: function () {
                return {
                    id: this.square.ID,
                    supplementary: this.supplementaryText
                }
            }

        },
        methods: {
            closeDialog () {
                this.$emit('close-dialog');
            },
            dataChanged () {
                this.$emit('on-change-data');
            }
        },
    }
</script>