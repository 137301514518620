<template>
    <div>
        <v-overlay
            :value="loading"
            :absolute="true">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>

        <p v-if="message">{{message}}</p>

        <v-btn
        v-if="error"
        color="primary"
        @click="loadData">Попробуйте ещё раз</v-btn>

        <div v-if="ok">
            <slot></slot>
        </div>

        <div v-if="pagination">
            <v-pagination
                v-if="pageCount > 1"
                v-model="pageIndex"
                :length="pageCount"
                :total-visible="paginationTotalVisible"
                @input="loadData"
            ></v-pagination>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'data-loader',
        //components: {},
        props: {
            url: {
                type: String
            },
            formData: {
                type: Object,
            },
            checkResponse: {
                type: Function,
                default: function (response) {
                    return (response.list && response.list.length > 0);
                },
            },
            doRequest: {
                type: Boolean
            },
            pagination: {
                type: Boolean
            },
            paginationTotalVisible: {
                type: Number,
                default: 7
            },
        },
        data () {
            return {
                loading: false,
                error: false,
                ok: false,
                message: '',
                pageCount: 1,
                pageIndex: 1,
            }
        },
        //computed: { },
        methods: {
            loadData: function () {
                this.message = '';
                this.error = false;
                this.ok = false;
                this.loading = true;
                this.$http({url: this.url, method: 'POST', data: Object.assign({}, this.formData, this.pagination ? {page: this.pageIndex} : {})})
                .then(
                    response => {
                        this.pageCount = 1;
                        this.loading = false;
                        if ( response && (!this.checkResponse || this.checkResponse(response)) ) {
                            this.ok = true;
                            if ( this.pagination && response.page_count) {
                                this.pageCount = +response.page_count;
                            }
                        } else {
                            this.message = 'Ничего не найдено';
                        }
                        this.$emit('loading-complete', (this.checkResponse(response) && response));
                    },
                    () => {
                        this.loading = false;
                        this.error = true;
                        this.message = 'Ошибка! Данные не были загружены';
                        this.$emit('loading-complete');
                    })
            }
        },
        created() {
            if (this.doRequest) {
                this.loadData();
            }
        },
        watch: {
            doRequest: function (newVal) {
                if (newVal) {
                    this.pageIndex = 1;
                    this.loadData();
                }
            }
        }
    }
</script>

<style>
</style>